import {DeleteWithConfirmButton, SaveButton, Toolbar} from "react-admin";
import React from "react";

export const SaveDeleteToolbar = ({onRedirect, mutationMode, deleteConfirmTitle, deleteConfirmContent, ...props}) => {

    deleteConfirmContent = deleteConfirmContent || 'ra.message.delete_content'
    deleteConfirmTitle = deleteConfirmTitle || 'ra.message.delete_title_generic' || 'ra.message.delete_title'
    
    return (
        <Toolbar {...props} >
            <SaveButton/>
            <DeleteWithConfirmButton
                confirmTitle={deleteConfirmTitle}
                confirmContent={deleteConfirmContent}
                onSuccess={basePath => {
                    if (onRedirect) {
                        onRedirect(basePath,props.record);
                    }
                    return basePath;
                }} mutationMode={mutationMode}/>
        </Toolbar>
    );
}