import {Create, DeleteWithConfirmButton, Edit} from 'react-admin';
import React from "react";
import {
    EditButton,
    NumberInput,
    SimpleForm,
    Datagrid,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
    required,
} from "react-admin";
import {FieldGuesser} from "@api-platform/admin";
import {MoneyField} from "../Form/FormFields";
import {GetPlan} from "../Session/getPlan";
import {Showme} from "../Tools/Tools";
import {BooleanField, BooleanInput, ListButton, TopToolbar} from "ra-ui-materialui";
import ChevronLeft from '@material-ui/icons/ChevronLeft';

// Need full nested EmployeeAllocation structure in list / calendar, 
// but create/edit needs flat structure with IRIs.   
export const BudgetSaveTransformer = (values) => {
    values.positionType = values.positionType ? (values.positionType['@id'] ? values.positionType['@id'] : values.positionType) : null;
    values.allocationGroup = values.allocationGroup ? (values.allocationGroup['@id'] ? values.allocationGroup['@id'] : values.allocationGroup) : null;
    return values;
}

const GroupBackActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    </TopToolbar>
);

export const GroupBudgetList = props => {
    const plan = GetPlan();
    if (!plan) {
        return null; // !!!
    }
    const planFilter = {'allocationGroup.project.plan': plan.id}
    return (
        <List {...props} filter={planFilter} hasEdit={true} hasCreate={true} exporter={false}>
            <Datagrid>
                <TextField label="Projekt" source={'allocationGroup.project.name'}/>
                <TextField label="Gruppe" source={'allocationGroup.name'}/>
                <TextField label="Funktionsgruppe" source={"positionType.name"}/>
                <BooleanField label="Selbständig" source={'isFreelancer'}/>
                <MoneyField label="Ansatz" source={"positionType.hourlyRate"}/>
                <FieldGuesser label="FTE" source={"fteAllocation"}/>
                <EditButton label={false}/>
                <DeleteWithConfirmButton
                    confirmTitle={'ra.message.delete_title_generic'}
                    confirmContent={'Budget wirklich löschen?'}
                    label={false}
                />
            </Datagrid>
        </List>
    )
}

export const GroupBudgetCreate = props => {
    const plan = GetPlan();
    if (!plan) {
        return null;
    }
    const groupFilter = {'context': 'group-short', 'project.plan': plan.id};
    const positionTypeFilter = {'plan': [plan.id]};
    return (
        <Create {...props} actions={<GroupBackActions />}>
            <SimpleForm variant={"outlined"} redirect={"list"}>
                <ReferenceInput
                    filter={groupFilter}
                    label="Gruppe"
                    source="allocationGroup"
                    reference="groups"
                    validate={required()}
                >
                    <SelectInput optionText="fullName"/>
                </ReferenceInput>
                <ReferenceInput
                    filter={positionTypeFilter}
                    label="Funktionsgruppe"
                    source="positionType"
                    reference="position_types"
                    validate={required()}
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <BooleanInput label="Selbständig" source={'isFreelancer'}/>
                {/*backend needs string decimal, not float*/}
                <NumberInput
                    parse={v => v.toString()}
                    step={"any"}
                    source={'fteAllocation'}
                    validate={required()}
                />
            </SimpleForm>
        </Create>
    )
}

export const GroupBudgetEdit = props => {
    const plan = GetPlan();
    if (!plan) {
        return null;
    }
    const groupFilter = {'context': 'group-short', 'project.plan': plan.id};
    const positionTypeFilter = {'plan': [plan.id]};
    return (
        <Edit {...props} hasShow={false} transform={BudgetSaveTransformer} undoable={false}>
            <SimpleForm variant={"outlined"}>
                <ReferenceInput
                    filter={groupFilter}
                    label="Gruppe"
                    source="allocationGroup.@id"
                    reference="groups"
                    validate={required()}
                >
                    <SelectInput optionValue="@id" optionText="fullName"/>
                </ReferenceInput>
                <Showme debugStr={"render Gedit"}/>
                <ReferenceInput
                    filter={positionTypeFilter}
                    label="Funktionsgruppe"
                    source="positionType.@id"
                    reference="position_types"
                    validate={required()}
                >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <BooleanInput label="Selbständig" source={'isFreelancer'}/>
                {/*backend needs string decimal, not float*/}
                <NumberInput
                    parse={v => v.toString()}
                    step={"any"}
                    source={'fteAllocation'}
                    validate={required()}
                />
            </SimpleForm>
        </Edit>
    )
}