import React, {
    cloneElement,
    useCallback,
    useState,
} from "react";
import {Drawer} from "@material-ui/core";
import {
    TopToolbar, 
    Button, 
    useUpdate, 
    sanitizeListRestProps,
    List,
    useNotify,
    useRefresh
} from "react-admin";
import {
    AllocationCreate,
    AllocationEdit,
    EmployeeInlineShow
} from "../Views/EmployeeAllocationViews";
import {GroupCreate, GroupEdit} from "../Views/GroupViews";
import {ProjectDetail} from "../Views/ProjectViews";
import {useSelector} from 'react-redux';
import SearchIcon from "@material-ui/icons/Search";
import {CreateButtonMenu, CreateButtonMenuItem} from "./CreateButtonMenu";
import {makeStyles} from "@material-ui/core/styles";
import {EventDetail} from "../Views/EventViews";
import {AbsenceCreateInline, AbsenceEditInline} from "../Views/AbsenceViews";
export const DrawerActionsTopToolbar = ({
                                            onClickCreate, currentSort, className, resource, filters, displayedFilters,
                                            exporter, // you can hide ExportButton if exporter = (null || false)
                                            filterValues, permanentFilter,
                                            hasCreate, // you can hide CreateButton if hasCreate = false
                                            basePath, selectedIds, onUnselectItems, showFilter, maxResults, total, 
                                            toggleAside, asideActive, ...rest
                                        }) => {
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButtonMenu>
                <CreateButtonMenuItem onClick={(event) => onClickCreate({objectType:'allocation'})} label={'Einsatz'} />
                <CreateButtonMenuItem onClick={(event) => onClickCreate({objectType:'group'})} label={'Gruppe'} />
            </CreateButtonMenu>
            {/*<ExportButton*/}
            {/*    disabled={total === 0}*/}
            {/*    resource={resource}*/}
            {/*    sort={currentSort}*/}
            {/*    filter={{...filterValues, ...permanentFilter}}*/}
            {/*    exporter={exporter}*/}
            {/*    maxResults={maxResults}*/}
            {/*/>*/}
            <Button
                style={{alignItems:'center'}}
                onClick={toggleAside}
                label="Personen"
            >
                <SearchIcon style={{fontSize:"1.1rem",marginRight:"-0.3rem"}}  />
            </Button>
        </TopToolbar>
    );
}

const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//     },
//     appBar: {
//         zIndex: theme.zIndex.drawer + 1,
//     },
//     title: {
//         flexGrow: 1,
//     },
//     hide: {
//         display: 'none',
//     },
//     drawer: {
//         width: drawerWidth,
//         flexShrink: 0,
//     },
//     drawerPaper: {
//         width: drawerWidth,
//         marginTop: 120,
//         position: 'fixed'
//     },
//     drawerHeader: {
//         display: 'flex',
//         alignItems: 'center',
//         padding: theme.spacing(0),
//         // necessary for content to be below app bar
//         ...theme.mixins.toolbar,
//         justifyContent: 'flex-start',
//     },
//     content: {
//         flexGrow: 1,
//         padding: theme.spacing(0),
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         marginRight: -drawerWidth,
//     },
//     contentShift: {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//         marginRight: 0,
//     },
//     small: {
//         height:15,
//         width:15
//     }
// }));


const useDrawerStyles = makeStyles((theme) => ({
    drawer: {
        minWidth:360,
        // border: '5px solid blue'
    },
    drawerPaper: {
        minWidth:360,
        // border: '5px solid red'
    },
}));
//
// const useListStyles = makeStyles({
//     header: {
//         backgroundColor: '#ccc',
//     },
//     actions: {
//         border: "3px solid red",
//     },
//     root: {
//         '&*': {
//           background:'green'  
//         },
//         background: 'none',
//     },
//     toolbar: {
//         background: 'pink'
//     },
// });

/**
 * @param isOpenAside
 * @param renderCreate
 * @param filter
 * @param filters
 * @param filterDefaultValues
 * @param plan
 * @param renderEdit
 * @param children
 * @param getLastInsertId
 * @param setLastInsertId
 * @param asideActive
 * @param toggleAside
 * @param perPage
 * @param props
 * @returns {*}
 * @constructor
 */
const ListWithCreateDrawer = ({isOpenAside, renderCreate, filter, filters, filterDefaultValues, plan, renderEdit, children, getLastInsertId, setLastInsertId, asideActive, toggleAside, perPage, ...props}) => {
    
// useTraceUpdate({renderCreate, filter, renderEdit, children, getLastInsertId, setLastInsertId, asideActive, toggleAside, perPage, ...props},"render ListWithCreateDrawer props changed")
    const classes = useDrawerStyles();
    const refresh = useRefresh();
    const [CalendarRef, setCalendarRef] = useState()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [drawerMode, setDrawerMode] = useState('create')
    const [initialValues, setinitialValues] = useState(null)
    const [objectId, setObjectId] = useState()
    const [objectType, setObjectType] = useState()
    const [rendererFilterValues, setRendererFilterValues] = useState()
    const notify = useNotify();
    const SessionProjects = useSelector(state => state.session.projects);
    const [useAllocationUpdate,useAllocationUpdateResult] = useUpdate('employee_allocations');
    const [useGroupUpdate,useGroupUpdateResult] = useUpdate('groups');
    const [hideToolbar,setHideToolbar] = useState(true);

    const onClickCreate = useCallback(({objectType, initialValues}) => {
        if(initialValues){
            setinitialValues(initialValues);    
        }
        if(objectType){
            setObjectType(objectType);
        }
        setDrawerMode('create');
        setDrawerOpen(true);
    }, [setinitialValues,setObjectType])

    const onDrawerClose = useCallback((props) => {
        setDrawerOpen(false)
    }, [])

    const onClickEdit = useCallback((props) => {
        setDrawerMode('edit');
        let targetType = props.event.extendedProps.extType;
        if (targetType === 'group' 
            || targetType === 'employee'
            || targetType === 'employeeResource'
            || targetType === 'event'
            || targetType === 'project'
            || targetType === 'absence'
        ) {
            setObjectId(props.event.id);
            setObjectType(targetType)
            setDrawerOpen(true);
        }
    }, [])

    const onFilter = useCallback((filterValues) => {
        setRendererFilterValues(filterValues);
    }, [setRendererFilterValues])
    
    const previousFilter = useCallback(()=>{
        return rendererFilterValues;
    },[rendererFilterValues])

    const onCreateRefreshAndNotify = useCallback((data,str) => {
        if(typeof data['@type'] !== 'undefined' && data['@type'] === 'Group'){
            filter['_gc'] = new Date().getTime();
        }
        if(typeof data['@type'] !== 'undefined' && data['@type'] === 'Absence'){
            filter['_gc'] = new Date().getTime();
        }
        notify('ra.notification.' + (str ?? 'updated'), 'info', {smart_count: 1});
        refresh();
    }, [notify, refresh, filter])

    const onDelete = useCallback((basePath, record) => {
        onDrawerClose();
        if (record && record.id) {
            // Force list data to reload by setting a one-time filter
            filter['groupDeleted'] = record.id;
        }
        notify('ra.notification.deleted', 'info', {smart_count: 1});
        refresh();                    
    }, [notify, onDrawerClose, filter, refresh])

    const toggleToolbar = useCallback(() => {
        setHideToolbar((hideToolbar) => !hideToolbar)
        if (isOpenAside) {
            toggleAside()
        }
    }, [isOpenAside, toggleAside])

    return (
        <div>
            <List 
                // classes={{root: listClasses.root, actions: listClasses.actions, header: admines.header}}
                filter={filter} 
                filterDefaultValues={filterDefaultValues}
                perPage={1000} 
                filters={hideToolbar ? null : filters}
                actions={hideToolbar ? null : 
                    <DrawerActionsTopToolbar 
                    toggleAside={toggleAside} 
                    asideActive={asideActive} 
                    onClickCreate={onClickCreate}/>
                } 
                {...props} >
                {children({
                    "onClickEdit": onClickEdit,
                    "onClickCreate": onClickCreate,
                    "onFilter": onFilter,
                    "previousFilter": previousFilter,
                    "setCalendarRef": setCalendarRef,
                    "toggleToolbar": toggleToolbar,
                    "hideToolbar": hideToolbar
                })}
            </List>
            <Drawer
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                open={drawerOpen} onClose={onDrawerClose} anchor={"right"}>
                
                {drawerMode === 'edit' && objectType === 'employee' && objectId ? 
                    <AllocationEdit updater={useAllocationUpdate}
                                    projects={SessionProjects}
                                    notify={notify}
                                    id={objectId}
                                    onCancel={onDrawerClose}
                                    onDelete={onDelete}
                                    objectSetter={setObjectId}
                                    objectTypeSetter={setObjectType}
                        {...props} /> : null}
                {drawerMode === 'edit' && objectType === 'employeeResource' && objectId 
                    ? <EmployeeInlineShow objectTypeSetter={setObjectType} objectSetter={setObjectId} id={objectId} onCancel={()=>setDrawerOpen(false)} {...props} />
                    : null
                } 
                {drawerMode === 'edit' && objectType === '_employeeResource' && objectId ? EmployeeInlineShow({
                    id: objectId,
                    onCancel: () => {
                        setDrawerOpen(false)
                    }, ...props
                }) : null}
                {drawerMode === 'edit' && objectType === 'group' && objectId ? 
                    <GroupEdit updater={useGroupUpdate} 
                               notify={notify}
                               id={objectId}
                               onCancel={onDrawerClose}
                               onCreate={onCreateRefreshAndNotify}
                               onDelete={onDelete}
                               fullWidth={true}
                        {...props}
                    /> : null }
                {drawerMode === 'create' && objectType === 'allocation' ? 
                        <AllocationCreate 
                            projects={SessionProjects} 
                            notify={notify} 
                            initialValues={initialValues} 
                            onCancel={() => {
                            setDrawerOpen(false)
                        }} {...props}/> 
                    : null}
                {drawerMode === 'create' && objectType === 'group' ?
                    <GroupCreate onCancel={onDrawerClose}
                                 onCreate={onCreateRefreshAndNotify}
                                 initialValues={initialValues}
                                 fullWidth={true}
                                  {...props}/>
                    : null}
                {drawerMode === 'create' && objectType === 'absence' ?
                    <AbsenceCreateInline 
                                 onCancel={onDrawerClose}
                                 onCreate={onCreateRefreshAndNotify}
                                 initialValues={initialValues}
                                 fullWidth={true} 
                                 plan={plan} 
                                 {...props}/>
                    : null}
                {drawerMode === 'edit' && objectType === 'absence' ?
                    <AbsenceEditInline   
                                notify={notify}
                                id={objectId}
                                onCancel={onDrawerClose}
                                onDelete={onDelete}
                                fullWidth={true}
                                {...props}/>
                    : null}
                {drawerMode === 'edit' && objectType === 'project' && objectId ?
                    <ProjectDetail 
                               id={objectId}
                               onCancel={onDrawerClose}
                               {...props}
                    /> : null }
                {drawerMode === 'edit' && objectType === 'event' && objectId ?
                    <EventDetail
                        id={objectId}
                        onCancel={onDrawerClose}
                        {...props}
                    /> : null }   
            </Drawer>
        </div>
    )
}

export default ListWithCreateDrawer;