import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_CHECK,
    AUTH_GET_PERMISSIONS
} from 'react-admin';
import decodeJwt from 'jwt-decode';
import {Authpoint} from "./Authorization";

const AuthProvider = (type, params, ...props) => {
    switch (type) {
        case AUTH_LOGIN:
            const {username, password} = params;
            const request = new Request(`${Authpoint}`, {
                method: 'POST',
                body: JSON.stringify({email: username, password}),
                headers: new Headers({'Content-Type': 'application/json'}),
            });

            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        //throw new Error(response.statusText);
                        return Promise.reject();
                    }
                    return response.json();
                })
                .then(({token}) => {
                    
                    const decodedToken = decodeJwt(token);
                    
                    localStorage.setItem('token', token); // The JWT token is stored in the browser's local storage
                    localStorage.setItem('permissions', decodedToken.roles);
                    
                    if(decodedToken.uid){
                        localStorage.setItem('uid', decodedToken.uid);
                        localStorage.setItem('state.'+decodedToken.uid, JSON.stringify(decodedToken.state));
                    }
                    
                    // Hard refresh is needed for some reason 
                    // or admin won't load resources when login page was removed from cache 
                    window.location.replace('/');
                });

        case AUTH_LOGOUT:
            localStorage.clear();
            return Promise.resolve();

        case AUTH_ERROR:
            if (401 === params.response.status || 403 === params.response.status) {
                return Promise.reject();
            }
            break;

        case AUTH_CHECK:
            if (localStorage.getItem('token')) {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }

        case AUTH_GET_PERMISSIONS:
            const permissions = localStorage.getItem('permissions');
            return permissions ? Promise.resolve(permissions) : Promise.reject();

        default:
            return Promise.resolve();
    }
}

export default AuthProvider;