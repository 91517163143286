import React, {useCallback} from "react";
import {
    ChipField,
    DateField,
    DateInput, ReferenceField,
    SimpleForm, TextField,
    useUpdate
} from "react-admin";
import {InputGuesser, ListGuesser} from "@api-platform/admin";
import {CustomSaveEdit} from "../Form/CustomSaveEdit";
import {isAdmin} from "../DataProvider/Authorization";

export const PerformanceList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <TextField source={"title"} />
            <ReferenceField sortBy={"production.name"} link={false} reference={"productions"} source={"production"}>
                <ChipField  source={"name"} />
            </ReferenceField>
            <DateField showTime={true} source={"dateTimeFrom"}/>
            <DateField showTime={true} source={"dateTimeUntil"}/>
        </ListGuesser>
    );
}
 export const PerformanceEdit = (props) => {
    
    const [update, updateResult] = useUpdate(props.resource);

     const transform = useCallback(data=>{
         data.dateTimeFrom = new Date();
         data.dateTimeFrom = new Date();
         return data;
     },[])

     const save = useCallback(p=>{
         return update({
             payload: {
                 id: p.id,
                 data: transform(p)
             }
         })
     },[transform,update])

     const onFailure = error => {
        // notify(error.message, 'warning')
    }

     const onSuccess = data => {
         // notify("warnung", 'warning')
     }
     
    return (
        <CustomSaveEdit transform={transform} save={save} onSuccess={onSuccess} onFailure={onFailure} {...props} >
            <SimpleForm  >
                <InputGuesser source={"production"}/>
                <DateInput showTime={true} source={"dateTimeFrom"}/>
                <DateInput showTime={true} source={"dateTimeUntil"}/>
                <InputGuesser source={"title"}/>
            </SimpleForm>
        </CustomSaveEdit>
    )
}