import * as React from 'react';
import { useHistory } from "react-router-dom";
import {createElement, useState, useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {useMediaQuery, Divider,Chip} from '@material-ui/core';
import {useDataProvider, MenuItemLink, getResources} from 'react-admin';
import {withRouter} from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import {makePlanPhaseLink, makeProjectLink} from '../Views/ProjectViews'
import ListSubheader from "@material-ui/core/ListSubheader";
import StarBorder from '@material-ui/icons/StarBorder';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GetPlan from "../Session/getPlan";
import Grow from '@material-ui/core/Grow';
import useFavs from "../Hook/useFavs";
import get from 'lodash/get';

const Menu = ({onMenuClick, logout}) => {

    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const SessionFavs = useSelector(state => state.session.favs)

    const [projects, setProjects] = useState([]);
    const [planPhases, setPlanPhases] = useState([]);

    const Session = useSelector(state => state.session);
    const Dispatch = useDispatch();
    const [favSelected,setFavSelected] = useFavs();
    const history = useHistory();
    
    const plan = GetPlan();
    
    useEffect(() => {
        
        // make sure we load only once 
        if (!Session.waitingForProjects && plan && Session.loadedPlanProjectsId !== plan.id) {
                Dispatch({type: 'SESSION_ADD', waitingForProjects:true});
                dataProvider.getList('projects', {
                    pagination: {page: 1, perPage: 100},
                    sort: {field: "id", order: "asc"},
                    filter: {
                        plan: plan.originId,
                        context: [
                            'project-short'
                        ]
                    }
                })
                    .then(({data}) => {
                        if (data) {
                            setProjects(data);
                            Dispatch({type: 'SESSION_ADD', projects:data});
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(()=>{
                        Dispatch({type: 'SESSION_ADD', loadedPlanProjectsId: plan.id});
                        Dispatch({type: 'SESSION_ADD', waitingForProjects: false});
                    })    
            }

        if (!Session.waitingForPlanPhases && plan && Session.loadedPlanPhasesId !== plan.id) {
                Dispatch({type: 'SESSION_ADD', waitingForPlanPhases: true});
                dataProvider.getList('plan_phases', {
                    pagination: {page: 1, perPage: 100},
                    sort: {field: "id", order: "asc"},
                    filter: {plan: plan.originId}
                })
                    .then(({data}) => {
                        if (data) {
                            setPlanPhases(data);
                            Dispatch({type: 'SESSION_ADD', planPhases:data});
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        Dispatch({type: 'SESSION_ADD', loadedPlanPhasesId: plan.id});
                        Dispatch({type: 'SESSION_ADD', waitingForPlanPhases:false});
                    })
            }
        
    }, [
        dataProvider, 
        Session.loadedPlanPhasesId, 
        Session.loadedPlanProjectsId, 
        Session.waitingForPlanPhases,
        Session.waitingForProjects,
        Dispatch, 
        plan
    ])

    if (typeof SessionFavs === 'string') {
        var favProjects = SessionFavs.split(',').map(projectId => {
            return projects.find(project => {
                return project.id === projectId
            })
        })
    }

    // Preventdefault and anonymous function workaround is needed 
    // as MUI Chip onDelete is buggy 
    const onDelete = (e, id) => {
        e.preventDefault()
        setFavSelected(id, false);
    }
    
    const onClickSwitchProject = (e,project) => {
        e.preventDefault()
        let path = makeProjectLink(project)
        history.push(path);
    }
    
    return (
        <div style={{marginTop: '1.25rem', marginLeft: '0.2rem'}}>
            {resources.map(resource => (
                resource.hasList ?
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={get(resource,'options.label') || resource.name}
                        leftIcon={resource.icon ? createElement(resource.icon) : <LabelIcon/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    /> : null
            ))}
            <Divider/>
            { open ? <ListSubheader>{ plan ? plan.name : 'Planphasen' }</ListSubheader> : null }
            {planPhases.map(phase => {
                return (
                    <MenuItemLink
                        key={phase.name}
                        to={makePlanPhaseLink(phase)}
                        primaryText={phase.name}
                        leftIcon={<DateRangeIcon/>}
                        sidebarIsOpen={open}
                    />
                )
            })}
            {favProjects && <Divider/>}
            {favProjects && open && <ListSubheader>Favoriten</ListSubheader> }
            {favProjects && favProjects.map(project => {
                if(typeof project === 'object'){
                    return (
                        <Grow in={true}  mountOnEnter unmountOnExit>
                            {open ?
                                <MenuItemLink
                                    style={{paddingTop:0,paddingBottom:0}}
                                    to={false}//makeProjectLink(project)
                                    key={'project-'+project.id}
                                    primaryText={
                                        <Chip
                                            // icon={<StarBorder />}
                                            style={{border:0,marginLeft:-12,maxWidth:180,background:'transparent'}}
                                            variant={"outlined"}
                                            label={project.name}
                                            onClick={e=>{return onClickSwitchProject(e,project)}}
                                            onDelete={e=>{return onDelete(e,project.id)}}
                                        />
                                    }
                                    // primaryText={}
                                    leftIcon={<StarBorder/>}
                                    sidebarIsOpen={true}
                                />
                                :  <MenuItemLink
                                    key={'project-sm-'+project.id}
                                    to={makeProjectLink(project)}
                                    primaryText={project.name}
                                    leftIcon={<StarBorder/>}
                                    sidebarIsOpen={false}
                                />
                            }
                        </Grow>
                    )    
                }
                return null;
            })}
            {isXSmall && logout}
        </div>
    );
}

export default withRouter(Menu);