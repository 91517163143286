import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {useDataProvider, Loading, Error} from 'react-admin';
import {Avatar,List,Badge,Card,CardContent,TextField,ListItem,Divider,ListItemText,ListItemAvatar} from '@material-ui/core';
import {Baseurl} from "../Entrypoint";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from '@fullcalendar/interaction';
import get from 'lodash/get';
import useWindowDimensions from "../Hook/useWindowDimensions";
const useTraceUpdate = (props, logid) => {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log(logid + ' Changed props:', changedProps);
        }
        prev.current = props;
    });
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    badgeAssistenz: {
        backgroundColor: "darkred",
        color:"lightgray",
    },
    badgeBuehne: {
        backgroundColor: "pink",
        color:"darkblue",
    },
    badgeTon: {
        backgroundColor: "blue",
        color:"white",
    },
    badgeVideo: {
        backgroundColor: "orange",
        color:"darkbrown",
    },
    badgeLicht: {
        backgroundColor: "yellow",
        color:"darkbrown",
    },
    badgeSpielortleiter: {
        backgroundColor: "lawngreen",
        color: "darkgreen"
    },
    badgeUnbekannt: {
        backgroundColor: "gray",
        color: "black"
    }
}));

const unknownStr = 'Unbekannt';

export const UserBadge = ({user,classes}) => {
    var positionTypeStr = get(user, 'defaultPositionType.name') || unknownStr;
    if(positionTypeStr==='Bühne'){
        positionTypeStr='Buehne';
    }
    var positionTypeChar = positionTypeStr.toUpperCase().charAt(0);
    return (
        <React.Fragment >
            <ListItem {...{"data-title": user.fullName, "data-id": user.id, "data-type": "employee", "key": user.id}} classes={{root: "halleluja"}} alignItems="flex-start">
                <ListItemAvatar>
                    {user.type.textIndex === 'block'
                        ? <Avatar alt={user.fullName} src={Baseurl + user.avatarUrl}/>
                        : <Badge
                            classes={{badge: get(classes,"badge" + positionTypeStr) || 'badgeUnbekannt' }}
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={positionTypeChar}
                        >
                            <Avatar alt={user.fullName} src={Baseurl + user.avatarUrl}/>
                        </Badge>
                    }
                </ListItemAvatar>
                <ListItemText
                    primary={user.fullName}
                    secondary={user.locationsStr + " (" +positionTypeStr +")"}
                />
            </ListItem>
            <Divider variant="inset" component="li"/>
        </React.Fragment>
    )
}

export const UsersList = React.memo(({theme, setStickyFilters, stickyFilters, plan,  ...props}) => {
    
    useTraceUpdate({theme, plan, setStickyFilters, stickyFilters,   ...props}, "UsersList changed");

    const filter= useMemo(()=> {
       return plan ? {plans: [plan.originId]}:null; 
    },[plan]) 
    const dataProvider = useDataProvider();
    const [users, setUsers] = useState();
    const [searchPhrase, setSearchPhrase] = useState(stickyFilters && stickyFilters.phrase ? stickyFilters.phrase : '');
    const [employeeType, setEmployeeType] = useState(stickyFilters && stickyFilters.type ? stickyFilters.type : 'all');
    const [positionType, setPositionType] = useState(stickyFilters && stickyFilters.ptype ? stickyFilters.ptype : 'all');
    const [loading, setLoading] = useState(true);
    const [waiting, setWaiting] = useState(true);
    const [draggersLoaded, setDraggersLoaded] = useState(false);
    const [error, setError] = useState();  
    const classes = useStyles();
    const ListRef = useRef(null);
    const UsersListRef = useRef(null);
    const [listContainerOffset,setListContainerOffset] = useState(120);
    const [listUsersOffset,setListUsersOffset] = useState(56);
    const { height, width } = useWindowDimensions();
    const filterList = useCallback((event) => {
        var type = employeeType;
        var ptype = positionType;
        var phrase = searchPhrase;
        if (event) {
            if (event.target.name === 'employee-type') {
                type = event.target.value.toLowerCase();
                setEmployeeType(type);
            } else if (event.target.name === 'position-type') {
                ptype = event.target.value.toLowerCase();
                setPositionType(ptype);
            } else if (event.target.name === 'searchphrase') {
                phrase = event.target.value.toLowerCase();
                setSearchPhrase(phrase);
            }
            setStickyFilters({type,ptype,phrase});
        }
    },[employeeType,positionType,searchPhrase,setSearchPhrase,setPositionType,setEmployeeType,setStickyFilters])
    const getFilteredUsers = () => {

        var type = employeeType;
        var ptype = positionType;
        var phrase = searchPhrase;
        var tmpUsers = users;

        if (type && type !== 'all') {
            tmpUsers = tmpUsers.filter(function (user) {
                return (
                    (type === 'alias' && user.isAlias)
                    ||  (type === 'freelancer' && !user.isAlias && user.isFreelancer)
                    ||  (type === 'employee' && !user.isAlias && !user.isFreelancer)
                );
            })
        }

        if (ptype && ptype !== 'all') {
            tmpUsers = tmpUsers.filter(function (user) {
                if (user.defaultPositionType){
                    var uPosType = user.defaultPositionType.name.toLowerCase();
                    return (ptype === uPosType);
                }
                return ptype === 'unbekannt' && !user.defaultPositionType;
            })
        }

        if (phrase && phrase !== '') {
            tmpUsers = tmpUsers.filter(function (user) {
                var positionTypeStr = user.defaultPositionType ? (user.defaultPositionType.name ?? unknownStr) : unknownStr;
                return (
                    user.fullName.toLowerCase().search(phrase) !== -1
                    || user.locationsStr.toLowerCase().search(phrase) !== -1
                    || positionTypeStr.toLowerCase().search(phrase) !== -1
                );
            })
        }
        return tmpUsers;
    }
    
    // Load employees and make them draggable
    useEffect(() => {
        if(loading){
            dataProvider.getList('employees', {
                pagination:{page:1,perPage:500},
                sort:{field:"id",order:"asc" },
                filter: {context:['emp-short'],...filter},
            })
            .then(({ data }) => {
                setUsers(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
            })
        }
                    
        // make sure there is only one dragging eventlistener
        if(!loading && !draggersLoaded){
            if(document.getElementById("draggable-users-list")){
                setDraggersLoaded(true);
                new Draggable(document.getElementById("draggable-users-list"), {
                    itemSelector: ".halleluja",
                    eventData: function(eventEl) {
                        return {
                            title: eventEl.getAttribute("data-title"),
                            extId: eventEl.getAttribute("data-id"),
                            extTitle: eventEl.getAttribute("data-title"),
                            extType: eventEl.getAttribute("data-type"),
                        };
                    }
                });    
            }
        }
    }, [dataProvider,filter,setLoading,setWaiting,loading,waiting,draggersLoaded,setDraggersLoaded]);
    
    useEffect(() => {
        if (ListRef.current) {
            setListContainerOffset(ListRef.current.getBoundingClientRect().top)
        }
        if (UsersListRef.current) {
            setListUsersOffset(UsersListRef.current.getBoundingClientRect().top)
        }
    }, [setListContainerOffset, setListUsersOffset, width, height])

    if (loading) return <Loading/>;
    if (error) return <Error error={error}/>;
    if (!users) return null;

    return (
        <div ref={ListRef} style={{"height": (height - listContainerOffset - 24) + "px", "overflow": 'hidden'}}>
            <Card variant="outlined">
                <CardContent>
                    <FormControl margin={"dense"} size={"small"} className={classes.formControl} fullWidth>
                        <Select
                            id="employee-type"
                            name="employee-type"
                            value={employeeType}
                            onChange={filterList}
                            autoWidth
                        >
                            <MenuItem value={"all"}>Alle Typen</MenuItem>
                            <MenuItem value={"employee"}>Angestellte</MenuItem>
                            <MenuItem value={"freelancer"}>Selbständige</MenuItem>
                            <MenuItem value={"alias"}>Aliase</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl  margin={"dense"} size={"small"} className={classes.formControl} fullWidth>
                        <Select
                            id="position-type"
                            name="position-type"
                            value={positionType}
                            onChange={filterList}
                            autoWidth
                        >
                            <MenuItem value={"all"}>Alle Funktionen</MenuItem>
                            <MenuItem value={"assistenz"}>Assistenz</MenuItem>
                            <MenuItem value={"bühne"}>Bühne</MenuItem>
                            <MenuItem value={"licht"}>Licht</MenuItem>
                            <MenuItem value={"spielortleiter"}>Spielortleiter</MenuItem>
                            <MenuItem value={"ton"}>Ton</MenuItem>
                            <MenuItem value={"unbekannt"}>Unbekannt</MenuItem>
                            <MenuItem value={"video"}>Video</MenuItem>
                        </Select>
                        <TextField name="searchphrase" id="searchphrase" label="Suchen" type="search" defaultValue={stickyFilters && stickyFilters.phrase ? stickyFilters.phrase:''} onChange={filterList} fullWidth/>
                    </FormControl>
                </CardContent>
            </Card>
            <Card ref={UsersListRef}  variant="outlined" style={{"height":(height - listUsersOffset - 24) + "px", "overflow":"auto", "overflowX": "hidden"}} >
                <CardContent>
                    <List id="draggable-users-list">
                        {users ? getFilteredUsers().map((user)=>{ 
                            return UserBadge({user,classes});
                        }):null}
                    </List>
                </CardContent>
            </Card>
        </div>
    )
});

