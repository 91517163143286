const SessionReducer = (previousState = {}, {type, ...data}) => {
    switch (type) {
        case 'SESSION_ADD':
            let newSession = {
                ...previousState,
                ...data
            };
            // localStorage.setItem('session', JSON.stringify(newSession));
            // console.log("reducer set session", newSession)
            return newSession
        case 'SESSION_REMOVE':
            Object.getOwnPropertyNames(data).forEach(name => {
                // console.log("auth remove name",name)
                if (previousState[name]) {
                    delete previousState[name]
                }
            });
            break;
        default:
            break;
    }
    return previousState;
}

export default SessionReducer;