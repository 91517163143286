import React from "react";
import {
    ListGuesser,
} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {ChipField, ReferenceArrayField, SingleFieldList, TextField} from "react-admin";

export const ProductionList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser filters={false} {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <TextField source={"name"} />
            
            <ReferenceArrayField sortBy={"project.name"} link={false} reference={"projects"} source={"projects"}>
                <SingleFieldList linkType={false}>
                    <ChipField  source={"name"} />
                </SingleFieldList>
            </ReferenceArrayField>
            
        </ListGuesser>
    );
}