import * as React from 'react';
import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import ContentAdd from '@material-ui/icons/Add';
import classnames from 'classnames';
import MenuItem from "@material-ui/core/MenuItem";
import {Button} from '@material-ui/core'

const useStyles = makeStyles(
    {
        root: { display: 'inline-block' },
    },
    { name: 'RaCreateButtonMenu' }
);

export const CreateButtonMenuItem = ({onClick,label}) =>{
    return (
        <span onClick={onClick}>{label}</span>
    )
}

export const CreateButtonMenu = props => {
    const {
        classes: classesOverride,
        className,
        onClickCreate,
        ...rest
    } = props;
    
    const [open, setOpen] = useState(false);
    const anchorEl = useRef();
    const classes = useStyles(props);

    const handleClickButton = useCallback(
        event => {
            // This prevents ghost click.
            event.preventDefault();
            setOpen(true);
            anchorEl.current = event.currentTarget;
        },
        [anchorEl, setOpen]
    );

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleShow = useCallback(
        ({event,...childProps}) => {
            if(typeof childProps.onClick === 'function'){
                childProps.onClick(event)
            }
            setOpen(false);
        },
        [ setOpen]
    );

    return (
        <div className={classnames(classes.root, className)} {...rest}>
            <Button
                color={"primary"}
                className="new-entity-item"
                label="ra.action.add_filter"
                onClick={handleClickButton}
                size={"small"}
            >
                <ContentAdd style={{fontSize:"1.1rem"}} /> Neu
            </Button>
            <Menu
                open={open}
                anchorEl={anchorEl.current}
                onClose={handleRequestClose}
                keepMounted
            >
                {props.children.map(child=>{
                    return (
                        <MenuItem
                            key={child.props.label}
                            style={{alignItems:'center'}}
                            className="new-entity-item"
                            onClick={event=>handleShow({event,...child.props})}
                        >
                            {child.props.label}
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    );
};

CreateButtonMenu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
};

