import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {TextField} from "react-admin";
import {isAdmin} from "../DataProvider/Authorization";

export const AbsenceTypeList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser filters={false} {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <TextField source={"name"}/>
        </ListGuesser>
    );
}