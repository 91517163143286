import React, {useEffect, useState} from "react";
import {Form} from "react-final-form";
import {Box, Button} from "@material-ui/core";
import {MyDateTimeInput} from '../Form/FormInputs';
import {addDays} from "date-fns";

// YYYY-MM-DD 
export const GetDateOverlapDates = (filterValues) => {
    if (filterValues['dateTimeFrom[date_overlaps_range]']) {
        var [strFrom, strUntil] = filterValues['dateTimeFrom[date_overlaps_range]'].split(',')
        return [strFrom.substr(0, 10), strUntil.substr(0, 10)];
    }
    return [];
}

export const DateOverlapsRangeFilter = ({
                                            displayedFilters,
                                            filterValues,
                                            setFilters,
                                            hideFilter,
                                            showFilter,
                                            open,
                                            variant
                                        }) => {
    
    var [strFrom, strUntil] = GetDateOverlapDates(filterValues);
    const [from, setFrom] = useState(strFrom)
    const [until, setUntil] = useState(strUntil)

    // Submit triggers filter update, using state
    // Submitted values may be outdated (effect not yet run) 
    // so we use state directly 
    const onSubmit = (changedValues) => {
        var fromDate = new Date(from);
        var untilDate = new Date(until);
        if (untilDate > fromDate) {
            filterValues['dateTimeFrom[date_overlaps_range]'] = fromDate.toISOString() + ',' + untilDate.toISOString();
            setFilters(filterValues);
        }
    };
    
    // Make sure filter gets updated when other filters change overlap daterange
    useEffect(() => {
        var [strFrom, strUntil] = GetDateOverlapDates(filterValues);
        setFrom(strFrom)
        setUntil(strUntil)
    }, [filterValues, setFrom, setUntil])
    
    return (
        <div>
            <Form onSubmit={onSubmit} variant={variant}>
                {({variant}) =>{
                    return (
                        <div>
                            {/*<Box mt={8}/>*/}
                            <Box display="flex" alignItems="flex-end" mb={0}>
                                <Box component="span" mr={1} mb={0}>
                                    <MyDateTimeInput
                                        showTime={false}
                                        // options={{required: true,dateformat:'P'}}
                                        options={{required: true}}
                                        source="dateFrom"
                                        label={"Von"}
                                        onChange={value=>setFrom(value)}
                                        defaultValue={from ? new Date(from) : null}
                                        variant={variant}
                                    />
                                </Box>
                                <Box component="span" mr={1}>
                                    <MyDateTimeInput
                                        showTime={false}
                                        // options={{required: true,dateformat:'P',mindate:new Date(from)}}
                                        options={{required: true, minDate: addDays(new Date(from),1)  }}
                                        source="dateUntil"
                                        label={"Bis"}
                                        onChange={value=>setUntil(value)}
                                        defaultValue={until ? new Date(until) : null}
                                        variant={variant}
                                    />
                                </Box>
                                <Box component="span" mr={1} mb={1}>
                                    <Button variant="outlined" color="primary" type="button" onClick={onSubmit}>
                                        Filter
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    ) 
                } }
            </Form>
        </div>
    );
};