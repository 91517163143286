module.exports = {
    resources: {
        imports: {
            name: 'Importieren',
            fields: {
                dateTimeImport: 'Importiert',
                rootElement: 'Datentyp',
                success: 'Erfolgreich'
            }
        },
        employee_allocations: {
            name: 'Einsatzplanung'
        },
        projects: {
            name: 'Projekte',
            fields: {
                dateTimeFrom: 'Von',
                dateTimeUntil: 'Bis',
                location: 'Standort'
            }
        },
        plan_phases: {
            name: 'Planphasen',
            fields: {
                projects: 'Projekte',
                planCategory: 'Plankategorie',
                dateTimeFrom: 'Von',
                dateTimeUntil: 'Bis'
            }
        },
        plan_categories: {
            name: 'Plankategorien',
            fields: {
                projects: 'Projekte',
                planPhases: 'Planphasen'
            }
        },
        plans: {
            name: 'Planungen',
            fields: {
                dateTimeFrom: 'Von',
                dateTimeUntil: 'Bis',
                progress: 'Status',
            }
        },
        productions: {
            name: 'Produktion',
            fields: {
                performances: 'Vorstellungen',
                projects: 'Projekte',
            }
        },
        groups: {
            name: 'Gruppen',
            fields: {
                project: 'Projekt',
                dateTimeFrom: 'Von',
                dateTimeUntil: 'Bis',
                groupBudgets: 'Budgets',
                remarks: 'Bemerkungen',
                originalGroupID: 'Filemaker-ID',
                valid: 'Gültig',
            }
        },
        group_budgets: {
            name: 'Budgets',
            fields: {
                allocationGroup: 'Gruppe',
                positionType: 'Funktionsgruppe',
                hourlyRate: 'Ansatz',
                fteAllocation: 'FTE',
            }
        },
        events: {
            name: 'Ereignisse',
            fields: {
                project: 'Projekt',
                type: 'Ereignis',
                dateTimeFrom: 'Von',
                dateTimeUntil: 'Bis',
            }
        },
        event_types: {
            name: 'Ereignistypen',
            fields: {
                textIndex: 'Index',
                events: 'Ereignisse',
            }
        },
        performances: {
            name: 'Vorstellungen',
            fields: {
                title: 'Titel',
                production: 'Produktion',
                dateTimeFrom: 'Von',
                dateTimeUntil: 'Bis',
            }
        },
        employees: {
            name: 'Mitarbeiter',
            fields: {
                firstName: 'Vorname',
                lastName: 'Nachname',
                employeeType: 'Typ',
                locations: 'Standorte',
                hourlyRate: 'Ansatz',
                presences: 'Anwesenheiten',
                absences: 'Abwesenheiten',
                positions: 'Funktionen',
            }
        },
        presences: {
            name: 'Anwesenheiten',
            fields: {
                employee: 'Mitarbeiter',
                dateTimeFrom: 'Von',
                dateTimeUntil: 'Bis',
            }
        },
        absences: {
            name: 'Abwesenheiten',
            fields: {
                employee: 'Mitarbeiter',
                dateTimeFrom: 'Von',
                dateTimeUntil: 'Bis',
                type: 'Typ',
            }
        },
        locations: {
            name: 'Standorte',
            fields: {
                type: 'Typ',
                project: 'Projekte',
                plan: 'Pläne',
            }
        },
        absence_types: {
            name: 'Abwesenheitstypen',
        },
        employee_types: {
            name: 'Mitarbeitertypen',
            fields: {
                textIndex: 'Index',
            }
        },
        location_types: {
            name: 'Standorttypen',
            fields: {
                locations: 'Standorte'
            }
        },
        position_types: {
            name: 'Funktionstypen',
            fields: {
                positions: 'Funktionen',
                hourlyRate: 'Ansatz',
            }
        },
        positions: {
            name: 'Funktionen',
            fields: {
                positionType: 'Funktionstyp',
                ansatz: 'Ansatz',
            }
        }
    },
    ra: {
        action: {
            delete: 'Löschen',
            show: 'Anzeigen',
            list: 'Liste',
            save: 'Speichern',
            create: 'Erstellen',
            edit: 'Bearbeiten',
            sort: 'Sortieren',
            cancel: 'Abbrechen',
            confirm: 'Bestätigen',
            undo: 'Zurücksetzen',
            refresh: 'Aktualisieren',
            add: 'Neu',
            remove: 'Entfernen',
            add_filter: 'Filter',
            remove_filter: 'Filter entfernen',
            back: 'Zurück',
            bulk_actions: 'Ein Element ausgewählt |||| %{smart_count} Elemente ausgewählt',
            clear_input_value: 'Eingabe löschen',
            clone: 'Klonen',
            export: 'Exportieren',
            search: 'Suchen',
            expand: 'Erweitern',
            unselect: 'Nicht mehr selektieren'
        },
        boolean: {
            true: 'Ja',
            false: 'Nein',
        },
        page: {
            list: '%{name}',
            edit: '%{name} #%{id}',
            show: '%{name} #%{id}',
            create: '%{name} erstellen',
            delete: '%{name} #%{id} löschen',
            delete_generic: '%{name} Element löschen',
            dashboard: 'Dashboard',
            not_found: 'Nicht gefunden',
            error: 'Etwas ist schief gelaufen',
            empty: 'Noch keine %{name} vorhanden',
            loading: "Lädt",
            invite: "Jetzt hinzufügen?",
        },
        input: {
            file: {
                upload_several: 'Zum Hochladen Dateien hineinziehen oder hier klicken, um Dateien auszuwählen.',
                upload_single: 'Zum Hochladen Datei hineinziehen oder hier klicken, um eine Datei auszuwählen.',
            },
            image: {
                upload_several: 'Zum Hochladen Bilder hineinziehen oder hier klicken, um Bilder auszuwählen.',
                upload_single: 'Zum Hochladen Bild hineinziehen oder hier klicken, um eine Bild auszuwählen.',
            },
            references: {
                all_missing: 'Die zugehörigen Referenzen konnten nicht gefunden werden.',
                many_missing: 'Mindestens eine der zugehörigen Referenzen scheint nicht mehr verfügbar zu sein.',
                single_missing: 'Eine zugehörige Referenz scheint nicht mehr verfügbar zu sein.',
            },
        },
        message: {
            yes: 'Ja',
            no: 'Nein',
            are_you_sure: 'Bist du sicher?',
            about: 'Über',
            not_found: 'Die Seite konnte nicht gefunden werden.',
            loading: 'Die Seite wird geladen.',
            invalid_form: 'Das Formular ist ungültig. Bitte überprüfen Sie ihre Eingaben.',
            delete_title: 'Lösche %{name} #%{id}',
            delete_title_generic: 'Löschen bestätigen',
            delete_content: 'Möchten Sie dieses Element wirklich löschen?',
            bulk_delete_title: 'Lösche %{name} |||| Lösche %{smart_count} %{name} Elemente',
            bulk_delete_content: 'Möchten Sie "%{name}" wirklich löschen? |||| Möchten Sie diese %{smart_count} Elemente wirklich löschen?',
            error: 'Ein Fehler ist aufgetreten und ihre Anfrage konnte nicht abgeschlossen werden.',
        },
        navigation: {
            no_results: 'Keine Resultate gefunden',
            no_more_results: 'Die Seite %{page} enthält keine Inhalte.',
            page_out_of_boundaries: 'Die Seite %{page} liegt ausserhalb des gültigen Bereichs',
            page_out_from_end: 'Letzte Seite',
            page_out_from_begin: 'Erste Seite',
            page_range_info: '%{offsetBegin}-%{offsetEnd} von %{total}',
            page_rows_per_page: 'Zeilen pro Seite:',
            next: 'Weiter',
            prev: 'Zurück',
            skip_nav: 'Navigation überspringen'
        },
        auth: {
            user_menu: 'Profil',
            username: 'Nutzername',
            password: 'Passwort',
            sign_in: 'Anmelden',
            sign_in_error: 'Fehler bei der Anmeldung',
            logout: 'Abmelden',
        },
        notification: {
            updated: 'Element wurde aktualisiert |||| %{smart_count} Elemente wurden aktualisiert',
            created: 'Element wurde erstellt',
            deleted: 'Element wurde gelöscht |||| %{smart_count} Elemente wurden gelöscht',
            bad_item: 'Fehlerhaftes Elemente',
            item_doesnt_exist: 'Das Element existiert nicht',
            http_error: 'Fehler beim Kommunizieren mit dem Server',
            canceled: 'Aktion abgebrochen',
            logged_out: 'Die Sitzung ist abgelaufen. Bitte erneut anmelden.'
        },
        validation: {
            required: 'Benötigt',
            minLength: 'Muss mindestens %{min} Zeichen lang sein',
            maxLength: 'Darf maximal %{max} Zeichen lang sein',
            minValue: 'Muss mindestens %{min} sein',
            maxValue: 'Muss %{max} oder weniger sein',
            number: 'Muss eine Nummer sein',
            email: 'Muss eine gültige E-Mail sein',
            oneOf: 'Es muss einer sein von: %{options}',
            regex: 'Es muss folgendem regulären Ausdruck entsprechen: %{pattern}',        },
    },
};
