export default function useInitial() {
    var initialState = {};
    try {
        const uid = localStorage.getItem('uid');
        const state = localStorage.getItem('state.' + uid);
        var json = JSON.parse(state)
        if (json && json.hasOwnProperty('session')) {
            initialState = json
        }
    } catch (err) {
        initialState = {};
    }
    return initialState;
}