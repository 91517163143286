import React from "react";
import GetPlan from "../Session/getPlan";
import {useDispatch} from 'react-redux';
import {
    Redirect,
} from "react-router-dom";

const SwitchPlan = ({match, ...rest}) => {

    const plan = GetPlan();
    const dispatch = useDispatch();

    if (match.params && match.params.id) {
        if(match.params.id !== plan.originId){
            dispatch({type: 'SESSION_ADD', PlanID: match.params.id})
            localStorage.setItem('PlanID', match.params.id);    
        }

    }
    return (
        <Redirect to="/"/>
    );
}

export default SwitchPlan;