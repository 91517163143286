import React from "react";
import {
    ListGuesser,
} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {ChipField, DateField, ReferenceField} from "react-admin";

export const PresenceList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser filters={false} {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <ReferenceField sortBy={"employee.fullName"} link={false} reference={"employees"} source={"employee"}>
                <ChipField source={"fullName"} />    
            </ReferenceField>

            <DateField showTime={true} source={"dateTimeFrom"}/>
            <DateField showTime={true} source={"dateTimeUntil"}/>
            
            <ReferenceField sortBy={"plan.name"} link={false} reference={"plans"} source={"plan"}>
                <ChipField source={"name"} />
            </ReferenceField>
            
        </ListGuesser>
    );
}