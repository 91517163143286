import {useEffect, useRef} from "react";

export const Serializer = function(obj, prefix) {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                Serializer(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

export const Showme = ({debugStr,...props})=>{
    console.log(debugStr,props)
    return null;
}

/**
 * Check for valid date object 
 * @param d
 * @returns {boolean|boolean}
 */
export const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
}

/**
 * Debugging sniplet
 * @param props
 */
export const useTraceUpdate = (props,debugStr) => {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log(debugStr+' TRACE changed props:', changedProps);
        }
        prev.current = props;
    });
}

// safely handles circular references
export const SafeStringify = (obj, indent = 2) => {
    let cache = [];
    const retVal = JSON.stringify(
        obj,
        (key, value) =>
            typeof value === "object" && value !== null
                ? cache.includes(value)
                ? undefined // Duplicate reference found, discard key
                : cache.push(value) && value // Store value in our collection
                : value,
        indent
    );
    cache = null;
    return retVal;
};

export const dateFormatter = (value,showTime) => {
    let v = value
    if(v){
        let d = new Date(v)
        if (!(d instanceof Date) || isNaN(d)) return v;
        if((typeof showTime !== undefined) && showTime){
            return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
                d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        } else {
            return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." + d.getFullYear();
        }
    }
    return '';
};
