
import React, {Fragment, useCallback, useState} from "react";
import RichTextInput from 'ra-input-rich-text';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TheatersIcon from '@material-ui/icons/Theaters';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import {
    LocationOnOutlined,
} from '@material-ui/icons';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import ContactPhoneRoundedIcon from '@material-ui/icons/ContactPhoneRounded';
import {Avatar, Typography, Chip, Divider, Card, CardHeader, CardContent, CardActions} from '@material-ui/core';
import GetPlan, {GetPlanID} from "../Session/getPlan";
import get from 'lodash/get';
import {dateFormatter, isValidDate, useTraceUpdate} from '../Tools/Tools'
import {ArrayRelationList, DateTimeFromUntil} from "../Form/Lists";
import Alert from '@material-ui/lab/Alert';
import {subMinutes, addMinutes, parseISO} from "date-fns";

import {
    Show,
    SimpleShowLayout,
    ReferenceField,
    DateField,
    ReferenceInput,
    SelectInput,
    BooleanField,
    SimpleForm,
    Create,
    TextField,
    List,
    Datagrid,
    ShowButton,
    EditButton,
    FormDataConsumer,
    Filter,
    AutocompleteInput, 
    RichTextField,
} from "react-admin";
import { useForm ,useFormState} from 'react-final-form';
import {InnerFormHeadline, InverseBooleanField} from "../Form/FormFields"
import {isAdmin} from "../DataProvider/Authorization";
import {Baseurl} from "../Entrypoint";
import {CustomSaveEdit} from "../Form/CustomSaveEdit";
import {SaveDeleteToolbar} from "../Form/SaveDeleteToolbar";
import {MyDateTimeInput, TransformDefaultDateTimeFields} from "../Form/FormInputs";
import {BooleanInput} from "ra-ui-materialui";

const EmployeeAllocationsListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Mitarbeiter" source="employee" reference="employees" allowEmpty>
            <SelectInput variant="outlined" optionText="fullName"/>
        </ReferenceInput>
        <ReferenceInput label="Funktion" source="position" reference="positions" allowEmpty>
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Gruppe" source="allocationGroup" reference="groups" allowEmpty>
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Vorstellung" source="allocationGroup.performance" reference="performances" allowEmpty>
            <SelectInput optionText="title"/>
        </ReferenceInput>
    </Filter>
);

export const EmployeeAllocationList = (props) => {
    var admin = isAdmin(props);
    return (
        <List filters={<EmployeeAllocationsListFilter/>}{...props} hasEdit={admin} hasCreate={admin}>
            <Datagrid {...props} >
                <ReferenceField label="Mitarbeiter" source="employee.@id" sortBy="employee.lastName"
                                reference="employees" link="show">
                    <TextField source="fullName"/>
                </ReferenceField>
                <TextField label="Mitarbeiter-ID" source="employee.id"/>
                <DateField label="Einsatz Beginn" showTime={true} source="dateTimeFrom"/>
                <DateField label="Einsatz Ende" showTime={true} source="dateTimeUntil"/>
                <TextField label="Funktion" source="position.name"/>

                <ReferenceField label="Gruppe" source="allocationGroup.@id" sortBy="allocationGroup.name"
                                reference="groups" link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField label="Gruppe Filemaker-ID" source="allocationGroup.originalGroupID"/>
                <DateField label="Gruppe Beginn" showTime={true} source="allocationGroup.dateTimeFrom"/>
                <DateField label="Gruppe Ende" showTime={true} source="allocationGroup.dateTimeUntil"/>
                <TextField label="Vorstellung" source="allocationGroup.performance.title"/>
                <ReferenceField label="Standort" source="allocationGroup.location.@id"
                                sortBy="allocationGroup.location.name" reference="locations"
                                link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField label="Standorttyp" source="allocationGroup.location.type.name" sortable={false}/>
                <TextField label="Plankategorie" source="allocationGroup.planCategory.name" sortable={false}/>

                <InverseBooleanField label="Anwesend" source="notPresent" sortable={false}/>
                <InverseBooleanField label="Ohne Konflikt" source="hasConflict" sortable={false}/>
                <BooleanField label="Gültig" source="valid" sortable={false}/>

                <ShowButton/>
                {admin ? <EditButton/> : null}

            </Datagrid>
        </List>
    )
}


// Need full nested EmployeeAllocation structure in list / calendar, 
// but create/edit needs flat structure with IRIs.   
export const AllocationSaveTransformer = (values) => {
    values.project = values.project ? values.project['@id'] : null;
    values.position = values.position ? values.position['@id'] : null;
    values.location = values.location ? values.location['@id'] : null;
    values.employee = values.employee ? values.employee['@id'] : null;
    values.allocationGroup = values.allocationGroup ? values.allocationGroup['@id'] : null;
    return values;
}

const useStyles = makeStyles({
    // root: {
    //     width: 256,
    // },
    suggestionsContainer: {
        zIndex: 1300,
    },
    
});

export const AllocationCreate = ({projects, notify, toolbar, actions, initialValues, onCancel, onUnselectItems, perPage, selectedIds, ...props}) => {
    
    const classes = useStyles();
    const plan = GetPlan();
    const [selectedGroup,setSelectedGroup] = useState(null)

    const onSuccess = useCallback(record => {
        onCancel();
    },[onCancel])
    const onFailure = useCallback( error => {
        notify(error.message, 'warning')
    },[notify])
    const cb = useCallback(allocationGroup=>{
         setSelectedGroup(allocationGroup)
    },[setSelectedGroup])
    
    let employeePlanFilter = plan ? {plans: plan.id} : null;
    
    return (    
        <Create record={initialValues} transform={TransformDefaultDateTimeFields} onFailure={onFailure} onSuccess={onSuccess} {...props}>
            <SimpleForm
                // initialValues={initialValues}
                variant="outlined" 
                validate={values => validateAllocationCreate(values, projects, selectedGroup)}>

                <InnerFormHeadline headline={"Neuer Einsatz"} />

                {/*Use two formdataconsumers, because otherwise both inputs get wrapped into the same container which breaks the layout */}
                <FormDataConsumer fullWidth>
                    {formDataProps => (
                        <ProjectSelector source={"project"} {...formDataProps} />
                    )}
                </FormDataConsumer>
                <FormDataConsumer fullWidth>
                    {formDataProps => (
                        <ProjectGroupSelector cb={cb} source={"allocationGroup"} {...formDataProps} />
                    )}
                </FormDataConsumer>
                <FormDataConsumer fullWidth>
                    {formDataProps => (
                        <ProjectLocationSelector source={"location"} {...formDataProps} />
                    )}
                </FormDataConsumer>
                <ReferenceInput 
                    fullWidth
                    filter={{
                        context: ['emp-short'],
                        ...employeePlanFilter
                    }} 
                    perPage={100}
                    filterToQuery={searchText => ({ fullName: searchText })} 
                    label="Mitarbeiter" 
                    source="employee" 
                    reference="employees">
                    {/*https://github.com/marmelab/react-admin/issues/4537*/}
                    {/*Issue with Autocomplete suggestion behind modal/drawer*/}
                    <AutocompleteInput
                        options={{fullWidth:true, classes: classes, suggestionsContainerProps: { className: classes.suggestionsContainer }}}
                        variant="outlined" 
                        fullWidth={true}
                        optionText="fullName" 
                        allowEmpty={false} />
                </ReferenceInput>

                <ReferenceInput fullWidth label="Funktion" source="position" reference="positions" perPage={500}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <MyDateTimeInput defaultValue={selectedGroup?new Date(selectedGroup.dateTimeFrom):null} options={{
                    minDate: selectedGroup ? parseISO(selectedGroup.dateTimeFrom) : null,
                    maxDate: selectedGroup ? subMinutes(parseISO(selectedGroup.dateTimeUntil), 30) : null,
                }} fullWidth label="Beginn" source={"dateTimeFrom"} showTime={true}/>
                <MyDateTimeInput defaultValue={selectedGroup?new Date(selectedGroup.dateTimeUntil):null} options={{
                        minDate:selectedGroup ?addMinutes(parseISO(selectedGroup.dateTimeUntil),30) : null,
                        maxDate:selectedGroup ? parseISO(selectedGroup.dateTimeUntil) : null,
                }} fullWidth label="Ende" source={"dateTimeUntil"} showTime={true}/>
                <RichTextInput label={"Bemerkungen"} defaultValue={""} source="remarks" toolbar={[ ['bold', 'italic', 'underline'] ]} />
            </SimpleForm>
        </Create>
    );
}

export const AllocationEdit = ({projects, updater, toolbar, actions, onCancel, onDelete, notify, objectSetter, objectTypeSetter, ...props}) => {
useTraceUpdate({projects, updater, toolbar, actions, onCancel, onDelete, notify, objectSetter, objectTypeSetter, ...props},"spacko")
    const plan = GetPlan();
    const classes = useStyles();
    const employeePlanFilter = plan ? {plans: plan.id} : null;
    const positionsFilter = plan ? {plan:[plan.id]}:null;
    const [selectedGroup,setSelectedGroup] = useState(null)
    const onSuccess = onCancel;
    const onFailure = useCallback(error => {
        notify(error.message, 'warning')
    },[notify]);
    const cb = useCallback(allocationGroup => {
        setSelectedGroup(allocationGroup)
    }, [setSelectedGroup])
    const save = useCallback(p => {
        return updater({
            payload: {
                id: p.id,
                data: TransformDefaultDateTimeFields(AllocationSaveTransformer(p))
            }
        }, {
            onSuccess: onSuccess,
            onFailure: onFailure
        })
    },[updater,onSuccess,onFailure])
    
    return (
        <CustomSaveEdit save={save} {...props} >
            <SimpleForm 
                toolbar={<SaveDeleteToolbar 
                    mutationMode={"undoable"} 
                    onRedirect={onDelete}
                    deleteConfirmContent={"Soll dieser Einsatz wirklich gelöscht werden?"}
                />} 
                variant={"outlined"} 
                validate={values => validateAllocationEdit(values, projects, selectedGroup)}
            >
                <InnerFormHeadline headline={"Einsatz bearbeiten"} />
                <AllocationWarning roomyBottom objectSetter={objectSetter} objectTypeSetter={objectTypeSetter} />
                
                {/*Use two formdataconsumers, because otherwise both inputs get wrapped into the same container which breaks the layout */}
                <FormDataConsumer fullWidth>
                    {formDataProps => (
                        <ProjectSelector source={"project.@id"} {...formDataProps} />
                    )}
                </FormDataConsumer>
                <FormDataConsumer fullWidth>
                    {formDataProps => (
                        <ProjectGroupSelector cb={cb} source={"allocationGroup.@id"} {...formDataProps} />
                    )}
                </FormDataConsumer>
                <FormDataConsumer fullWidth>
                    {formDataProps => (
                        <ProjectLocationSelector source={"location.@id"}  {...formDataProps} />
                    )}
                </FormDataConsumer>
                <FormDataConsumer fullWidth>
                    {formDataProps => (
                        <ReferenceInput
                            fullWidth
                            filter={{
                                context: ['emp-short'],
                                ...employeePlanFilter
                            }}
                            perPage={100}
                            filterToQuery={searchText => ({
                                fullName: searchText,
                                employeeAvailableFrom: formDataProps.record.dateTimeFrom,
                                employeeAvailableUntil: formDataProps.record.dateTimeUntil
                            })}
                            label="Mitarbeiter"
                            source="employee.@id"
                            reference="employees">
                            {/*https://github.com/marmelab/react-admin/issues/4537*/}
                            {/*Issue with Autocomplete suggestion behind modal/drawer*/}
                            <AutocompleteInput
                                options={{fullWidth:true, classes: classes, suggestionsContainerProps: { className: classes.suggestionsContainer }}}
                                variant="outlined"
                                fullWidth={true}
                                optionText="fullName"
                                allowEmpty={false} />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                
                <ReferenceInput filter={positionsFilter} fullWidth label="Funktion" source="position.@id" reference="positions" perPage={500}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>

                <MyDateTimeInput defaultValue={ selectedGroup 
                    ? new Date(selectedGroup.dateTimeFromOverride ?? selectedGroup.dateTimeFrom)
                    : null } options={{
                    minDate: selectedGroup ? parseISO(selectedGroup.dateTimeFrom) : null,
                    maxDate: selectedGroup ? subMinutes(parseISO(selectedGroup.dateTimeUntil), 30) : null,
                }} fullWidth label="Beginn" source={"dateTimeFrom"} showTime={true}/>
                <MyDateTimeInput defaultValue={ selectedGroup
                    ? new Date(selectedGroup.dateTimeUntilOverride ?? selectedGroup.dateTimeUntil)
                    : null } options={{
                    minDate:selectedGroup ?addMinutes(parseISO(selectedGroup.dateTimeUntil),30) : null,
                    maxDate:selectedGroup ? parseISO(selectedGroup.dateTimeUntil) : null,
                }} fullWidth label="Ende" source={"dateTimeUntil"} showTime={true}/>
                {/*<MyDateTimeInput fullWidth label="Beginn" source={"dateTimeFrom"} showTime={true}/>*/}
                {/*<MyDateTimeInput fullWidth label="Ende" source={"dateTimeUntil"} showTime={true}/>*/}

                <BooleanInput source={"isAttentionNeeded"} label={"Prüfung benötigt"} options={{
                    checkedIcon: <ErrorIcon />,
                }}/>
                
                <RichTextInput  fullWidth defaultValue={""} label="Bemerkungen" source="remarks" toolbar={[ ['bold', 'italic', 'underline'] ]} />
            </SimpleForm>
        </CustomSaveEdit>
    )
}

const EmployeeBadge = ({record,...props})=>{

    var plan = GetPlan();
    var unknownStr='Unbekannt';

    const locationPlanFilter = (location) => {
        let matchingPlan = location.plan.find(p => p.id === plan.originId);
        return (matchingPlan);
    }
    
    // const classes = useStyles();
    var positionTypeStr = record.defaultPositionType ? (record.defaultPositionType.name ? record.defaultPositionType.name:unknownStr) : unknownStr;
    return (

        <Card key={record.id} style={cardStyle}>
            <CardHeader style={{background:'palegoldenrod'}}
                title={<TextField variant={"subtitle1"} record={record} source="fullName" />}
                subheader={
                    <Fragment>
                        <LocationOnOutlined />
                        <ArrayRelationList record={record} filter={locationPlanFilter} source={"locations"}>
                            <TextField source={"name"} />
                        </ArrayRelationList>
                    </Fragment>
                }
                avatar={<Avatar alt={record.fullName} src={Baseurl + record.avatarUrl}/>}
            />
            <CardActions style={{background: 'lightgoldenrodyellow', justifyContent: 'flex-end' }}>
                {record.isFreelancer
                    ? <Chip label={"Freelance"} color="primary" variant="outlined" icon={<ContactPhoneRoundedIcon/>}/>
                    : <Chip label={"Anstellung"} color="primary" variant="outlined" icon={<PermContactCalendarOutlinedIcon/>}/>
                }
                <Chip label={record.hourlyRate+" Sfr."} color={"primary"} variant="outlined" icon={<AttachMoneyOutlinedIcon/>}/>
            </CardActions>
            <Divider />
            <CardContent>
                {!record.remarks ?
                    <Fragment>
                        <Typography color={"textSecondary"} variant={"caption"} paragraph>Bemerkungen</Typography>
                        <TextField variant="body2" label="Bemerkungen" record={record} source="remarks" />
                    </Fragment>
                    : null
                }
            </CardContent>
            <CardContent>
                {record.position.length ? 
                    <Fragment>
                        <Typography color={"textSecondary"} variant={"caption"} paragraph>Frühere Funktionen</Typography>
                        {/* ArrayField  broken in react-admin@3.6.0 */}
                        {/*https://github.com/marmelab/react-admin/issues/4916*/}
                        {/*<ReferenceArrayField record={record} {...props}  reference="positions" source="position" >*/}
                            <MySingleFieldList source="position" optionText={"name"} record={record} />
                                {/*<ChipField size={"small"}  source="name" />*/}
                            {/*</MySingleFieldList>*/}
                        {/*</ReferenceArrayField> */}
                    </Fragment>
                    : null
                }
            </CardContent>
        </Card>
    )
}

const MySingleFieldList = ({source, record, optionText}) => {
    if (record.hasOwnProperty(source)) {
        return record[source].map(data => {
            return <Chip style={{margin: 2}} size={"small"} label={data[optionText] ?? ''}/>
        });
    }
    return null;
}

const cardStyle = {
    width: '100%',
    minWidth: 280,
    maxWidth: '30vw',
    // minHeight: 120,
    margin: 0,
    marginTop: '0.5em',
    // display: 'inline-block',
    verticalAlign: 'top'
};

const TimelineCard = ({record, objectTypeSetter, objectSetter, ...rest}) => {
    return (
        <Card key={record.id} style={cardStyle}>
            <CardHeader
                title={<TextField record={record} source="position.name"/>}
                subheader={
                    <Fragment>
                        <DateField record={record} options={{
                            day: 'numeric',
                            month: 'numeric',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit'
                        }} label={false} showTime={true} source="dateTimeFrom"/>
                        {' — '}
                        <DateField record={record} options={{
                            day: 'numeric',
                            month: 'numeric',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit'
                        }} label={false} showTime={true} source="dateTimeUntil"/>
                    </Fragment>

                }
                avatar={<Avatar variant="square"><TheatersIcon/></Avatar>}
            />
            {record.remarks &&
                <CardContent>
                    <RichTextField record={record} source="remarks"/>
                </CardContent>
            }
            {(record.hasConflict||record.notPresent||record.tooManyDaysAllocated) &&
                <CardContent>
                    <AllocationWarning objectSetter={objectSetter} objectTypeSetter={objectTypeSetter} record={record} />
                </CardContent>
            }
            <CardActions style={{justifyContent: 'flex-end'}}>
                <Chip label={record.location.name} size={"small"} variant="default" icon={<LocationOnOutlined/>}/>
                {/*<EditButton resource="employee_locations" basePath={basePath} record={data[id]} />*/}
            </CardActions>
        </Card>
    )
}

const Warning = (title,details) => {
    return (
        <Alert severity="warning">
            <Typography variant={"subtitle2"}>{title}</Typography>
            {details}    
        </Alert>
    )
}

const AllocationWarning = ({record,objectSetter,objectTypeSetter,roomyBottom,...props})=>{
    
    const rows = []
    
    if(record.tooManyDaysAllocated){
        rows.push(Warning('Maximale Einsatzdauer überschritten.'))
    }
    if(record.notPresent){
        rows.push(Warning('Zum Zeitpunkt des Einsatzes nicht anwesend.'))
    }
    
    const handleClick = (allocation) => {
        objectTypeSetter('employee')
        objectSetter(allocation['@id'])
    }
    
    if(record.conflictingAbsences && record.conflictingAbsences.length>0) {
        rows.push(Warning('Abwesenheiten:',
            record.conflictingAbsences.map(absence => {
                return (
                    <Chip size={"small"} variant={"default"}
                        label={<DateTimeFromUntil
                            record={absence}
                            fieldFrom={'dateTimeFrom'}
                            fieldUntil={'dateTimeUntil'}
                        />}
                    />
                )
            })
        ))
    }
    if(record.conflictingAllocations && record.conflictingAllocations.length>0) {
        record.conflictingAllocations.forEach(allocation => {
            let projectName = get(allocation,'allocationGroup.project.name');
            let groupName = get(allocation,'allocationGroup.name');
            var text = "Konflikt";
            if(projectName){
                text = text + " mit " + projectName + (groupName ? ', ' + groupName : '');
            } else if (groupName) {
                text = text + " mit " + groupName ;
            }
            text = text + ':';
            
            rows.push(
                Warning( text,
                    <Chip size={"small"} variant={"default"}
                          onClick={() => {handleClick(allocation)}}
                          label={<DateTimeFromUntil
                              record={allocation}
                              fieldFrom={'dateTimeFrom'}
                              fieldUntil={'dateTimeUntil'}
                          />}
                    />
                )
            )
        })
    }
    // record.conflictingAllocations.forEach(allocation=>{
    //     rows.push({name: 'Anderer Einsatz', detail: allocation.allocationGroup.name, from:allocation.dateTimeFrom,until:allocation.dateTimeUntil})
    // })
    if(roomyBottom && rows.length){
        rows.push(<Divider style={{marginBottom:'0.5em'}}  />)
    }
    
    return rows;
}

export const EmployeeInlineShow = ({toolbar, actions, onCancel, objectSetter, objectTypeSetter, ...props}) => {
    const plan = GetPlan();
    return (
        <Show resource={'employee'} {...props} hasEdit={false} hasCreate={false} hasShow={false} >
            <SimpleShowLayout fullWidth variant={"outlined"} toolbar={false}>
                <EmployeeBadge {...props} />
                 {/*<ReferenceArrayField filter={plan ? {'project.plan.id': plan.originId} : null} reference="employee_allocations" target="employee.id">*/}
                 {/*    <TimelineCard />*/}
                 {/*</ReferenceArrayField>*/}
                <ArrayRelationList sortProperty={'dateTimeFrom'} sortDir={"desc"} filter={plan ? {'project.plan.id': plan.originId} : null} source={"employeeAllocations"}>
                    <TimelineCard objectTypeSetter={objectTypeSetter} objectSetter={objectSetter}  />
                </ArrayRelationList>
                <IconButton onClick={onCancel} title={"Schliessen"} aria-label="schliessen">
                    <CloseIcon />
                </IconButton>
            </SimpleShowLayout>
        </Show>
    )
}

const getIdFromFormDataProperty = (formData,property) =>{
    var id = null;
    if(formData.hasOwnProperty(property)){
        if (typeof formData[property] === 'string'){
            id = formData[property];
        } else {
            id = formData[property].hasOwnProperty('@id') ? formData[property]['@id'] : null;
        }
    }
    return id;
}

const ProjectSelector = ({ formData, ...rest }) => {
    const planID =  GetPlanID();
    const planFilter =  {plan: planID, context:['project-short'] };
    const form = useForm();
    return (
        <ReferenceInput
            sortBy="name"
            filter={planFilter} 
            label="Projekt" 
            reference="projects" 
            perPage={500} {...rest}
        >
            <SelectInput optionText="name" onChange={(narf) =>{
                return form.change('allocationGroup', null)
            } 
            } />
        </ReferenceInput>
    );
};

const GroupSelectInput = ({cb,record,...props}) => {
    const { values } = useFormState();
    if(props.choices.length && values.allocationGroup) {
        let groupId = (typeof values.allocationGroup === 'string') 
            ? values.allocationGroup
            : values.allocationGroup['@id'];
        let allocationGroup =props.choices.find(group => group['@id']===groupId);
        if (record && record.allocationGroup && record.dateTimeFrom && record.allocationGroup['@id']===groupId){
            // Same group as saved in record. We make sure not to lose the original setting,
            // do not overwrite with full group span in this case. 
            allocationGroup.dateTimeFromOverride = record.dateTimeFrom;
            allocationGroup.dateTimeUntilOverride = record.dateTimeUntil;
        }
        cb(allocationGroup);
    }
    return (
        <SelectInput {...props} />
    );
}
const ProjectGroupSelector = ({ cb, formData, record, ...rest }) => {
    return (
        <ReferenceInput filter={{ 
            project: getIdFromFormDataProperty(formData,'project'),
            context: ['group-short']
        }} label="Gruppe" reference="groups" perPage={500} {...rest}>
            <GroupSelectInput cb={cb} record={record} optionText="fullName"/> 
        </ReferenceInput>
    );
};

const ProjectLocationSelector = ({formData, ...rest }) => {
    return (
        <ReferenceInput fullWidth={true} filter={{ 
            project: getIdFromFormDataProperty(formData,'project') 
        }} label="Standort" reference="locations" perPage={500} {...rest}>
            <SelectInput fullWidth={true} allowEmpty={false} optionText="name" />
        </ReferenceInput>
    );
};

const validateAllocationEdit = (values, projects, allocationGroup) => {
    const errors = {};

    ['project.@id', 'allocationGroup.@id', 'location.@id', 'employee.@id', 'position.@id'].forEach(key => {
        if (!get(values,key)) {
            errors[key] = ['Notwendige Auswahl'];
        }
    });

    ['dateTimeFrom', 'dateTimeUntil'].forEach(key => {
        if (!isValidDate(new Date(values[key]))) {
            errors[key] = ['Bitte einen Zeitpunkt wählenl'];
        }
    });

        let from = new Date(values.dateTimeFrom);
        let until = new Date(values.dateTimeUntil);

    if (isValidDate(from) && isValidDate(until)) {
        if (until <= from) {
            errors.dateTimeUntil = ['Der Endzeitpunkt muss hinter dem Startzeitpunkt liegen.']
        } else if (values.project) {
            if (projects) {
                let project = projects.find(p => p.id === values.project);
                if (project) {
                    let projectFrom = parseISO(project.dateTimeFrom);
                    let projectUntil = parseISO(project.dateTimeUntil);
            if (from < projectFrom) {
                        errors.dateTimeFrom = ['Der Einsatz kann nicht vor dem Projekt starten (' + dateFormatter(projectFrom, true) + ').']
            }
            if (until > projectUntil) {
                        errors.dateTimeUntil = ['Der Einsatz kann nicht nach dem Projekt enden (' + dateFormatter(projectUntil, true) + ').']
                    }
                }
            }
            }

        if (allocationGroup) {
            if (!errors.dateTimeFrom) {
                let groupFrom = parseISO(allocationGroup.dateTimeFrom);
                if (groupFrom && from < groupFrom) {
                    errors.dateTimeFrom = ['Der Einsatz kann nicht vor der Gruppe starten (' + dateFormatter(groupFrom, true) + ').']
                }
            }
            if (!errors.dateTimeUntil) {
                let groupUntil = parseISO(allocationGroup.dateTimeUntil);
                if (groupUntil && until > groupUntil) {
                    errors.dateTimeUntil = ['Der Einsatz kann nicht nach der Gruppe enden (' + dateFormatter(groupUntil, true) + ').']
                }
        }
    }
    }

    return errors
}

const validateAllocationCreate = (values,projects,allocationGroup) => {
    
    const errors = {};
    
    ['project', 'allocationGroup', 'location', 'employee', 'position'].forEach(key => {
        if (!values[key]) {
            errors[key] = ['Notwendige Auswahl'];
        }
    });
    
    ['dateTimeFrom', 'dateTimeUntil'].forEach(key => {
        if (!values[key]) {
            errors[key] = ['Bitte einen Zeitpunkt wählenl'];
        }
    });

    if (values.dateTimeFrom && values.dateTimeUntil) {
        let from = parseISO(values.dateTimeFrom);
        let until = parseISO(values.dateTimeUntil);
        if (until <= from) {
            errors.dateTimeUntil = ['Der Endzeitpunkt muss hinter dem Startzeitpunkt liegen.']
        } else if (values.project) {
            if (projects) {
                let project = projects.find(p => p.id === values.project);
                if (project) {
                    let projectFrom = parseISO(project.dateTimeFrom);
                    let projectUntil = parseISO(project.dateTimeUntil);
                    if (from < projectFrom) {
                        errors.dateTimeFrom = ['Der Einsatz kann nicht vor dem Projekt starten (' + dateFormatter(projectFrom,true) + ').']
                    }
                    if (until > projectUntil) {
                        errors.dateTimeUntil = ['Der Einsatz kann nicht nach dem Projekt enden (' + dateFormatter(projectUntil,true) + ').']
                    }
                    }
            }
        }
        if(allocationGroup){
            if (!errors.dateTimeFrom) {
                let groupFrom = parseISO(allocationGroup.dateTimeFrom);
                if (groupFrom && from < groupFrom) {
                    errors.dateTimeFrom = ['Der Einsatz kann nicht vor der Gruppe starten (' + dateFormatter(groupFrom,true) + ').']
                }
            }
            if (!errors.dateTimeUntil) {
                let groupUntil = parseISO(allocationGroup.dateTimeUntil);
                if (groupUntil && until > groupUntil) {
                    errors.dateTimeUntil = ['Der Einsatz kann nicht nach der Gruppe enden (' + dateFormatter(groupUntil,true) + ').']
                }
            }
        }
    }
    return errors
}

