import React, {useCallback} from "react";
import { useForm } from 'react-final-form';

import { FieldGuesser, InputGuesser} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {
    FormDataConsumer,
    Create,
    Edit,
    TextField,
    List,
    Datagrid,
    SimpleForm, TextInput, ReferenceInput, SelectInput, EditButton, DeleteWithConfirmButton, NumberField,
} from 'react-admin'
import RichTextInput from "ra-input-rich-text";
import {MySingleFieldList} from "../Form/Lists";
import {GetPlan} from "../Session/getPlan";
import {MyDateTimeInput, TransformDefaultDateTimeFields} from "../Form/FormInputs";
import {CustomSaveEdit} from "../Form/CustomSaveEdit";
import {GroupJsonExporter} from "../Exporter/GroupJsonExporter";
import {SaveDeleteToolbar} from "../Form/SaveDeleteToolbar";
import {InnerFormHeadline} from "../Form/FormFields";

export const GroupList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    const plan = GetPlan();
    const planFilter = plan ? {'project.plan': plan.id} : null;
    
    return (
        <List {...props} {...addProps} filter={planFilter} hasCreate={true} exporter={GroupJsonExporter}>
            <Datagrid>
                <FieldGuesser label="Name"  source={"name"} />
                <TextField label="Projekt" source="project.name" />
                <FieldGuesser label="Von" source={"dateTimeFrom"} />
                <FieldGuesser label="Bis" source={"dateTimeUntil"} />
                <MySingleFieldList basePath={"/group_budgets"} optionText={record=>{
                    return parseFloat(record.fteAllocation) +' ' +(record.positionType ? record.positionType.name : '');
                }} label="Budgets" source={"groupBudgets"} reference={"group_budgets"} />
                {/*<MyFixedReferenceArrayField label="Budgets" reference="group_budgets" source="groupBudgets">*/}
                {/*    <Showme />*/}
                {/*    /!*<SingleFieldList>*!/*/}
                {/*    /!*    <ChipField source="positionType.name" />*!/*/}
                {/*    /!*</SingleFieldList>*!/*/}
                {/*</MyFixedReferenceArrayField>*/}
                <FieldGuesser label="Bemerkungen" source={"remarks"} />
                <FieldGuesser label="Original-ID" source={"originalGroupID"} />
                <FieldGuesser label="Gültig" source={"valid"} />
                {/*{ admin && <EditButton />}*/}
                {/*{ admin && <DeleteWithConfirmButton />}*/}
                <EditButton label={false} />
                <DeleteWithConfirmButton
                    confirmTitle={'ra.message.delete_title_generic'}
                    confirmContent={'Wirklich diese Gruppe löschen?'} 
                    label={false} 
                />
            </Datagrid>
        </List>
    );
}

const ProjectSelector = ({ formData, ...rest }) => {
    const form = useForm();
    const plan = GetPlan();
    return (
        <ReferenceInput {...rest} filter={{
            plan:plan?plan.originId:null,
            context: ['project-short']
        }} label="Projekt" source="project" reference="projects" perPage={500}>
            <SelectInput optionText="name" onChange={(narf) =>{
                return form.change('dateTimeFrom') && form.change('dateTimeUntil', null)
            }
            } />
        </ReferenceInput>
    );
};

const ScopedFrom = ({ formData, ...rest }) => {
    const plan = GetPlan();
    var options = {}

    if(formData.project && plan){
        let project = plan.projects.find(project => project.id === formData.project || project['@id'] === formData.project)
        options.minDate = new Date(project.dateTimeFrom)
        options.maxDate = new Date(project.dateTimeUntil)
    }
    return (
        <MyDateTimeInput label="Beginn" showTime={true} source={"dateTimeFrom"} options={options} {...rest}/>
    );
};

const ScopedUntil = ({ formData, ...rest }) => {
    const plan = GetPlan();
    var options = {}

    if(formData.project && plan){
        let project = plan.projects.find(project => project.id === formData.project || project['@id'] === formData.project)
        options.minDate = new Date(project.dateTimeFrom)
        options.maxDate = new Date(project.dateTimeUntil)
    }
    return (
        <MyDateTimeInput label="Ende" showTime={true} source={"dateTimeUntil"} options={options} {...rest}/>
    );
};

const validateGroupCreate = (values,projects) => {
    const errors = {};

    ['name', 'project'].forEach(key => {
        if (!values[key]) {
            errors[key] = ['Notwendige Auswahl'];
        }
    });

    ['dateTimeFrom', 'dateTimeUntil'].forEach(key => {
        if (!values[key]) {
            errors[key] = ['Bitte einen Zeitpunkt wählenl'];
        }
    });

    if (values.dateTimeFrom && values.dateTimeUntil) {
        let from = new Date(values.dateTimeFrom);
        let until = new Date(values.dateTimeUntil);
        if (until <= from) {
            errors.dateTimeUntil = ['Der Endzeitpunkt muss hinter dem Startzeitpunkt liegen.']
        } else if (values.project) {
            if (projects) {
                let project = projects.find(p => p.id === values.project);
                if (project) {
                    let projectFrom = new Date(project.dateTimeFrom);
                    let projectUntil = new Date(project.dateTimeUntil);
                    if (from < projectFrom) {
                        errors.dateTimeFrom = ['Die Gruppe kann nicht vor dem Projekt starten.']
                    }
                    if (until > projectUntil) {
                        errors.dateTimeUntil = ['Die Gruppe kann nicht nach dem Projekt enden.']
                    }
                }
            }
        }
    }
    return errors
}

export const GroupSaveTransformer = (values) => {
    values.project = values.project ? ( values.project['@id'] ? values.project['@id'] : values.project) : null;
    delete values.groupBudgets
    delete values.totalCost
    return TransformDefaultDateTimeFields(values);
}

export const GroupCreate = ({initialValues,onCreate,onCancel,...props}) => {

    const fullWidth = !!props.fullWidth;

    // If called inline, replace normal success handling (Redirection)
    var onSuccess = null;
    if(onCancel || onCreate){
        onSuccess = function (record) {
            if(typeof onCancel === 'function') onCancel();
            if(typeof onCreate === 'function') onCreate(record.data,'created');
        } 
    }
    
    return (
        <Create record={initialValues} {...props} resource={"groups"} transform={GroupSaveTransformer} onSuccess={onSuccess}>
            <SimpleForm  validate={validateGroupCreate} variant="outlined" redirect={"list"}>
                <InnerFormHeadline headline={"Neue Gruppe"} fullWidth={fullWidth}/>
                <FormDataConsumer fullWidth={fullWidth}>
                    {formDataProps => (
                        <ProjectSelector {...formDataProps} />
                    )}
                </FormDataConsumer>
                <InputGuesser source={"name"} fullWidth={fullWidth}/>
                <FormDataConsumer fullWidth={fullWidth}>
                    {formDataProps => (
                        <ScopedFrom {...formDataProps} />
                    )}
                </FormDataConsumer>
                <FormDataConsumer fullWidth={fullWidth}>
                    {formDataProps => (
                        <ScopedUntil {...formDataProps} />
                    )}
                </FormDataConsumer>
                {/*<TextInput initialValue="0" label={"Externe Gruppen-ID"} source={"originalGroupId"} />*/}
                <RichTextInput fullWidth={fullWidth} label="Bemerkungen" source="remarks"
                               toolbar={[['bold', 'italic', 'underline']]}/>
                {/*<RichTextInput  source={"remarks"} />*/}
            </SimpleForm>
        </Create>
    );
}

const validateGroupEdit = (values,projects) => {
    return validateGroupCreate(values,projects)
}

export const StandardGroupEdit = (props)=>{
    return (
        <Edit {...props} hasShow={false}>
            <SimpleForm variant={"outlined"}>
                <ReferenceInput label="Projekt" reference={"projects"} source={"project.@id"}>
                    <SelectInput optionText={"name"}/>
                </ReferenceInput>
                <TextInput label="Name" source="name" />
                <MyDateTimeInput label="Von" showTime={true} source="dateTimeFrom" />
                <MyDateTimeInput label="Bis" showTime={true} source="dateTimeUntil" />
                <RichTextInput label={"Bemerkungen"} source="remarks" toolbar={[ ['bold', 'italic', 'underline'] ]}  />
                <NumberField source="originalGroupID" />
            </SimpleForm>
        </Edit>
    )
}

export const GroupEdit = ({updater, toolbar, actions, onCancel, onCreate, onDelete, notify, resource, ...props}) => {
    
    const plan = GetPlan();

    const onFailure = useCallback(error => {
        notify(error.message, 'warning')
    },[notify]);

    const onSuccess = useCallback(record => {
        onCancel();
        onCreate(record.data);
    },[onCreate,onCancel])

    const save = useCallback(p => {
        return updater({
            payload: {
                id: p.id,
                data: GroupSaveTransformer(p)
            }
        }, {
            onSuccess: onSuccess,
            onFailure: onFailure
        })
    },[updater,onSuccess,onFailure])
    
    const fullWidth = !!props.fullWidth;

    return (
        <CustomSaveEdit resource={"groups"} save={save} {...props} >
           <SimpleForm 
               toolbar={
                   <SaveDeleteToolbar 
                   mutationMode={"optimistic"} 
                   onRedirect={onDelete}
                   deleteConfirmContent={"Soll diese Gruppe wirklich gelöscht werden?"}
                 />
               } 
               validate={validateGroupEdit} 
               variant={"outlined"} >
               
                <InnerFormHeadline headline={"Gruppe bearbeiten"} fullWidth={fullWidth} />

                <ReferenceInput fullWidth={fullWidth} filter={{
                    plan:plan.originId,
                    context: ['project-short']
                }} label="Projekt" source="project.@id" reference="projects" perPage={500}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>

               <TextInput fullWidth={fullWidth}  source={"name"}/>

                <MyDateTimeInput fullWidth={fullWidth}  label="Beginn" source={"dateTimeFrom"} showTime={true}/>
                <MyDateTimeInput fullWidth={fullWidth} label="Ende" source={"dateTimeUntil"} showTime={true}/>

                <RichTextInput fullWidth={fullWidth} defaultValue={""} label="Bemerkungen" source="remarks" toolbar={[ ['bold', 'italic', 'underline'] ]} />
                {/*<TextField source={"originalGroupID"}/>*/}

            </SimpleForm>
        </CustomSaveEdit>
    )
}
