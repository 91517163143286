import {Chip} from "@material-ui/core";
import React, {Fragment} from "react";
import {DateField} from "react-admin";
import get from 'lodash/get';

/**
 * Adds a list of custom chip fields
 *
 * @param resource
 * @param overrideBasePath
 * @param basePath
 * @param source
 * @param record
 * @param optionText
 * @returns {null|*}
 * @constructor
 */
export const MySingleFieldList = ({resource, overrideBasePath, basePath, source, record, optionText}) => {

    // var ids = [], data = {};
    // var sources = get(record,source);
    // sources.forEach(dataSrc => {
    //     ids.push(dataSrc['@id']);
    //     data[dataSrc['@id']] = dataSrc;
    // });
    // Folgender Workaround ist buggy. warten bis es mit ReferenceArrayField funzt
    // return <SingleFieldList resource={resource} ids={ids} basePath={basePath} data={data}>
    //          <ChipFieldWithCustomLabel label={optionText} source={"id"} />
    //  </SingleFieldList> 
    if (overrideBasePath) {
        basePath = overrideBasePath;
    }
    if (record.hasOwnProperty(source)) {
        return record[source].map(data => {
            var label = '';
            if (typeof optionText === "function") {
                label = optionText(data);
            } else if (typeof optionText === "string") {
                label = get(data, optionText);
            }

            // Our handleClick does nothing as we wrap the children inside a Link but it is
            // required by ChipField, which uses a Chip from material-ui.
            // The material-ui Chip requires an onClick handler to behave like a clickable element.
            var handleClick = function () {
            };
            return (
                <a href={'/#' + basePath + '/' + encodeURIComponent(data['@id']) + '/edit'}>
                    <Chip onClick={handleClick} style={{margin: 2}} size={"small"} label={label}/>
                </a>
            )
        });
    }
    return null;
}

export const DateTimeField = ({record,source}) => (
    <DateField record={record} options={{
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }} showTime={true} source={source}/>
)

export const DateTimeFromUntil = ({record,fieldFrom,fieldUntil,label})=>(
    <Fragment>
        {label? label+' ':null}
        <DateTimeField record={record} source={fieldFrom} />
        {" - "}
        <DateTimeField record={record} source={fieldUntil} />
    </Fragment>
)

/**
 * Adds a list of custom chip fields with datetime from - until label
 *
 * @param fieldFrom
 * @param fieldUntil
 * @param props
 * @returns {*}
 * @constructor
 */
export const MySingleFieldListWithFromUntil = ({fieldFrom, fieldUntil, ...props}) => {
    fieldFrom = fieldFrom ?? 'dateTimeFrom';
    fieldUntil = fieldUntil ?? 'dateTimeUntil';
    return (
        <MySingleFieldList {...props} optionText={
            record => <DateTimeFromUntil record={record} fieldFrom={fieldFrom} fieldUntil={fieldUntil} />
        }/>
    )
}

/**
 * Needed to enable label property in Forms
 * @type {{addLabel: boolean}}
 */
MySingleFieldList.defaultProps = {
    addLabel: true,
};
MySingleFieldListWithFromUntil.defaultProps = {
    addLabel: true,
};

/**
 * Render children with subrecords of record, specified by source
 * possibly filtered (Simple Replacement of ReferenceArrayField)
 * possibly sorted by ONE flat object property 
 *
 * @param source
 * @param record
 * @param filter
 * @param sortProperty
 * @param sortDir
 * @param children
 * @returns {null|*}
 * @constructor
 */
export const ArrayRelationList = ({source, record, filter, sortProperty, sortDir, children}) => {
    var pathElement = get(record, source);
    if (Array.isArray(pathElement)) {
        pathElement =  pathElement
            .filter(element => {
                var show = true;
                if (typeof filter === 'function') {
                    if (!filter(element)) {
                        show = false;
                    }
                } else if (typeof filter === 'object') {
                    Object.keys(filter).forEach(path => {
                        var val = get(element, path);
                        if (val !== filter[path]) {
                            show = false;
                        }
                    })
                }
                return show;
            });
        
        if(typeof sortProperty === 'string'){
            pathElement.sort((a,b)=>{
                var c = sortDir.toLowerCase() === 'asc' ? 1 : -1;
                if(a.hasOwnProperty(sortProperty)&&b.hasOwnProperty(sortProperty)){
                    if(a[sortProperty] > b[sortProperty]) return c;
                }
                return -c;
            })
        }
    
        return pathElement.map(element => {
            return React.Children.map(children, function (child) {
                return React.cloneElement(child, {record: element})
            });
        });
    }
    return null;
}
