import {useEffect} from 'react';
import {useDataProvider} from "react-admin";
import {useSelector} from 'react-redux';
import get from 'lodash/get';

export function useSessionViewSync() {

    const dataProvider = useDataProvider();
    // need specific selectors or useEffect() won't detect the object change.
    const V = useSelector(state => get(state, 'session.view'));
    const p = useSelector(state => get(state, 'session.PlanID'));
    const w = useSelector(state => get(state, 'session.view.resourceAreaWidth'));
    const rf = useSelector(state => get(state, 'session.view.slotDurations.filterRange'));
    const r1d = useSelector(state => get(state, 'session.view.slotDurations.resourceTimeline1d'));
    const r5d = useSelector(state => get(state, 'session.view.slotDurations.resourceTimeline5d'));
    const r1m = useSelector(state => get(state, 'session.view.slotDurations.resourceTimeline1m'));
    const selV = useSelector(state => get(state, 'session.view.selectedView'));
    const sCC = useSelector(state => get(state, 'session.view.showCostColumns'));
    const sidebarOpen = useSelector(state => get(state, 'admin.ui.sidebarOpen'));
    const favs = useSelector(state => get(state, 'session.favs'));

    // list params / filter settings etc. 
    const employeeAllocationListParams = useSelector(state => get(state, 'session.listParamsEmployeeAllocations'));

    useEffect(() => {
        let uid = localStorage.getItem('uid');
        if (uid) {
            let remoteState = {
                session: {
                    PlanID: p,
                    view: V,
                    listParamsEmployeeAllocations: employeeAllocationListParams,
                    favs: favs
                },
                admin: {
                    ui: {
                        sidebarOpen: sidebarOpen
                    }
                }
            }
            dataProvider.update('users', {
                id: '/api/users/' + uid,
                data: {
                    state: remoteState
                }
            }).then(data => {
            }).catch(error => {
                console.log("sess error", error)
            });
        }
    }, [
        dataProvider,
        V, p, w, rf, r1d, r5d, r1m, selV, sCC,
        favs,
        employeeAllocationListParams,
        sidebarOpen,
    ])

    return null;
}