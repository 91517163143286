import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {ChipField, ReferenceArrayField, SingleFieldList, TextField} from "react-admin";
import {isAdmin} from "../DataProvider/Authorization";

export const LocationTypeList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser filters={false} {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <TextField source={"name"}/>
            <ReferenceArrayField sortBy={"location.name"} link={false} reference={"locations"} source={"locations"}>
                <SingleFieldList linkType={false}>
                    <ChipField source={"name"} />
                </SingleFieldList>
            </ReferenceArrayField>
        </ListGuesser>
    );
}