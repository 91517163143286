import React, {useCallback, useRef, useEffect, useState, cloneElement} from 'react';
import {useDataProvider} from 'react-admin';
import {
    Avatar, 
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Drawer
} from '@material-ui/core';
import {AutocompleteArrayInput} from 'react-admin';
import PersonIcon from '@material-ui/core/Avatar';
import {
    TextField,
    DateField,
    Filter,
    useUpdate,
    useCreate,
    ReferenceArrayInput,
    TopToolbar,
    sanitizeListRestProps, CreateButton, ExportButton
} from "react-admin";
import {UsersList} from "../Views/UsersList";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import bootstrapPlugin from "@fullcalendar/bootstrap";

import ListWithCreateDrawer from "../Form/ListWithCreateDrawer";
import {DateOverlapsRangeFilter, GetDateOverlapDates} from "./DateOverlapsRangeFilter"
import './main.scss';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import {useSelector,useDispatch} from 'react-redux';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import GetPlan from "../Session/getPlan";
import ReactDOM from 'react-dom';
import {Baseurl} from "../Entrypoint";
import {DiscreteSlider} from "../Form/Slider";
import {SafeStringify, useTraceUpdate} from "../Tools/Tools";
import useWindowDimensions from "../Hook/useWindowDimensions";

import Tippy from '@tippyjs/react';
import {followCursor,roundArrow} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import {Loading} from "ra-ui-materialui";

const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: 120,
        position: 'fixed',
        background: "transparent",
        border: 0
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        // minHeight: "90vh",
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    small: {
        height:15,
        width:15
    },
    badgeAssistenz: {
        backgroundColor: "darkred",
        color:"lightgray",
    },
    badgeBuehne: {
        backgroundColor: "pink",
        color:"darkblue",
    },
    badgeTon: {
        backgroundColor: "blue",
        color:"white",
    },
    badgeVideo: {
        backgroundColor: "orange",
        color:"darkbrown",
    },
    badgeLicht: {
        backgroundColor: "yellow",
        color:"darkbrown",
    },
    badgeSpielortleiter: {
        backgroundColor: "lawngreen",
        color: "darkgreen"
    },
    badgeUnbekannt: {
        backgroundColor: "gray",
        color: "black"
    }
}));

const CommentGrid = ({ids, data, basePath}) => (
    <div style={{margin: '1em'}}>
        {ids.map(id =>
            <Card key={id} style={cardStyle}>
                <CardHeader
                    title={<TextField record={data[id]} source="employee.fullName"/>}
                    subheader={<DateField record={data[id]} source="dateTimeFrom"/>}
                    avatar={<Avatar icon={<PersonIcon/>}/>}
                />
                <CardContent>
                    <TextField record={data[id]} source="allocationGroup.name"/>
                </CardContent>
            </Card>
        )}
    </div>
);

const PropsAreEqualByJson = (prev, next) => {
    // data dictionary needs to be compared one by one by certain props
    const {data: prevData, ...prevProps} = prev;
    const {data: nextData, ...nextProps} = next;
    const compareDataPaths = [
        'allocationGroup.@id',
        'employee.@id',
        'project.@id',
        'dateTimeFrom',
        'dateTimeUntil',
        'valid',
        'isAttentionNeeded'
    ];
    let dataChanged = false;
    let propsChanged = false;
    if (prevData && nextData) {
        let keys = Object.keys(prevData);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let left = prevData[key];
            let right = nextData[key];
            let changedPaths = compareDataPaths.filter(path=>{
                return SafeStringify(get(left,path)) !== SafeStringify(get(right,path))
            })
            if (changedPaths.length>0) {
                dataChanged = true;
                break;
            }
        }
        if (!dataChanged) {
            propsChanged = (SafeStringify(prevProps) !== SafeStringify(nextProps));
        }
    }
    return !propsChanged && !dataChanged;
}

const CalendarFilters = ({plan, ...props}) => {

    var defaultPlanFilter = null;
    var employeePlanFilter = null;
    var projectPlanFilter = null;

    if (plan) {
        defaultPlanFilter = {'project.plan': plan.id}
        employeePlanFilter = {plans: plan.id};
        projectPlanFilter = {plan: plan.id};
    }

    return (
        <Filter {...props} variant="outlined" style={{alignItems:'flex-start',minHeight:0,alignSelf:'flex-end',margin:0}}>

            <ReferenceArrayInput
                label="Funktionsgruppe"
                filter={projectPlanFilter}
                source={"position.positionType"}
                reference="position_types">
                <AutocompleteArrayInput variant="outlined" optionText="name" allowEmpty={false} />
            </ReferenceArrayInput>

            <ReferenceArrayInput
                label="Gruppe"
                filter={defaultPlanFilter}
                filterToQuery={searchText => ({ name: searchText })}
                source="allocationGroup"
                reference="groups">
                <AutocompleteArrayInput variant="outlined" optionText="name" allowEmpty={false} />
            </ReferenceArrayInput>

            <ReferenceArrayInput
                label="Mitarbeiter"
                filter={employeePlanFilter}
                filterToQuery={searchText => ({ fullName: searchText })}
                source="employee.id"
                reference="employees">
                <AutocompleteArrayInput variant="outlined" optionText="fullName" allowEmpty={false} />
            </ReferenceArrayInput>

            <ReferenceArrayInput
                label="Projekt"
                filterToQuery={searchText => ({ name: searchText })}
                sort={{ field: 'name', order: 'ASC' }}
                filter={projectPlanFilter}
                source={"project.id"}
                reference="projects">
                <AutocompleteArrayInput variant="outlined" optionText="name" allowEmpty={false} />
            </ReferenceArrayInput>
            {/*<PlanPhaseSpanFilter filter={defaultPlanFilter} label={"Planphase"} source={"plan[phasespan]"} {...props} />*/}

            {/*<ProjectSpanFilter label={"Projekt"} source={"project[timespan]"} {...props} />*/}
            <ReferenceArrayInput
                label="Standort"
                filter={defaultPlanFilter}
                filterToQuery={searchText => ({ name: searchText })}
                source="location"
                reference="locations">
                <AutocompleteArrayInput variant="outlined" optionText="name" allowEmpty={false} />
            </ReferenceArrayInput>

            <DateOverlapsRangeFilter
                label="Zeitraum"
                source="dateTimeFrom[date_overlaps_range]" {...props}
            />
        </Filter>
    )
}
//
// const getDurationFromUTCDateTime(DateTimeUTC)=>{
//     return {
//         year: DateTimeUTC.getUTCFullYear(),
//         month: DateTimeUTC.getUTCMonth(),
//         day: DateTimeUTC.getUTCDay(),
//         hours: DateTimeUTC.getUTCHours(),
//         minutes: DateTimeUTC.getUTCHours(),
//     } 
//     DateTimeUTC.setUTCMonth(DateTimeUTC.getUTCMonth() + (DurationObject.months ?? 0));
//     DateTimeUTC.setUTCHours(DateTimeUTC.getUTCHours() + ((DurationObject.days ?? 0) * 24));
//     DateTimeUTC.setUTCSeconds(DateTimeUTC.getUTCSeconds() + (DurationObject.seconds ?? 0));
//     DateTimeUTC.setUTCMilliseconds(DateTimeUTC.getUTCMilliseconds() + (DurationObject.milliseconds ?? 0));
//     return DateTimeUTC;    
// }
const addDurationToUTCDateTime = ((DateTimeUTC, DurationObject) => {
    //  However, when the API emits a Duration object, it is always a plain 
    //  JavaScript object with just a few keys:
    // years (integer)
    // months (integer)
    // days (integer)
    // milliseconds (integer)
    DateTimeUTC.setUTCFullYear(DateTimeUTC.getUTCFullYear() + (DurationObject.years ?? 0));
    DateTimeUTC.setUTCMonth(DateTimeUTC.getUTCMonth() + (DurationObject.months ?? 0));
    DateTimeUTC.setUTCHours(DateTimeUTC.getUTCHours() + ((DurationObject.days ?? 0) * 24));
    DateTimeUTC.setUTCSeconds(DateTimeUTC.getUTCSeconds() + (DurationObject.seconds ?? 0));
    DateTimeUTC.setUTCMilliseconds(DateTimeUTC.getUTCMilliseconds() + (DurationObject.milliseconds ?? 0));
    return DateTimeUTC;
})

const defaultHeader = {
    // left: "toggleCollapse toggleCostColumns prev,today,next",
    left: "toggleCostColumns prev,today,next",
    center: "title",
    right: "toggleSlider,resourceTimeline1d,resourceTimeline5d,resourceTimeline1m,toggleFilterPanel"
}


const CalendarDataRenderer = React.memo(({ids, data, plan, refreshInterval, onFilter, previousFilter, drawerMode, page, setPage,
                                          total, basePath, displayedFilters, filterValues, setFilters, hideFilter, loaded, loading, perPage, setPerPage,
                                          currentSort, title, toggleAside, asideOpen, onClickEdit, onClickCreate, toggleToolbar, hideToolbar,
                                          setStickyFilters,stickyFilters,theme, ...props
                                         }) => {
    const dataProvider = useDataProvider();
    const SessionView = useSelector(state=>get(state,'session.view'));
    const SessionSelectedView = useSelector(state=>get(state,'session.view.selectedView'));
    const SessionViewSlotDurations = useSelector(state=>get(state,'session.view.slotDurations'));
    const SessionViewEmpFilterReset = useSelector(state=>get(state,'session.view.empFilterReset'));
    const Dispatch = useDispatch();

    // Wenn props der Komponente geändert werden, wird neu gerendert, und 
    // der state ändert sich wieder auf ausgangszustand.
    const CalendarRef = useRef(null);
    
    // Watch Calendar Dimensions with window resize and toolbar toggle
    const CalendarContainerRef = useRef(null);
    const [listContainerOffset,setListContainerOffset] = useState(56);
    const { height, width } = useWindowDimensions();
    
    // useTraceUpdate({
    //     ids, data, plan, refreshInterval, needsRefresh, onRefresh, drawerMode, page, setPage,
    //     total, basePath, displayedFilters, filterValues, setFilters, loaded, loading,
    //     title, ...props
    // }, "CalendarDataRenderer");
    const classes = useStyles();
    const [showCostColumns, setShowCostColumns] = useState(get(SessionView,'showCostColumns'));
    const [expandAll, setExpandAll] = useState(get(SessionView,'expandAll'));
    const [strFrom, strUntil] = GetDateOverlapDates(filterValues);

    var defaultViews = {
        'resourceTimeline1d': {
            type: "resourceTimeline",//'resourceDayGridDay'
            duration: {days: 1},
            dayCount: 1,
            buttonText: '1 Tag',
            slotDuration: '00:30',
            scrollTime: '07:00:00',
            slotDurationSliderSteps: [
                {'4h': '04:00'},
                {'2h': '02:00'},
                {'1h': '01:00'},
                {'30m': '00:30'},
                {'15m': '00:15'},
            ],
            slotLabelFormat: [
                { weekday: 'short',  day: 'numeric', month: 'short' }, // top level of text
                (slot) => { return slot.date.marker.getUTCHours()  } // lower level of text
            ],
        },
        'resourceTimeline5d': {
            type: 'resourceTimeline',
            duration: {days: 5},
            dayCount: 5,
            buttonText: '5 Tage',
            slotDuration: '04:00',
            // slotLabelFormat: (slot) => {
            //     return slot.date.marker.getUTCHours();
            // },
            slotLabelFormat: [
                {weekday: 'short',  day: 'numeric', month: 'short' }, // top level of text
                (slot) => { return slot.date.marker.getUTCHours()  } // lower level of text
            ],
            // slotLabelInterval: {
            //     'hours': 3
            // },
            slotDurationSliderSteps: [
                {'12h': '12:00'},
                {'8h': '08:00'},
                {'4h': '04:00'},
                {'2h': '02:00'},
                {'1h': '01:00'},
                {'30m': '00:30'},
            ]
        },
        'resourceTimeline1m': {
            type: 'resourceTimeline',
            buttonText: '1 Monat',
            duration: {months: 1},
            slotDuration: '24:00',
            slotDurationSliderSteps: [
                {'24h': '24:00'},
                {'12h': '12:00'},
                {'8h': '08:00'},
                {'4h': '04:00'},
                {'2h': '02:00'},
                {'1h': '01:00'},
            ],
            slotLabelFormat: [
                {  day: 'numeric' }, // top level of text
                (slot) => { return slot.date.marker.getUTCHours()  } // lower level of text
            ],
            // slotLabelFormat: getMonthSlotLabelFormat,
            // slotLabelFormat: [
            //     { month: 'long', year: 'numeric' }, // top level of text
            //     (slot) => { return slot.date.marker.getUTCDate() + ' ' + ['So','Mo','Di','Mi','Do','Fr','Sa'][(slot.date.marker.getUTCDay())] ;  } // lower level of text
            // ],
        }
    };
    var buttonText = {
        today: '⯀',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        list: 'Liste',
        prev: '⯇',
        next: '⯈'
    }

    var defaultView = SessionSelectedView && defaultViews.hasOwnProperty(SessionSelectedView)
        ? SessionSelectedView : 'resourceTimeline1m';

    if (strFrom && strUntil) {
        defaultViews.filterRange = {
            type: 'resourceTimeline',
            buttonText: 'Filter',
            visibleRange: {
                start: strFrom,
                end: addDurationToUTCDateTime(new Date(strUntil), {days: 1})
            },
            slotDuration: '24:00',
            slotDurationSliderSteps: [
                {'24h': '24:00'},
                {'12h': '12:00'},
                {'8h': '08:00'},
                {'4h': '04:00'},
                {'2h': '02:00'},
                {'1h': '01:00'},
                {'30m': '00:30'},
            ]
        }
        if(!SessionSelectedView || SessionSelectedView === 'filterRange'){
            defaultView = 'filterRange';
        }
        defaultHeader.right = "toggleSlider,resourceTimeline1d,resourceTimeline5d,resourceTimeline1m,filterRange,toggleFilterPanel";
    } else {
        defaultHeader.right = "toggleSlider,resourceTimeline1d,resourceTimeline5d,resourceTimeline1m,toggleFilterPanel";
    }

    const defaultViewsStr = JSON.stringify(defaultViews);
    const defaultHeaderStr = JSON.stringify(defaultHeader);

    const groupedView = 0;

    const [activeHeader, setActiveHeader] = useState(defaultHeader);
    const [selectedView, setSelectedView] = useState(defaultView);
    const [isResizingResourceArea, setIsResizingResourceArea] = useState(false);

    const [updateAllocationEvent, updateAllocationEventResult] = useUpdate('employee_allocations');
    const [updateAbsence, updateAbsenceResult] = useUpdate('absences');
    const [updateGroup] = useUpdate('groups');
    const [createAllocationEvent, createAllocationEventResult] = useCreate('employee_allocations');
    
    if(CalendarRef.current){
        // Don't try to access project view when removed or no project selected
        var Cal = CalendarRef.current.getApi();
        if (Object.keys(defaultViews).indexOf(Cal.view.type) === -1){
            Cal.changeView('resourceTimeline1m');
        }
    }
    
    const createEventFromAllocation = (allocation) => {
        var event = {
            data: allocation,
            start: allocation.dateTimeFrom,
            end: allocation.dateTimeUntil,
            valid: allocation.valid
        }
        
        if (allocation.project !== null) {
            event.projectId = allocation.project['@id'];
            event.projectTitle = allocation.project.name;
            event.projectLocation = allocation.project.location.name;
            event.projectStart = allocation.project.dateTimeFrom;
            event.projectEnd = allocation.project.dateTimeUntil;
        }

        if(allocation.allocationType === 'absence' && allocation.hasOwnProperty('absence')) {
            event.resourceId = allocation.employee['@id'] + ':absences';
            event.id = allocation['@id'];
            event.title = allocation.absence.type.name;
            event.allocationGroupId = null;
            event.extType = 'absence';
            event.extTitle = allocation.absence.title;
            event.tooltip = allocation.absence.title + (allocation.remarks ?? '')
            event.extId = allocation.absence['@id'];
            event.color = allocation.absence.importable ? '#FF5A73' : '#FF905A'
            event.textColor = 'white';
            event.editable = true;
            event.displayEventTime = true;
            event.classNames = ['event'];
            event.constraint = {
                // Nur auf dieser Resource verschieben können 
                resourceIds: [event.resourceId]
            };
       } else if (allocation.event) {
            // Place time blocks in project resource
            event.id = allocation.event['@id'];
            event.title = allocation.event.name;
            event.resourceId = allocation.project['@id'];
            event.allocationGroupId = null;
            event.extType = 'event';
            event.extTitle = allocation.event.name;
            event.extId = allocation.event['@id'];
            event.color = 'darkkhaki';//cadetblue';
            event.textColor = 'azure';
            event.editable = false;
            event.displayEventTime = false;
            event.classNames = ['event'];
            event.tooltip = allocation.event.name;
        } else if (allocation.employee) {
            // Place employees in their own resources
            event.id = allocation['@id'];
            event.title = groupedView ? allocation.employee.fullName : allocation.allocationGroup['name'];
            // event.title = groupedView ? allocation.employee.fullName : allocation.name;
            event.tooltip = allocation.name;
            event.resourceId = groupedView ? allocation.allocationGroup['@id'] + ':' + allocation.employee['@id'] : allocation.project['@id'] + ':' + allocation.employee['@id'];
            event.absenceResourceId = allocation.employee['@id'] + ':absences';
            event.positionId = allocation.position ? (allocation.position['@id'] ? allocation.position['@id'] : allocation.position) : null;
            event.allocationGroupId = allocation.allocationGroup['@id'];
            event.extType = 'employee';
            event.extTitle = allocation.employee.fullName;
            event.extId = allocation.employee['@id'];
            event.hourlyRate = allocation.employee.hourlyRate ?? 0;
            event.avatarUrl = allocation.employee.avatarUrl;
            event.color = '#EFEADB';//'lightblue';
            event.textColor = '#222';
            event.borderColor = true ? "rgb(215, 203, 166)" : "#FF905A"
            event.classNames = ['employee'];
            // event.constraint = 'any';
            if(!allocation.valid){
                event.classNames.push('invalid');
            }
            if(allocation.isAttentionNeeded){
                event.color = '#FF681F';//'orangered';
                event.textColor = 'white';
                event.title = '! '+event.title;
                event.classNames.push('attention');
            }
        } else {
            if(allocation.status === 'group'){
                // Group dummy in order to always show the group even when it's empty
                event.id = allocation['@id'];
                event.allocationGroupId = allocation.allocationGroup['@id'];
            }
        }
        return event;
    }

    // fetch events callback from fullcalendar.    
    const fetchEvents = useCallback((fetchInfo, successCallback, failureCallback) => {

        var projectIds = [];
        var groupIds = [];
        var employeeResourceIds= [];
        var events = [];
        
        // Gray weekends
        events.push({
            daysOfWeek: [0, 6], // Sun/Sat
            display: "background",
            color: "#dae6dd",
            overLap: false,
            allDay: true
        })

        var absences =[]
        
        ids.forEach(allocationId => {

            var allocation = data[allocationId];
            var event = createEventFromAllocation(allocation);

            // Add calendar items (from employee, absence or event)
            events.push(event);

            // Save absences for later 
            if (event.data.allocationType === 'absence') {
                absences.push(event)
            }

            // Add project timespans
            if (event.projectId) {
                if (projectIds.indexOf(event.projectId) === -1) {
                    projectIds.push(event.projectId);
                    events.push({
                        id: event.projectId,
                        start: event.projectStart,
                        end: event.projectEnd,
                        title: event.projectTitle,
                        extType: 'project',
                        extTitle: event.projectTitle,
                        extId: event.projectId,
                        resourceId: event.projectId,
                        editable: false,
                        rendering: 'background',
                        backgroundColor: '#00BDB6',//'#007F77'//'green'
                        borderColor: 'transparent',
                        classNames: 'project',
                        projectId: allocation.project['@id'],
                        projectTitle: allocation.project.name,
                        projectLocation: allocation.project.location.name,
                        projectStart: allocation.project.dateTimeFrom,
                        projectEnd: allocation.project.dateTimeUntil,
                        tooltip: getLocalTimeOfDateTime(new Date(event.projectStart))
                            + '-' + getLocalTimeOfDateTime(new Date(event.projectEnd))
                            + ' ' + event.projectTitle + ' (' + event.projectLocation + ')'
                    })
                }
            }

            // Add missing groups 
            if (event.allocationGroupId) {
                if (groupIds.indexOf(event.allocationGroupId) === -1) {
                    groupIds.push(event.allocationGroupId);
                    events.push({
                        durationEditable: true,
                        editable: true,
                        resourceEditable: true,
                        id: event.allocationGroupId,
                        data: allocation.allocationGroup,
                        resourceId: event.allocationGroupId,
                        title: allocation.allocationGroup.name,
                        start: allocation.allocationGroup.dateTimeFrom,
                        end: allocation.allocationGroup.dateTimeUntil,
                        valid: false, // @todo 
                        extId: event.allocationGroupId,
                        extType: 'group',
                        extTitle: allocation.allocationGroup.name,
                        // groupId: "any", // Used as constraint
                        tooltip:  getLocalTimeOfDateTime(new Date(allocation.allocationGroup.dateTimeFrom))
                             + '-' +getLocalTimeOfDateTime(new Date(allocation.allocationGroup.dateTimeUntil))
                             + ' ' + allocation.allocationGroup.fullName,
                        classNames: 'group'
                    })
                }
            }
            
            // Add presences  which have to be embedded
            // in allocation.employee.presences data 
            // Once for each resource of this employee (could be in several groups)
            if(event.extType === 'employee'){
                if(employeeResourceIds.indexOf(event.resourceId)===-1){
                    employeeResourceIds.push(event.resourceId);
                    if(event.data.employee.hasOwnProperty('presences')){
                        event.data.employee.presences.forEach(presence=>{
                            if(presence.plan.id === plan.originId){
                                events.push({
                                    resourceId: event.resourceId,
                                    extType: 'presence',
                                    title: '',//presence.title,
                                    start: presence.dateTimeFrom,
                                    end: presence.dateTimeUntil,
                                    display: 'background',
                                    color: '#BBFFF9'
                                })
                            }
                        });
                    }
                }
            }
        })
        
        // Background display of all absences in all Employee-resources 
        absences.forEach(absence => {
            employeeResourceIds.forEach(resourceId => {
                let employeeId = resourceId.split(':').pop()
                if (employeeId === absence.data.employee['@id']) {
                    events.push({
                        resourceId: resourceId,
                        extType: 'absence',
                        title: '',
                        start: absence.start,
                        end: absence.end,
                        display: 'background',
                        color: absence.data.absence.importable ? '#FF5A73' : '#FF905A' 
                    })
                }
            })
        })
        successCallback(events);
    }, [data, ids, plan.originId])

    // fetch resources callback from fullcalendar.    
    const fetchResources = useCallback((fetchInfo, successCallback, failureCallback) => {
        
        if (!loading && loaded) {
            var resources = [];
            // date overlap filter neu setzen 
            if (fetchInfo.startStr && fetchInfo.endStr) {
                // var filterStr = fetchInfo.startStr + ',' + fetchInfo.endStr
                if (!filterValues['dateTimeFrom[date_overlaps_range]']) {
                    // filterValues['dateTimeFrom[date_overlaps_range]'] = filterStr;
                    // setFilters(filterValues);
                }
            }
            
            // Resourcen für Abwesenheiten 
            resources.push({
                id: 'absenceGroup',
                extType: 'absenceGroup',
                title: "Abwesenheiten",
                // immer oben einsortieren
                projectStart: 0
            })

            // 'data' ist das GANZE dictionary, man muss darum 'ids' loopen. 
            ids.forEach(allocationId => {
                let allocation = data[allocationId];
                let event = createEventFromAllocation(allocation);

                // build structure using FLAT array https://fullcalendar.io/docs/resource-parsing
                // using filter() because find() is not supported in all browsers
                if (event.projectId) {
                    let projectResource = resources.filter(o => {
                        return o.id === event.projectId
                    })[0];
                    if (!projectResource) {
                        resources.push({
                            id: event.projectId,
                            extType: 'project',
                            title: event.projectTitle,
                            extId: event.projectId,
                            projectLocation: event.projectLocation,
                            projectStart: event.projectStart,
                            projectEnd: event.projectEnd,
                        });
                    }
                }

                if (event.allocationGroupId) {
                    let groupResource = resources.filter(o => {
                        return o.id === event.allocationGroupId
                    })[0];
                    if (!groupResource) {
                        resources.push({
                            id: event.allocationGroupId,
                            title: allocation.allocationGroup.name,
                            parentId: event.projectId,
                            projectId: event.projectId,
                            locationId: allocation.location['@id'],
                            extId: event.allocationGroupId,
                            extDateTimeFrom: allocation.allocationGroup.dateTimeFrom,
                            extType: 'group'
                        });
                    }
                }

                if (allocation.employee) {
                    
                    let employeeResource = resources.filter(o => {
                        return o.id === event.resourceId
                    })[0];
                    
                    if (!employeeResource) {
                        
                        if('absence' === allocation.allocationType) {
                            // Add absence resource
                            resources.push({
                                id: event.resourceId,
                                parentId: 'absenceGroup',
                                extType: 'absence',
                                extId: allocation.employee['@id'],
                                title: allocation.employee.fullName,
                                avatarUrl: allocation.employee.avatarUrl,
                                defaultPositionStr: allocation.employee.defaultPositionType.name ?? 'unbekannt',
                                defaultAbsenceType: allocation.absence.type['@id']
                            })
                        } else {
                            // Add employee resource
                            resources.push({
                                id: event.resourceId,
                                title: event.extTitle,
                                parentId: groupedView ? event.allocationGroupId : event.projectId,
                                projectId: event.projectId,
                                locationId: allocation.location['@id'],
                                positionId: event.positionId,
                                allocationGroup: event.allocationGroupId,
                                extId: event.extId,
                                extType: 'employee',
                                hourlyRate: event.hourlyRate,
                                avatarUrl: event.avatarUrl,
                                defaultPositionStr: event.data.employee.defaultPositionType.name ?? 'unbekannt'
                            })    
                        }
                    }
                }
            })
            successCallback(resources)
            // successCallback(resources.sort(function (a, b) {
            //     var titleA = a.title.toLowerCase(), titleB = b.title.toLowerCase();
            //     if (a.extType === 'project') {
            //         if (b.extType === 'project') {
            //             // Projekte nach Startdatum
            //             if (a.projectStart < b.projectStart) return -1;
            //             if (a.projectStart > b.projectStart) return 1;
            //             return 0;
            //         } else return -1;
            //     } else if (a.extType === 'group') {
            //         if (b.extType === 'group') {
            //             // Gruppen nach Startdatum
            //             if (a.extDateTimeFrom < b.extDateTimeFrom) return -1;
            //             if (a.extDateTimeFrom > b.extDateTimeFrom) return 1;
            //             return 0;
            //         } else if (b.extType === 'employee') {
            //             return -1;
            //         }
            //     } else if (a.extType === 'employee') {
            //         if (b.extType === 'employee') {
            //             // Employees nach Alphabet
            //             if (titleA < titleB) return -1;
            //             if (titleA > titleB) return 1;
            //         }
            //         return 0;
            //     }
            // }));
        }
    }, [data, filterValues, ids, loaded, loading])

    const dateClickCallback = useCallback((info) => {
        if (typeof onClickCreate === 'function') {
            if (info.resource.extendedProps.hasOwnProperty("extType")) {

                var from = info.date;
                var until = new Date(info.date.getTime());
                until.setUTCHours(until.getUTCHours() + 1);

                if (info.resource.extendedProps.extType === 'employee') {
                    // Neuer Einsatz, Mitarbeiter bekannt
                    var allocationGroup = info.resource.extendedProps.allocationGroup;
                    if (!groupedView) {
                        let projectResource = CalendarRef.current.getApi().getResourceById(info.resource._resource.parentId);
                        let groupChildren = projectResource.getChildren().filter(childResource => {
                            return childResource.extendedProps.extType === 'group'
                        });
                        if (groupChildren && groupChildren.length > 0) {
                            allocationGroup = groupChildren[0].extendedProps.extId;
                        }
                    }
                    let groupEvent = CalendarRef.current.getApi().getEventById(allocationGroup);
                    onClickCreate({
                        objectType: 'allocation',
                        initialValues: {
                            allocationGroup: allocationGroup,
                            project: info.resource.extendedProps.projectId,
                            location: info.resource.extendedProps.locationId,
                            employee: info.resource.extendedProps.extId,
                            position: info.resource.extendedProps.positionId,
                            // dateTimeFrom: from,
                            // dateTimeUntil: until,
                            dateTimeFrom: groupEvent.start ?? from,
                            dateTimeUntil: groupEvent.end ?? until,
                        }
                    })
                } else  if (info.resource.extendedProps.extType === 'project') {
                    // Neue Gruppe
                    // Kollidiert mit "Projekt detail" drawer - diesen in tooltip?
                    // onClickCreate({
                    //     objectType: 'group',
                    //     initialValues: {
                    //         project: info.resource.extendedProps.extId,
                    //         dateTimeFrom: from,
                    //         dateTimeUntil: until,
                    //     }
                    // })
                } else  if (info.resource.extendedProps.extType === 'group') {
                    // Neuer Einsatz, Mitarbeiter unbekannt
                    onClickCreate({
                        objectType: 'allocation',
                        initialValues: {
                            allocationGroup: info.resource.extendedProps.extId,
                            project: info.resource.extendedProps.projectId,
                            location: info.resource.extendedProps.locationId,
                            dateTimeFrom: from,
                            dateTimeUntil: until,
                        }
                    })
                } else  if (info.resource.extendedProps.extType === 'absenceGroup') {
                    // Abwesenheit (Standard 1 Tag) 
                    until.setUTCHours(until.getUTCHours() + 23);
                    onClickCreate({
                        objectType: 'absence',
                        initialValues: {
                            dateTimeFrom: from,
                            dateTimeUntil: until,
                        }
                    })
                } else  if (info.resource.extendedProps.extType === 'absence') {
                    // Abwesenheit (Standard 1 Tag) 
                    until.setUTCHours(until.getUTCHours() + 23);
                    onClickCreate({
                        objectType: 'absence',
                        initialValues: {
                            dateTimeFrom: from,
                            dateTimeUntil: until,
                            type: info.resource.extendedProps.defaultAbsenceType,
                            employee: info.resource.extendedProps.extId,
                        }
                    })
                }
            }
        }
    }, [onClickCreate])

    const resizeOrMoveGroup = useCallback((before, after) => {
        let data = {
            '@id': after.extendedProps.data['@id'],
            dateTimeFrom: after.start.toUTCString(),
            dateTimeUntil: after.end.toUTCString()
        }
        updateGroup({payload: {id: after.extendedProps.data['@id'], data}}, {
            onSuccess: ({data: newRecord}) => {
                // Re-Fetch all allocations of this group  
                dataProvider.getManyReference('employee_allocations', {
                    target: 'allocationGroup',
                    id: newRecord['@id'],
                    // Muss unbedingt allocationType berücksichtigen!
                    filter: {allocationType: ['employee','group_dummy']},
                    pagination: {page: 0, perPage: 0},
                    sort: {},
                })
            }
        });
    }, [updateGroup, dataProvider])

    const eventAllowCallback = useCallback((dropInfo, draggedEvent) => {
        var eventExt = draggedEvent.extendedProps
        var resourceExt = dropInfo.resource.extendedProps;
        if (eventExt.extType === 'employee') {
            if (resourceExt.extType === 'group') {
                if (eventExt.hasOwnProperty('data')) {
                    // Allow to move employees into NEW groups only
                    return (eventExt.data.allocationGroup !== resourceExt.extId);
                }
                return true;
            }
            if (resourceExt.extType === 'employee') {
                if (dropInfo.start) {
                    // Allow to move existing events within its group boundaries
                    // event.constraint with groupId won't work (would need drop into group resource)
                    let groupEvent = CalendarRef.current.getApi().getEventById(eventExt.allocationGroupId);
                    if(groupEvent){
                        return (dropInfo.start >= groupEvent.start && dropInfo.end <= groupEvent.end); 
                    }
                }
            }
        } else if (eventExt.extType === 'group') {
            // Allow to move/resize on timeline only
            return eventExt.extId === resourceExt.extId || resourceExt.extType === 'none'
        } else if (eventExt.extType === 'block') {
            // Allow to move time block within its group 
            return eventExt.allocationGroupId === resourceExt.extId;
        } else if (eventExt.extType === 'absence') {
            // Allow to move absence: Always (within its RESOURCE CONSTRAINTS config)
            return true; 
        }
        return false;
    }, [CalendarRef])

    const eventResizeCallback = useCallback((info) => {
        let data = {
            'dateTimeFrom': info.event.start,
            'dateTimeUntil': info.event.end,
        }
        if ('group' === info.event.extendedProps.extType) {
            resizeOrMoveGroup(info.oldEvent, info.event, info.startDelta, info.endDelta, info.revert)
        } else {
            updateAllocationEvent({payload: {id: info.event.id, data}}, {
                onSuccess: ({data: newRecord})=>{
                }
            })
        }
    }, [updateAllocationEvent, resizeOrMoveGroup])

    const eventDropCallback = useCallback((dropInfo) => {
        if ('group' === dropInfo.event.extendedProps.extType) {
            // Move group  
            resizeOrMoveGroup(dropInfo.oldEvent, dropInfo.event, dropInfo.delta, dropInfo.delta, dropInfo.revert);
        } else {
            // Move employee to employee resource (same or other)
            var newAllocationData = {
                id: dropInfo.oldEvent.extendedProps.data.id,
                dateTimeFrom: dropInfo.event.start.toUTCString(),
                dateTimeUntil: dropInfo.event.end.toUTCString(),
            }
            if (dropInfo.newResource) {
                if (dropInfo.oldResource.extendedProps.extType === 'employee') {
                    // Move allocation to other resource (NEW group or different employee)
                    if (dropInfo.newResource.extendedProps.extType === 'group') {
                        newAllocationData.allocationGroup = dropInfo.newResource.extendedProps.extId
                        newAllocationData.locationId = dropInfo.newResource.extendedProps.locationId
                    } else if (dropInfo.newResource.extendedProps.extType === 'employee') {
                        newAllocationData.employee = dropInfo.newResource.extendedProps.extId;
                        if(groupedView) {
                            newAllocationData.allocationGroup = dropInfo.newResource._resource.parentId;
                            newAllocationData.locationId = dropInfo.newResource.extendedProps.locationId
                        } else {
                            // In non-grouped view, employee resource does not belong to specific
                            // group -> keep group and location.  
                        }
                        dropInfo.event.setExtendedProp('extId', dropInfo.newResource.extendedProps.extId);
                        dropInfo.event.setExtendedProp('extTitle', dropInfo.newResource.title);
                    }
                }
            }
            updateAllocationEvent({payload: {id: newAllocationData.id, data: newAllocationData}}, {
                    onSuccess: ({data: newRecord}) => {
                        dropInfo.event.setExtendedProp('data', newRecord);
                    },
                    onFailure: (error) => {
                        console.log(error);
                        dropInfo.revert();
                    }
                }
            );
        }
    }, [updateAllocationEvent, resizeOrMoveGroup])

    const eventReceiveCallback = useCallback(({event,view}) => {
        // Only allow to drop employees into groups
        if ('employee' === event.extendedProps.extType) {
            let groupResource = event.getResources()[0];
            if (groupResource && groupResource.extendedProps.extType === 'group') {
                let allocationGroup = groupResource.extendedProps.extId;
                // Get default time span 
                let dateTimeFrom = event.start.toUTCString();
                let dateTimeUntil = addDurationToUTCDateTime(event.start, {milliseconds: 3600000}).toUTCString();
                let groupEvents = groupResource.getEvents();
                if (Array.isArray(groupEvents) && groupEvents.length > 0) {
                    var groupEvent = null;
                    for (let i = 0; i < groupEvents.length; i++) {
                        if ('group' === groupEvents[i].extendedProps.extType) {
                            groupEvent = groupEvents[i];
                            break;
                        }
                    }
                    if (groupEvent) {
                        if (groupEvent.start) {
                            dateTimeFrom = groupEvent.start.toUTCString();
                            dateTimeUntil = groupEvent.end.toUTCString();
                        }
                        let data = {
                            employee: event.extendedProps.extId,
                            project: groupResource.extendedProps.projectId,
                            dateTimeFrom: dateTimeFrom,
                            dateTimeUntil: dateTimeUntil,
                            allocationGroup: allocationGroup,
                            location: groupResource.extendedProps.locationId,
                            // position: groupResource.extendedProps.positionId
                        }
                        createAllocationEvent({payload: {'data': data}},{
                            onSuccess: response => {
                                filterValues['allocationCreated'] = response.data.id;
                                setFilters(filterValues);
                                
                                CalendarRef.current.getApi().scrollToTime({
                                      millisecond: new Date(response.data.dateTimeFrom) - view.activeStart// (event.start - view.activeStart)
                                })
                            }
                        })
                    }
                }
            }
        }
        event.remove();
    }, [createAllocationEvent, filterValues, setFilters, CalendarRef])
    
    const getDefaultDate = useCallback(() => {
        if (strFrom) {
            return new Date(strFrom);
        }
    }, [strFrom]);

    const getValidRange = useCallback(() => {
        var start, end;
        if (strFrom && strUntil) {
            start = new Date(strFrom);
            end = addDurationToUTCDateTime(new Date(strUntil), {days: 1});
        } else if (plan) {
            start = new Date(plan.dateTimeFrom);
            end = addDurationToUTCDateTime(new Date(plan.dateTimeUntil), {days: 1});
        } else {
            return null;
        }
        return {start, end}
    }, [plan, strFrom, strUntil])

    // Change the view programmatically
    const changeView = useCallback((view, range) => {
        CalendarRef.current.getApi().changeView(view, range);
    }, [CalendarRef]);

    // Change the date programmatically
    const changeDate = useCallback((date) => {
        CalendarRef.current.getApi().gotoDate(date);
    }, [CalendarRef]);

    const getResourceHours = ({resource, groupId}) => {

        var extendedProps = resource._resource.extendedProps;
        var parentId = resource._resource.parentId;
        var duration = {milliseconds: 0, hours: 0};

        switch (extendedProps.extType) {
            case 'employee':
                resource.getEvents().forEach(event => {
                    let plain = event.toPlainObject();
                    if (!groupId || groupId === plain.extendedProps.allocationGroupId) {
                        // Ignore absence/presence background events etc
                        if (plain.extendedProps.extType === 'employee') {
                            duration.milliseconds += new Date(plain.end) - new Date(plain.start);
                        }
                    }
                })
                return (duration.milliseconds / 3600000);

            case 'group':
                groupId = extendedProps.extId;
                if (parentId) {
                    let projectResource = CalendarRef.current.getApi().getResourceById(parentId);
                    if (projectResource) {
                        projectResource.getChildren()
                            .filter(employeeResource => {
                                return 'employee' === employeeResource._resource.extendedProps.extType;
                            })
                            .forEach(employeeResource => {
                                duration.hours += parseFloat(getResourceHoursText({
                                    resource: employeeResource,
                                    groupId
                                }));
                            });
                    }
                }
                return duration.hours;

            case 'project':
                resource.getChildren().forEach(childResource => {
                    if (childResource.toPlainObject().extendedProps.extType === 'group') {
                        duration.hours += parseFloat(getResourceHoursText({resource: childResource}));
                    }
                })
                return duration.hours;

            default:
                return 0;
        }
    }

    const getResourceHoursText = ({resource, groupId}) => {
        return getResourceHours({resource, groupId}).toFixed(2);
    }

    const getResourceCost = ({resource, groupId}) => {

        var total = 0;
        var extendedProps = resource._resource.extendedProps;
        var parentId = resource._resource.parentId;

        switch (extendedProps.extType) {
            default:
                break;
            case 'employee':
                if (extendedProps.hourlyRate) {
                    total = extendedProps.hourlyRate * parseFloat(getResourceHoursText({resource, groupId}));
                }
                break;
            case 'group':
                groupId = extendedProps.extId;
                if (parentId) {
                    let projectResource = CalendarRef.current.getApi().getResourceById(parentId);
                    if (projectResource) {
                        projectResource.getChildren()
                            .filter(employeeResource => {
                                return 'employee' === employeeResource.extendedProps.extType;
                            })
                            .forEach(employeeResource => {
                                total += getResourceCost({resource: employeeResource, groupId})
                            });
                    }
                }
                break;
            case 'project':
                resource.getChildren().forEach(childResource => {
                    if (childResource.extendedProps.extType === 'group') {
                        total += getResourceCost({resource: childResource});
                    }
                })
                break;
        }
        return total;
    }

    const getResourceCostText = (resource) => {
        // return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' })
        return new Intl.NumberFormat('de-CH').format(getResourceCost(resource));
    }

    const getLocalTimeOfDateTime = (dateTime) => {
        return dateTime.toLocaleTimeString('de-DE',{hour: "numeric", minute:"numeric"},)
    }

    const getUTCTimeOfDateTime = (dateTime) => {
        return dateTime.toLocaleTimeString('de-DE',{timeZone: "UTC", hour: "numeric", minute:"numeric"},)
    }

    const isEmployeeSingleFiltered = (employeeId) => {
        return get(displayedFilters, 'employee.id')
            && JSON.stringify(get(filterValues, 'employee.id')) === JSON.stringify([employeeId])
    }

    const hasEmpFilterReset = () => {
        return get(SessionViewEmpFilterReset, 'filterValues') && get(SessionViewEmpFilterReset, 'displayedFilters')
    }

    const renderResource = (props) => {
        var resource = props.resource._resource;
        var extendedProps = resource.extendedProps;
        var positionTypeStr = extendedProps.defaultPositionStr ?? '';
        if (positionTypeStr === 'Bühne') {
            positionTypeStr = 'Buehne';
        }
        var positionTypeChar = positionTypeStr.toUpperCase().charAt(0);
        if ((extendedProps.extType==='employee' ||  extendedProps.extType==='absence') && extendedProps.avatarUrl) {
            return (
                <div>
                    <Chip title={extendedProps.defaultPositionStr ?? ''} style={{cursor: "help", "marginRight": 5}}
                          classes={{root: get(classes, "badge" + positionTypeStr) || 'badgeUnbekannt'}} size="small"
                          label={positionTypeChar}/>
                    <Chip
                        label={resource.title}
                        size="small"
                        variant="default"
                        onClick={() => onClickEdit({
                            event: {id: extendedProps.extId, extendedProps: {extType: 'employeeResource'}}
                        })}
                        avatar={
                            <Avatar src={Baseurl + extendedProps.avatarUrl}/>
                        }
                        onDelete={
                            isEmployeeSingleFiltered(extendedProps.extId)
                                ? hasEmpFilterReset()
                                    ? () => {
                                        // reset filters to previous version
                                        setFilters(
                                            SessionViewEmpFilterReset.filterValues ?? {},
                                            SessionViewEmpFilterReset.displayedFilters ?? {}
                                        )
                                        let oldView = SessionView;
                                        delete oldView.empFilterReset
                                        Dispatch({type: 'SESSION_ADD', view: oldView})
                                    }
                                    : false
                                : () => {
                                    // memorize filters
                                    let oldView = SessionView;
                                    oldView.empFilterReset = {filterValues, displayedFilters}
                                    Dispatch({type: 'SESSION_ADD', view: oldView})
                                    // set single Employee Filter
                                    setFilters({employee: {id: [extendedProps.extId]}}, {'employee.id': true})
                                }
                        }
                        deleteIcon={
                            isEmployeeSingleFiltered(extendedProps.extId)
                                ? <FilterNoneIcon/>
                                : <FilterListIcon/>
                        }
                    />
                </div>
            )
        } else if(extendedProps.extType==='project') {
            // return (
            //     <List dense={true}>
            //         <ListItem>
            //             <ListItemText
            //                 primary={resource.title}
            //                 secondary={<DateTimeFromUntil record={resource} fieldFrom={'projectStart'}/>}
            //             />
            //             <ListItemSecondaryAction>
            //                 <IconButton edge="end" aria-label="Favorite">
            //                     <StarBorder/>
            //                 </IconButton>
            //             </ListItemSecondaryAction>
            //         </ListItem>
            //     </List>
            // )
        }
    }
    
    const toggleCostColumns = useCallback(event => {
        let oldView = SessionView;
        oldView.showCostColumns = !showCostColumns;
        setShowCostColumns(!showCostColumns);
        Dispatch({type:'SESSION_ADD', view: oldView})
    },[setShowCostColumns,showCostColumns,Dispatch,SessionView])

    const toggleExpandAll = useCallback(event => {
        console.log(expandAll)
        let oldView = SessionView;
        oldView.expandAll = !expandAll;
        setExpandAll(!expandAll);
        // Dispatch({type:'SESSION_ADD', view: oldView})
    },[setExpandAll,expandAll,Dispatch,SessionView])

    const renderDurationSlider = useCallback((value) => {
        var defaultViews = JSON.parse(defaultViewsStr);
        const changeGranularity = (event,value) => {
            if (CalendarRef.current) {
                let Api = CalendarRef.current.getApi();
                let selectedView = Api.view.type;
                let sliderSteps = get(defaultViews[selectedView], 'slotDurationSliderSteps');
                if (sliderSteps) {
                    let durationObject = get(sliderSteps, (value - 1));
                    if (typeof durationObject === 'object') {
                        let slotDuration = Object.values(durationObject)[0];
                        Api.setOption('slotDuration', slotDuration);
                        // Save one selected value for each view in Session
                        let view = SessionView
                        let slotDurations = view.slotDurations ?? {};
                        slotDurations[selectedView] = slotDuration;
                        view.slotDurations = slotDurations
                        Dispatch({type: 'SESSION_ADD', view: view})
                        renderDurationSlider(value);
                    }
                }
            }
        }

        if (CalendarRef.current) {
            let selectedView = CalendarRef.current.getApi().view.type;
            if (selectedView && defaultViews[selectedView].hasOwnProperty('slotDurationSliderSteps')) {
                let calendarEl = document.getElementsByClassName('fc')[0];
                if(calendarEl){
                    let headerRight = calendarEl.querySelector('.fc-header-toolbar > .fc-toolbar-chunk:last-child');
                    let slider = headerRight.querySelector('.fc-duration-slider');
                    if (headerRight && !slider) {
                        var div = document.createElement('div');
                        div.className = 'fc-duration-slider';
                        headerRight.prepend(div);
                        ReactDOM.render(
                            <DiscreteSlider
                                defaultValue={value}
                                onChange={changeGranularity}
                                values={defaultViews[selectedView].slotDurationSliderSteps}
                            />,
                            div
                        );
                    }    
                }
            }
        }
    }, [CalendarRef, defaultViewsStr, Dispatch, SessionView]);

    const DOMgetSlider = useCallback(() => {
        if (CalendarRef.current) {
            let selectedView = CalendarRef.current.getApi().view.type;
            let defaultViews = JSON.parse(defaultViewsStr);
            if (selectedView && defaultViews[selectedView].hasOwnProperty('slotDurationSliderSteps')) {
                let calendarEl = document.getElementsByClassName('fc')[0]
                let toolbar = calendarEl.querySelector('.fc-header-toolbar');
                return toolbar.querySelector('.fc-duration-slider');
            }
        }
    }, [CalendarRef,defaultViewsStr]);

    const getSliderValueBySlotDuration =useCallback( (slotDuration,viewType) => {
        let defaultViews = JSON.parse(defaultViewsStr);
        let sliderSteps = get(defaultViews[viewType],'slotDurationSliderSteps');

        var currentIndex = -1;
        if(sliderSteps && Array.isArray(sliderSteps)) {
            // Check if current slotDuration is a slider option
            currentIndex = findIndex(sliderSteps, function (step) {
                return Object.values(step)[0] === slotDuration;
            });
        }
        return ++currentIndex;
    },[defaultViewsStr])

    const renderDurationSliderFromSession = useCallback(() => {
        let Api = CalendarRef.current.getApi();
        let selectedView = Api.view.type;
        let slotDurations = SessionView.slotDurations ?? {};
        let slotDuration = slotDurations[selectedView] ?? Api.getOption('slotDuration');
        let currentIndex = getSliderValueBySlotDuration(slotDuration, selectedView);
        renderDurationSlider(currentIndex)
    },[SessionView.slotDurations, getSliderValueBySlotDuration, renderDurationSlider])

    const toggleSlider = useCallback((ref) => {
        if (CalendarRef.current) {
            var slider = DOMgetSlider();
            if (slider) {
                slider.remove()
            } else {
                renderDurationSliderFromSession();
            }
        }
    }, [DOMgetSlider, renderDurationSliderFromSession])

    const rebuildSlider = useCallback(() => {
        if (CalendarRef.current) {
            let slider = DOMgetSlider();
            if (slider) {
                slider.remove()
                renderDurationSliderFromSession()
            }
        }
    }, [DOMgetSlider, renderDurationSliderFromSession])
    
    /**
     * Triggered before a view’s DOM skeleton is removed from the DOM.
     * view      The old View Object.
     * el        The HTML element for the container of the existing view.
     */
    const viewSkeletonDestroy = useCallback((props) => {
    },[])

    const viewChange = useCallback(({view, el}) => {
            rebuildSlider();
        }, [rebuildSlider]
    )
    
    /**
     * Triggered after a view’s non-date-related DOM structure has been rendered.
     * view      The new View Object.
     * el        The HTML element for the container of the new view.
     */
    const viewSkeletonRender = useCallback(({view, el,...props}) => {
        // Apply new header settings on view render
        switch (view.type) {
            case 'filterRange':
                setActiveHeader({
                    left: "toggleCostColumns",
                    center: "title",
                    right: "toggleSlider,resourceTimeline1d,resourceTimeline5d,resourceTimeline1m,filterRange,toggleFilterPanel"
                })
                break;
            default:
                setActiveHeader(defaultHeader);
                break;
        }

        setSelectedView(view.type)

        // Dispatch to save in session 
        let oldView = SessionView ?? {view: {selectedView: null}}
        oldView.selectedView = view.type
        Dispatch({type: 'SESSION_ADD', view: oldView});
        rebuildSlider();
    }, [SessionView, Dispatch, setActiveHeader, setSelectedView, rebuildSlider])
    
    // MouseDown Handler: Keep track of resource area resize 
    window.onmousedown = useCallback((e) => {
        if (CalendarRef.current) {
            setIsResizingResourceArea(
                e.target.classList.contains('fc-resource-timeline-divider')
                || e.target.classList.contains('fc-datagrid-cell-resizer')
            )
        }
    }, [setIsResizingResourceArea])
    
    // MouseUp Handler: check if resource area was resized and save it 
    window.onmouseup = useCallback((e) => {
        if (CalendarRef.current) {
            if(isResizingResourceArea) {
                // Memorize ResourceAreaWidth
                let calendarEl = document.getElementsByClassName('fc')[0];
                var resourceEl = calendarEl.querySelector('.fc-scroller-harness');
                if (resourceEl) {
                    var oldWidth = SessionView ? (SessionView.resourceAreaWidth ?? 0) : 0;
                    let newWidth = resourceEl.clientWidth;
                    if (newWidth && oldWidth !== newWidth) {
                        let oldView = SessionView;
                        oldView.resourceAreaWidth = newWidth;
                        Dispatch({type: 'SESSION_ADD', view: oldView});
                    }
                }
                setIsResizingResourceArea(false)
            }
        }
    }, [SessionView, Dispatch, isResizingResourceArea, setIsResizingResourceArea])

    // After render refresh hook, depends on current filterValues 
    useEffect(() => {
        const interval = setInterval(() => {
            dataProvider.getList('plans', {
                pagination: {page: 1, perPage: 155},
                sort: {field: "id", order: "desc"},
                filter: {id: plan['@id'],context:['plan-version']},
            }).then(({data}) => {
                if(data.length > 0){
                    if(localStorage.getItem('uid') !== data[0].relationsLastModifiedBy) {
                        if(0 === data[0].relationsLastModified || data[0].relationsLastModified >filterValues.seq ){
                            filterValues.seq = data[0].relationsLastModified;
                            setFilters(filterValues);
                        }
                    }
                }
            }).catch(error => {
                // setError(error);
            })
        }, refreshInterval ? refreshInterval * 1000 : 30000);
        return () => clearInterval(interval);
    }, [filterValues, setFilters, refreshInterval, dataProvider, plan]); 

    // When selectedView has changed AND rendered, set calendar-wide slot 
    // duration according to Session value because it is not possible to 
    // set it dynamically on View Object. 
    useEffect(() => {
        if (CalendarRef) {
            var slotDuration;
            if (SessionViewSlotDurations && SessionViewSlotDurations.hasOwnProperty(selectedView)) {
                slotDuration = SessionViewSlotDurations[selectedView];
            }
            if(!slotDuration){
                let defaultViews = JSON.parse(defaultViewsStr);
                if( defaultViews.hasOwnProperty(selectedView)){
                    // If session is empty, use specific view configuration.
                    slotDuration = defaultViews[selectedView].slotDuration;
                }
            }
            if (slotDuration) {
                CalendarRef.current.getApi().setOption('slotDuration', slotDuration);
            }
        }
    }, [
        getSliderValueBySlotDuration,
        CalendarRef,
        defaultViewsStr,
        selectedView,
        SessionViewSlotDurations,
        renderDurationSlider,
    ]);

    // Toolbar effects, called only when right header setting has changed
    // (Filter button added/removed)
    useEffect(() => {
        // Fix issues with header update, when filter changed
        let defaultHeader=JSON.parse(defaultHeaderStr)
        if (activeHeader.right !== defaultHeader.right) {
            setActiveHeader(defaultHeader);
        }
    }, [activeHeader.right, defaultHeaderStr, setActiveHeader])

    // Put filter settings into session and remote state 
    useEffect(() => {
        Dispatch({type: 'SESSION_ADD', listParamsEmployeeAllocations: {
                filterValues: filterValues,
                displayedFilters: displayedFilters,
                sort: currentSort.field,
                order: currentSort.order,
                page: page,
                perPage: perPage,
            }});
    }, [filterValues, displayedFilters, Dispatch, page, perPage, currentSort])

    // On Window Resize or Toolbar Toggle, adjust top offset for calendar height 
    useEffect(()=>{
        if(CalendarContainerRef.current){
            setListContainerOffset(CalendarContainerRef.current.getBoundingClientRect().top)    
        }

    },[setListContainerOffset,width,height,hideToolbar,displayedFilters,filterValues])
    
    const EventTitleWithTime = event => {
        return getUTCTimeOfDateTime(new Date(event.start)) + ' ' + event.title
    }
    
    const EventTitleDuringResize = event => {
        return getUTCTimeOfDateTime(new Date(event.start)) + ' - ' + getUTCTimeOfDateTime(new Date(event.end))
    }
    
    const offsetTop = CalendarContainerRef.current ? CalendarContainerRef.current.getBoundingClientRect().top : listContainerOffset;

    return (
        <div ref={CalendarContainerRef} className={classes.root}>
            <main className={clsx(classes.content, {[classes.contentShift]: asideOpen,})}>
                <FullCalendar
                    eventContent={(eventInfo,createElement) => {
                        if(eventInfo.event.display !== 'background'){
                            if(eventInfo.isResizing || eventInfo.isDragging){
                                return EventTitleDuringResize(eventInfo.event)
                            } else {
                                if(eventInfo.event.extendedProps.tooltip){
                                    return (
                                        <Tippy allowHTML={true} offset={[0,15]} content={<div dangerouslySetInnerHTML={{__html: eventInfo.event.extendedProps.tooltip}} />} arrow={roundArrow} followCursor={'horizontal'} plugins={[followCursor]}>
                                            <div style={{display:"block"}}>{EventTitleWithTime(eventInfo.event)}</div>
                                        </Tippy>
                                    )
                                } else {
                                    return EventTitleWithTime(eventInfo.event)
                                }
                            }    
                        }
                    }}
                    // eventDidMount={arg=>{console.log("eventDidMount",arg)}}
                    snapDuration={'00:30'}
                    slotDuration={'01:00'}
                    resourceAreaWidth={SessionView && SessionView.resourceAreaWidth ? SessionView.resourceAreaWidth : '30%'}
                    // viewDidMount={viewSkeletonRender}
                    viewWillUnmount={viewSkeletonDestroy}
                    viewClassNames={viewSkeletonRender}
                    // bootstrapFontAwesome={{}}
                    resourceAreaColumns={showCostColumns ? [
                        {
                            width: '60%',
                            headerContent: 'Ressource',
                            cellContent: renderResource,
                        },
                        {
                            width: '20%',
                            headerContent: 'Stunden',
                            cellContent: getResourceHoursText,
                        },
                        {
                            headerContent: 'Kosten',
                            width: '20%',
                            cellContent: getResourceCostText,
                        }
                    ] : [
                        {
                            headerContent: 'Ressource',
                            cellContent: renderResource,
                        }
                    ]}
                    customButtons={{
                        toggleCostColumns: {
                            text: (showCostColumns ? '➖' : '➕') + ' Details',
                            click: toggleCostColumns
                        },
                        // toggleCollapse: {
                        //     text: "Ausklappen",
                        //     click: toggleExpandAll // ()=>{console.log("click")}
                        // },
                        toggleSlider: {
                            text: '🔍',
                            click: toggleSlider
                        },
                        toggleFilterPanel: {
                            text: '≡', // ⚙',
                            click:toggleToolbar,
                        // () => {
                        //         CalendarRef.current.getApi().scrollToTime({days:4})
                            // }
                        }
                    }}
                    buttonText={buttonText}
                    locale={"de"}
                    aspectRatio={false}
                    height={ (height - offsetTop - 24)}
                    // contentHeight={"100%"}
                    ref={CalendarRef}
                    // resourcesInitiallyExpanded={expandAll}
                    resourcesInitiallyExpanded={true}
                    initialDate={getDefaultDate()}
                    validRange={getValidRange}
                    dateClick={dateClickCallback}
                    headerToolbar={activeHeader}
                    timeZone="Europe/Zurich"
                    plugins={[interactionPlugin, bootstrapPlugin, resourceDayGridPlugin, resourceTimelinePlugin]}
                    initialView={defaultView}
                    // resourceLabelText={null}
                    refetchResourcesOnNavigate={true}
                    eventReceive={eventReceiveCallback}
                    eventAllow={eventAllowCallback}
                    eventResize={eventResizeCallback}
                    eventClick={onClickEdit}
                    eventDrop={eventDropCallback}
                    editable={true}
                    eventResizableFromStart={true}
                    eventStartEditable={true}
                    displayEventTime={false}
                    views={defaultViews}
                    events={fetchEvents}
                    resources={fetchResources}
                    eventTimeFormat={props.eventTimeFormat}
                    themeSystem={'bootstrap'}
                    resourceOrder={'projectStart,extDateTimeFrom,title'}
                />
            </main>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={asideOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {/*<div className={classes.drawerHeader}>*/}
                {/*    <IconButton onClick={toggleAside}>*/}
                {/*        {theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}*/}
                {/*    </IconButton>*/}
                {/*</div>*/}
                {asideOpen ?
                    <UsersList
                        plan={plan}
                        setStickyFilters={setStickyFilters}
                        stickyFilters={stickyFilters}
                        theme={theme}
                    /> : null
                }
            </Drawer>
        </div>

    );



},PropsAreEqualByJson);

CalendarDataRenderer.defaultProps = {
    data: {},
    ids: [],
    eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false,
        timeZone: 'Europe/Berlin'
    }
};


CommentGrid.defaultProps = {
    data: {},
    ids: [],
};

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         toggleAside,
                         ...rest
                     }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
        {/*<FormGroup>*/}
        {/*    <FormControlLabel*/}
        {/*        control={<Switch size="small" checked={false} onChange={toggleAside} />}*/}
        {/*        label="Mitarbeiter"*/}
        {/*    />*/}
        {/*</FormGroup>*/}
        <Button
            onClick={toggleAside}
            label="Mitarbeiter"
        >
            <SearchIcon />
        </Button>
    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export const CalendarListAdapter = React.memo((props) => {

    // useTraceUpdate(props,"render trace update CalendarListNew")

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [asideFilters, setAsideFilters] = useState();
    
    const plan = GetPlan();
    
    const toggleAside = () => {
        setOpen(!open);
    }

    if(!plan){
        return null;
    }

    const permanentFilter = {
        "project.plan.id": plan.originId
    }

    return (
        <ListWithCreateDrawer pagination={null} title="Einsatzplanung"
                              toggleAside={toggleAside}
                              isOpenAside={open}
                              plan={plan}
                              filters={<CalendarFilters plan={plan}/>}
                              filter={permanentFilter}
                              {...props}>
            {renderProps =>{ 
                return (
                <CalendarDataRenderer
                    asideOpen={open}
                    plan={plan}
                    theme={theme}
                    setStickyFilters={setAsideFilters}
                    stickyFilters={asideFilters}
                    refreshInterval={10} {...renderProps} />
                )}
            }
        </ListWithCreateDrawer>
    );
});

CalendarListAdapter.defaultProps = {
    perPage: 0
}