import React from "react";
import {
    ListGuesser,
    FieldGuesser,
} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {ChipField, ReferenceArrayField, SingleFieldList} from "react-admin";

export const PlanCategoryList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <FieldGuesser source={"name"} />
            <ReferenceArrayField sortBy={"project.name"} link={false} reference={"projects"} source={"projects"}>
                <SingleFieldList linkType={false}>
                    <ChipField source={"name"} />
                </SingleFieldList>
            </ReferenceArrayField>
        </ListGuesser>
    );
}