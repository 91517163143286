import React, {Fragment} from "react";
import {BooleanField, NumberField} from "react-admin";
import {Divider, Typography} from "@material-ui/core";

export const InverseBooleanField = ({record, ...rest}) => {
    if (record && rest.source && record.hasOwnProperty(rest.source)) {
        record[rest.source] = !record[rest.source];
        return <BooleanField record={record} {...rest} />
    }
    return null;
}

export const MoneyField = (props) => {
    return (
        <NumberField {...props} locales="de-CH" options={{style: 'currency', currency: 'CHF'}}/>
    );
}

export const InnerFormHeadline = ({headline, ...rest}) => {
    // return (<Fragment fullWidth={!!rest.fullWidth}>
    return (<Fragment>
        <Typography variant={"h6"} gutterBottom>
            {headline}
        </Typography>
        <Divider style={{marginBottom: 10}}/>
    </Fragment>)
}
