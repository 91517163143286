import {downloadCSV} from 'react-admin';
import jsonExport from 'jsonexport/dist';

export const ProjectGroupExporter = (projects, fetchRelatedRecords) => {

    var rows = [];
    var budgets = [];
    var budgetNames = [];
    
    const Df = (date) => {
        return new Date(date).toLocaleDateString();
    }
    
    const Nf = (number) => {
        if(!number){
            number = 0;
        }
        return number.toLocaleString(undefined,{ maximumFractionDigits: 2})
        // return new Intl.NumberFormat('de-CH', {style: 'currency', currency: 'CHF'})
        //     .format(number);
    }

    // First, find all used budget types so we can create the columns for all    
    projects.forEach(post => {
        return post.groups.forEach(group => {
            group.groupBudgets.forEach(budget => {
                if (budgets.indexOf(budget.id) === -1) {
                    budgets.push(budget.id);
                    budgetNames.push(budget.positionType.name);
                }
            })
        })
    })

    projects.forEach(project => {
        project.groups.forEach(group => {
            // Check all budgets
            var budgetCols = {};
            budgets.forEach((budgetId, index) => {
                budgetCols[budgetNames[index]] = '0';
            })
            group.groupBudgets.forEach(budget=>{
                budgetCols[budget.positionType.name] = (parseFloat(budgetCols[budget.positionType.name]) + parseFloat(budget.fteAllocation)).toFixed(2);
            })
            // Build row
            rows.push({
                'Plan': project.plan.name,
                'Projekt': project.name,
                'Kategorie': project.planCategory.name,
                'Standort': project.location.name,
                'Gruppe': group.name,
                'Von': Df(group.dateTimeFrom),
                'Bis': Df(group.dateTimeUntil),
                ...budgetCols,
                'Budget': Nf(group.totalCost.budget),
                'Angestellt': Nf(group.totalCost.employee),
                'Selbstaendig': Nf(group.totalCost.freelancer),
                'Total': Nf(group.totalCost.total),
            });
        })
    })

    jsonExport(rows, {
        // headers: ['Plan', 'Projekt'], // order fields in the export
        textDelimiter: '"'
    }, (err, csv) => {
        downloadCSV(csv, 'Projektgruppen'); // download as 'groups.csv` file
    });
};
