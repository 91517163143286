import React, {useState, useCallback, useEffect, useRef} from "react";
import {useInput, TextInput} from "react-admin";
import get from 'lodash/get';
import { useLocation } from 'react-router'


import {registerLocale} from "react-datepicker";
import de from 'date-fns/locale/de';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.scss";
import {dateFormatter} from "../Tools/Tools";
registerLocale('de', de)

/**
 * 
 * @param options - see https://reactdatepicker.com/
 * @param label
 * @param source
 * @param resource
 * @param className
 * @param isRequired
 * @param providerOptions
 * @param record
 * @param showTime
 * @param rest
 * @returns {*}
 * @constructor
 */
export const MyDateTimeInput = ({
                                    options,
                                    label,
                                    source,
                                    resource,
                                    className,
                                    isRequired,
                                    // providerOptions,
                                    record,
                                    showTime,
                                    fullWidth,
                                    variant,
                                    defaultValue,
                                    onChange,
                                }) => {
    const fieldProps = {
        options,
        label,
        fullWidth,
        source,
        resource,
        className,
        isRequired,
        // providerOptions,
        record,
        variant
    }
    const [stateDefaultDate, setStateDefaultDate] = useState(defaultValue);
    const prefillDate = defaultValue ? defaultValue : get(record, source);
    const location = useLocation();
    const [date, setDate] = useState(prefillDate);
    const {input, meta} = useInput({source});
    const {touched, error} = meta;
    
    const handleChange = useCallback(value => {
        let changedValue = Date.parse(value) ? value.toISOString() : null;
        input.onChange(changedValue);
        if(typeof onChange === 'function'){
            onChange(changedValue)
        }
        setDate(value);
    }, [input,onChange]);

    const DatePickerRef = useRef();
    const pickerOpts = {
        timeFormat: "HH:mm",
        timeIntervals: 30,
        timeCaption: "Uhrzeit",
        dateFormat: "Pp",
        locale: 'de',
        required: true,
        showTimeSelect: showTime,
        ...options
    }

    useEffect(()=>{
        if(prefillDate && !date){
            setDate(prefillDate)
        }
    },[prefillDate,setDate,date])
    
    // Clear date state when location changes
    useEffect(() => {
        setDate(null)
    }, [location, setDate])

    // Apply new default value (only when changed)    
    useEffect(() => {
        if (defaultValue && defaultValue !== stateDefaultDate) {
            setStateDefaultDate(defaultValue);
            setDate(defaultValue);
            handleChange(defaultValue);
        }
    }, [defaultValue, stateDefaultDate, setDate, setStateDefaultDate, handleChange])
    
    const selectedDate = Date.parse(date) 
        ? new Date(date)
        : (Date.parse(prefillDate) 
            ? new Date(prefillDate) 
            : null);
    
    return (
        <DatePicker
            ref={DatePickerRef}
            fullWidth={true}
            selected={selectedDate}
            onChange={handleChange}
            name={source}
            id={source}
            customInput={<TextInput defaultValue={defaultValue} format={value=>dateFormatter(value,showTime)} helperText={false} {...fieldProps} />}
            {...pickerOpts}
        />
    );
}

export const TransformDefaultDateTimeFields = data => {

    // Apply timezone offsets
    ['dateTimeFrom','dateTimeUntil','date'].forEach(property=>{
        if (data.hasOwnProperty(property)) {
            let targetDate = new Date(data[property]);
            if(targetDate){
                data[property] = new Date(targetDate.getTime() - targetDate.getTimezoneOffset() * 60 * 1000);
            }
        }
    })

    return data;
}
