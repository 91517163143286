import React from "react";
import {
    ListGuesser
} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {ChipField, TextField, DateField, ReferenceField} from "react-admin";

export const PlanPhaseList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            
            <TextField source={"name"} />
            <ReferenceField sortBy={"plan.name"} link={false} reference={"plans"} source={"plan"}>
                <ChipField source={"name"} />
            </ReferenceField>
            <ReferenceField sortBy={"planCategory.name"} link={false} reference={"plan_categories"} source={"planCategory"}>
                <ChipField source={"name"} />
            </ReferenceField>
            
            
            <DateField showTime={true} source={"dateTimeFrom"}/>
            <DateField showTime={true} source={"dateTimeUntil"}/>

        </ListGuesser>
    );
}