import React from "react";
import {
    FunctionField,
    SimpleForm,
    Edit,
    Datagrid,
    ReferenceInput,
    SelectInput,
    TextField,
    List,
} from "react-admin";
import {FieldGuesser, ShowGuesser, InputGuesser} from "@api-platform/admin";
import {Avatar} from '@material-ui/core';
import {Baseurl} from "../Entrypoint";
import{MySingleFieldList,MySingleFieldListWithFromUntil}from '../Form/Lists';
import {isAdmin} from "../DataProvider/Authorization";
import {Serializer} from "../Tools/Tools";

/**
 * Employee List View 
 * @param props
 * @returns {*}
 * @constructor
 */
export const EmployeeList = (props) => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <List {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
                     perPage={50}
        >
                <Datagrid expand={<EmployeeEdit actions={null} />}>
                    <FunctionField label="Bild" render={record => <Avatar src={Baseurl + record.avatarUrl}/> }/>
                    <FieldGuesser source="firstName"/>
                    <FieldGuesser source="lastName"/>
                    <TextField label="Objekttyp" source="type.name" />
                    <TextField label="Standorte" source="locationsStr" />
                    {/*<FieldGuesser source="type"/>*/}
                    {/*<ArrayRelationList source={"locations"} >*/}
                    {/*    <TextField source={"name"} />*/}
                    {/*</ArrayRelationList>*/}
                </Datagrid>    
        </List>
    )
}

/**
 * Employee Edit View 
 * @param props
 * @returns {*}
 * @constructor
 */
export const EmployeeEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm variant={"outlined"} toolbar={false}>
                <InputGuesser source={"firstName"} /* formClassName={classes.inlineBlock} */ />
                <InputGuesser source={"lastName"} /* formClassName={classes.inlineBlock}*//>
                <InputGuesser source={"hourlyRate"} /* formClassName={classes.inlineBlock}*//>
                <ReferenceInput label="Objekttyp" source="type.@id" reference="employee_types">
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <MySingleFieldList label={"Funktionen"} overrideBasePath={"/positions"} source={"position"} optionText={"name"} />
                <MySingleFieldListWithFromUntil label={"Anwesenheiten"} overrideBasePath={"/presences"} source={"presences"} />
                <MySingleFieldListWithFromUntil label={"Abwesenheiten"} overrideBasePath={"/absences"} source={"absences"} />
            </SimpleForm>
        </Edit>
    )
}

/**
 * Employee Show view 
 * @param props
 * @returns {*}
 * @constructor
 */
export const EmployeeShow = (props) => {
    return (
        <ShowGuesser {...props}>
            <FunctionField addLabel={false} {...props} render={record => {
                return (
                    <Avatar variant="square" src={Baseurl + record.avatarUrl}/>
                );
            }}/>
            <FieldGuesser source="firstName" addLabel={true}/>
            <FieldGuesser source="lastName" addLabel={true}/>
            <FieldGuesser source="presences" addLabel={true}/>
            <FieldGuesser source="absences" addLabel={true}/>
            <FieldGuesser source="locations" addLabel={true}/>
            <FieldGuesser source="position" addLabel={true}/>
        </ShowGuesser>
    );
}

export const makeEmployeeLink = (record) => {
    var params = {
        displayedFilters: JSON.stringify({
            "employee": true,
        }),
        filter: JSON.stringify({
            "employee": {id: [record.id]},
        })
    }
    return '/employee_allocations?' + Serializer(params);
}
