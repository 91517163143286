import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

export default function useFavs(presetId = null) {

    const Dispatch = useDispatch();
    const SessionFavs = useSelector(state => state.session.favs)

    const isSelected = useCallback(id => {
        id = id ?? presetId;
        return (typeof SessionFavs ==='string') && SessionFavs.split(',').indexOf(id) !== -1
    }, [SessionFavs, presetId])

    const setSelected = useCallback((id, selected) => {
        var newFavs = typeof SessionFavs === 'string' ? SessionFavs :  '';
        var ids = newFavs.split(',');
        var index = ids.indexOf(id);
        if (selected && index === -1) {
            ids.push(id);
        } else if (!selected && index !== -1) {
            delete ids[index]
        }
        newFavs = ids.join(',')
        if (newFavs !== SessionFavs) {
            Dispatch({type: 'SESSION_ADD', favs: newFavs})
        }
    }, [Dispatch, SessionFavs]);

    return [
        isSelected,
        setSelected
    ]
}