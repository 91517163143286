import React, {useCallback} from "react";
import {
    InputGuesser
} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {
    SimpleForm,
    Edit,
    SelectInput,
    required,
    FormDataConsumer,
    ReferenceInput,
    AutocompleteInput,
    ChipField,
    DateField,
    ReferenceField
} from "react-admin";
import {
    Create,
    Datagrid,
    DateTimeInput,
    EditButton,
    List, ListButton,
    TopToolbar
} from "ra-ui-materialui";
import {makeStyles} from "@material-ui/core/styles";
import {InnerFormHeadline} from "../Form/FormFields";
import {TransformDefaultDateTimeFields} from "../Form/FormInputs";
import {SaveDeleteToolbar} from "../Form/SaveDeleteToolbar";
import RichTextInput from "ra-input-rich-text";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const validateRequired = [required()]

const useStyles = makeStyles({
    root: {
        width: 256,
    },
    suggestionsContainer: {
        zIndex: 1300,
    },
});

const AbsenceBackActions = ({basePath, data}) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Zurück" icon={<ChevronLeft/>}/>
    </TopToolbar>
);

const validateAbsenceEdit = (values) => {
    const errors = {};

    ['employee', 'absenceType'].forEach(key => {
        if (values.hasOwnProperty(key)) {
            if (!values[key]) {
                errors[key] = ['Notwendige Auswahl'];
            }
        }
    });

    ['dateTimeFrom', 'dateTimeUntil'].forEach(key => {
        if (!values[key]) {
            errors[key] = ['Bitte einen Zeitpunkt wählenl'];
        }
    });

    if (values.dateTimeFrom && values.dateTimeUntil) {
        let from = new Date(values.dateTimeFrom);
        let until = new Date(values.dateTimeUntil);
        if (until <= from) {
            errors.dateTimeUntil = ['Der Endzeitpunkt muss hinter dem Startzeitpunkt liegen.']
        }
    }
    return errors
}

export const AbsenceSaveTransformer = ({plan, employee, dateTimeFrom, dateTimeUntil, remarks, absenceType}) => {
    return TransformDefaultDateTimeFields(
        {plan, employee, remarks, dateTimeFrom, dateTimeUntil, absenceType: absenceType['@id']}
    );
}

export const AbsenceEditInline = ({onDelete, fullWidth, onCancel, notify, ...props}) => {
    const onFailure = useCallback(error => {
        notify(error.message, 'warning')
    }, [notify]);
    return (
        <Edit undoable={true} onFailure={onFailure} resource={"employee_allocations"} transform={AbsenceSaveTransformer}
              actions={false} hasShow={false} {...props}>
            <SimpleForm validate={validateAbsenceEdit} toolbar={<SaveDeleteToolbar
                mutationMode={"undoable"}
                onRedirect={onDelete}
                deleteConfirmContent={"Soll diese Abwesenheit wirklich gelöscht werden?"}
            />} variant={"outlined"}>
                <InnerFormHeadline headline={"Abwesenheit bearbeiten"} fullWidth={fullWidth}/>
                <DateTimeInput fullWidth={fullWidth} label="Beginn" showTime={true} source="dateTimeFrom"/>
                <DateTimeInput fullWidth={fullWidth} label="Ende" showTime={true} source="dateTimeUntil"/>
                <ReferenceInput fullWidth={fullWidth} label="Typ" reference={"absence_types"}
                                source={"absenceType.@id"}>
                    <SelectInput optionText={"name"}/>
                </ReferenceInput>
                <RichTextInput fullWidth={fullWidth} defaultValue={""} label="Bemerkungen" source="remarks"
                               toolbar={[['bold', 'italic', 'underline']]}/>
            </SimpleForm>
        </Edit>
    )
}


export const AbsenceEdit = (props) => {
    const classes = useStyles();

    return (
        <Edit {...props} actions={<AbsenceBackActions/>} hasList={true} hasShow={false}>
            <SimpleForm variant={"outlined"} undoable={false}>
                <InnerFormHeadline headline={"Abwesenheit bearbeiten"} fullWidth={true}/>
                <InputGuesser validate={validateRequired} source={"plan"} /* formClassName={classes.inlineBlock} */ />
                <FormDataConsumer>
                    {formDataProps => (
                        <ReferenceInput
                            filter={{
                                context: ['emp-short'],
                            }}
                            perPage={100}
                            filterToQuery={searchText => ({
                                fullName: searchText,
                                plans: formDataProps.formData.plan ?? null
                            })}
                            label="Mitarbeiter"
                            source="employee"
                            reference="employees">
                            {/*https://github.com/marmelab/react-admin/issues/4537*/}
                            {/*Issue with Autocomplete suggestion behind modal/drawer*/}
                            <AutocompleteInput
                                options={{
                                    classes: classes,
                                    suggestionsContainerProps: {className: classes.suggestionsContainer}
                                }}
                                variant="outlined"
                                optionText="fullName"
                                allowEmpty={false}/>
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <DateTimeInput label="Beginn" showTime={true} validate={validateRequired} source="dateTimeFrom"/>
                <DateTimeInput label="Ende" showTime={true} validate={validateRequired} source="dateTimeUntil"/>
                <InputGuesser validate={validateRequired} source={"type"} /* formClassName={classes.inlineBlock} */ />
            </SimpleForm>
        </Edit>
    )
}

export const AbsenceCreateInline = ({resource, notify, plan, initialValues, onCreate, onCancel, ...props}) => {

    const fullWidth = !!props.fullWidth
    initialValues.plan = plan.id
    initialValues.absenceType = {'@id': '/api/absence_types/1'}

    // If called inline, replace normal success handling (Redirection)
    var onSuccess = null;
    if (onCancel || onCreate) {
        onSuccess = function (record) {
            if (typeof onCancel === 'function') onCancel();
            if (typeof onCreate === 'function') onCreate(record.data, 'created');
        }
    }

    return (
        <Create {...props} resource={"employee_allocations"}
                record={initialValues}
                onSuccess={onSuccess}
                transform={AbsenceSaveTransformer}>
            <SimpleForm validate={validateAbsenceEdit} variant={"outlined"}>
                <InnerFormHeadline headline={"Neue Abwesenheit"} fullWidth={fullWidth}/>
                <ReferenceInput
                    validate={validateRequired}
                    fullWidth={fullWidth}
                    filter={{context: ['emp-short']}}
                    perPage={100}
                    filterToQuery={searchText => ({
                        fullName: searchText,
                        plans: plan.id
                    })}
                    label="Mitarbeiter"
                    source="employee"
                    reference="employees">
                    <AutocompleteInput
                        variant="outlined"
                        optionText="fullName"
                        allowEmpty={false}/>
                </ReferenceInput>
                <DateTimeInput fullWidth={fullWidth} label="Beginn" showTime={true} source="dateTimeFrom"/>
                <DateTimeInput fullWidth={fullWidth} label="Ende" showTime={true} source="dateTimeUntil"/>
                <ReferenceInput validate={validateRequired} fullWidth={fullWidth} label="Typ" reference={"absence_types"} source={"absenceType.@id"}>
                    <SelectInput optionText={"name"}/>
                </ReferenceInput>
                <RichTextInput fullWidth={fullWidth} defaultValue={""} label="Bemerkungen" source="remarks"
 toolbar={[['bold', 'italic', 'underline']]}/>
            </SimpleForm>
        </Create>
    )
}

export const AbsenceCreate = (props) => {
    const classes = useStyles();
    return (
        <Create redirect="list" actions={<AbsenceBackActions/>} hasShow={false} {...props}>
            <SimpleForm variant={"outlined"} undoable={false}>
                <InnerFormHeadline headline={"Neue Abwesenheit"} fullWidth={true}/>
                <InputGuesser validate={validateRequired} source={"plan"} /* formClassName={classes.inlineBlock} */ />
                <FormDataConsumer>
                    {formDataProps => (
                        <ReferenceInput
                            filter={{
                                context: ['emp-short'],
                            }}
                            perPage={100}
                            filterToQuery={searchText => ({
                                fullName: searchText,
                                plans: formDataProps.formData.plan ?? null
                            })}
                            label="Mitarbeiter"
                            source="employee"
                            reference="employees">
                            {/*https://github.com/marmelab/react-admin/issues/4537*/}
                            {/*Issue with Autocomplete suggestion behind modal/drawer*/}
                            <AutocompleteInput
                                options={{
                                    classes: classes,
                                    suggestionsContainerProps: {className: classes.suggestionsContainer}
                                }}
                                variant="outlined"
                                optionText="fullName"
                                allowEmpty={false}/>
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <DateTimeInput label="Beginn" showTime={true} validate={validateRequired} source="dateTimeFrom"/>
                <DateTimeInput label="Ende" showTime={true} validate={validateRequired} source="dateTimeUntil"/>
                <InputGuesser validate={validateRequired} source={"type"} /* formClassName={classes.inlineBlock} */ />
            </SimpleForm>
        </Create>
    )
}

export const AbsenceList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <List filters={false} {...props} {...addProps}
              bulkActionButtons={false}
              exporter={false}
              hasShow={false}
              hasEdit={false}
        >
            <Datagrid>
                <ReferenceField label={"Name"} sortBy={"employee.fullName"} link={false} reference={"employees"}
                                source={"employee.@id"}>
                    <ChipField source={"fullName"}/>
                </ReferenceField>

                <DateField showTime={true} source={"dateTimeFrom"}/>
                <DateField showTime={true} source={"dateTimeUntil"}/>
                <ReferenceField label={"Abwesenheitstyp"} sortBy={"type.name"} link={false} reference={"absence_types"}
                                source={"type.@id"}>
                    <ChipField source={"name"}/>
                </ReferenceField>

                <ReferenceField sortBy={"plan.name"} link={false} reference={"plans"} source={"plan"}>
                    <ChipField source={"name"}/>
                </ReferenceField>

                <EditButton/>
            </Datagrid>
        </List>
    );
}