import React from "react";
import {
    NumberInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Create,
    ReferenceArrayInput,
    SelectArrayInput
} from "react-admin";
import { parse } from "query-string";


export const PositionCreate = props => {
    // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
    const { employee_id } = parse(props.location.search);
    const defaultValues = employee_id ? {employees: [employee_id]} : null;
    
    return(
        <Create {...props} >
            <SimpleForm variant="outlined" defaultValue={defaultValues} redirect={"show"}>
                <TextInput label={"Name der neuen Funktion"} source={'name'}/>
                <ReferenceArrayInput label="Personen mit dieser Funktion" reference="employees" source={'employees'} >
                    <SelectArrayInput optionText={'fullName'}/>
                </ReferenceArrayInput>
                <ReferenceInput label="Funktionsgruppe" reference="position_types" source={'positionType'} >
                    <SelectInput optionText={'name'}/>
                </ReferenceInput>
                <NumberInput label="Stundensatz" source={"ansatz"} /> 
                {/*<TextInput source={'name'}/>*/}
            </SimpleForm>
        </Create>        
    )
}
