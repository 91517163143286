import * as React from "react";
import {useSelector} from 'react-redux';
import {
    Redirect,
} from "react-router-dom";
import {Serializer} from "../Tools/Tools";

export const entryUrl = (params) => {
    var newParams = {}
    if (params && params.filterValues) {
        newParams = {
            displayedFilters: JSON.stringify(params.displayedFilters),
            filter: JSON.stringify(params.filterValues)
        }
    }
    return '/employee_allocations?' + Serializer(newParams);
}

const Redirector = () => {
    const SessionParams = useSelector(state => state.session.listParamsEmployeeAllocations);
    return (
        <Redirect to={entryUrl(SessionParams)}/>
    );
} 
export default Redirector; 