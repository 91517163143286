import React,{ cloneElement} from 'react';
import {
    List,
    TextField,
    ChipField,
    Datagrid,
    FunctionField,
    ReferenceManyField,
    BooleanField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    DateField,
    Filter,
    SearchInput,
    TopToolbar,
    CreateButton,
    ExportButton,
    Button,
    sanitizeListRestProps, ReferenceArrayInput, AutocompleteArrayInput,
} from "react-admin";
import { FieldGuesser} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import LinkField from "../Form/LinkField";
import SearchIcon from '@material-ui/icons/Search';
import get from 'lodash/get';
import {ProjectGroupExporter} from "../Exporter/ProjectGroupExporter";
import {InnerFormHeadline, MoneyField} from "../Form/FormFields";
import {Serializer} from "../Tools/Tools";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {LocationOnOutlined} from "@material-ui/icons";
import {ProductionIcon} from "../Layout/SpektakuloTheme";
import StarBorder from "@material-ui/icons/StarBorder";
import StarIcon from '@material-ui/icons/Star';
import useFavs from '../Hook/useFavs';
import {useSessionViewSync} from "../Hook/useSessionViewSync";

export const makePlanPhaseLink = (record) => {
    var params = {
        displayedFilters: JSON.stringify({
            "dateTimeFrom[date_overlaps_range]": true,
        }),
        filter: JSON.stringify({
            "dateTimeFrom[date_overlaps_range]": record.dateTimeFrom + ',' + record.dateTimeUntil,
            "selectedView": "filterRange",
        })
    }
    return '/employee_allocations?' + Serializer(params);
}

export const makeProjectLink = (record) => {
    var params = {
        displayedFilters: JSON.stringify({
            "dateTimeFrom[date_overlaps_range]": true,
            "location": true,
            "project.id": true,
        }),
        filter: JSON.stringify({
            "dateTimeFrom[date_overlaps_range]": record.dateTimeFrom + ',' + record.dateTimeUntil,
            "location": [record.location.hasOwnProperty('@id') ? record.location['@id']: record.location],
            "selectedView": "filterRange",
            "project": {id: [record.id]},
        })
    }
    return '/employee_allocations?' + Serializer(params);
}

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
    toggleAside,
                         ...rest
                     }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
        <Button
            onClick={toggleAside}
            label="Mitarbeiter"
        >
            <SearchIcon />
        </Button>
    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};


const FteCalculationField = ({sourceBaseFrom,sourceBaseUntil,sourceFrom,sourceUntil,...props}) => {
    return(
        <FunctionField {...props} render={record => {
            if (props.record) {
                let baseFrom = get(props.record, sourceBaseFrom)
                let baseUntil = get(props.record, sourceBaseUntil)
                let from = get(props.record, sourceFrom)
                let until = get(props.record, sourceUntil)
                if (baseFrom && baseUntil && from && until) {
                    let baseDurationMs= new Date(baseUntil) - new Date(baseFrom)
                    let durationMs= new Date(until) - new Date(from)
                    props.record._fteCalculation = durationMs / baseDurationMs;
                } else {
                    props.record._fteCalculation = 0;
                }
            }
            return <NumberField record={record} source={"_fteCalculation"} locales="de-CH" options={{maximumFractionDigits: 1}}/>
        }} />
    )
}

const HourlyDurationField = ({sourceFrom,sourceUntil,...props}) => (
    <FunctionField {...props} render={record => {
        if (props.record) {
            // get nested properties with lodash/get
            let from = get(props.record, sourceFrom)
            let until = get(props.record, sourceUntil)
            if (from&&until) {
                let durationMs = new Date(until) - new Date(from)
                props.record._hourlyDuration = durationMs / 3600000;
            } else {
                props.record._hourlyDuration = 0;
            }
        }
        return <NumberField record={record} source={"_hourlyDuration"} locales="de-CH" options={{maximumFractionDigits: 1}}/>
    }}/>
)

const AllocationCostField = ({sourceFrom,sourceUntil,sourceRate,...props}) => (
    <FunctionField {...props} render={record => {
        if (props.record) {
            // get nested properties with lodash/get
            let rate = get(props.record, sourceRate)
            if (typeof rate !== 'undefined') {
                let from = get(props.record, sourceFrom)
                let until = get(props.record, sourceUntil)
                if(from && until){
                    let durationMs = new Date(until) - new Date(from)
                    props.record._allocationCost = durationMs / 3600000 * rate;
                }
            } else {
                props.record._allocationCost = 0;
            }
        }
        return <MoneyField record={record} source={"_allocationCost"} />
    }}/>
)

const GroupExpanded = (props)  => {
    return (
    <ReferenceManyField {...props} filter={{allocationType:'employee'}} label="Comments by" reference="employee_allocations" target="allocationGroup">
             <Datagrid >
                 <TextField label="Mitarbeiter" source={"employee.fullName"}/>
                 <TextField label="Funktionsgruppe" source={"position.positionType.name"}/>
                 <TextField label="Funktion" source={"position.name"}/>
                 <BooleanField label="Freelancer" source={"employee.isFreelancer"}/>
                 <MoneyField label={"Ansatz"} source={"employee.hourlyRate"} />
                 <HourlyDurationField label={"Stunden"} sourceFrom={"dateTimeFrom"} sourceUntil={"dateTimeUntil"}/>
                 <FteCalculationField label={"FTE"} sourceFrom={"dateTimeFrom"} sourceUntil={"dateTimeUntil"} sourceBaseFrom={"allocationGroup.dateTimeFrom"} sourceBaseUntil={"allocationGroup.dateTimeUntil"}/>
                 <AllocationCostField label={"Total"} sourceFrom={"dateTimeFrom"} sourceUntil={"dateTimeUntil"} sourceRate={"employee.hourlyRate"} />
                 
                 {/*<HourlyDurationField label={"FTE"} sourceFrom={"dateTimeFrom"} sourceUntil={"dateTimeUntil"}/>*/}
                 {/*<NumberField label={"Stunden"} source={}*/}
                 {/*<FieldGuesser label="Von" source={"dateTimeFrom"}/>*/}
                 {/*<FieldGuesser label="Bis" source={"dateTimeUntil"}/>*/}
             </Datagrid>
    </ReferenceManyField>
    )
}

const ProjectExpanded = props => {
    return (
        <ReferenceManyField label="Groups" basePath={"/projects"} source={"id"} reference="groups" target="project" record={props.record}>
            <Datagrid expand={<GroupExpanded />} >
                <TextField label="Gruppe" source={"name"}/>
                <FieldGuesser label="Von" source={"dateTimeFrom"}/>
                <FieldGuesser label="Bis" source={"dateTimeUntil"}/>
                {/*<BudgetArrayField label={"Budgets"} />*/}
                {/*<FteGroupField label="FTE Licht" currentVal={"currentFteLight"} targetVal={"FteLight"}/>*/}
                {/*<FteGroupField label="FTE Licht" currentVal={"currentFteLight"} targetVal={"FteLight"}/>*/}
                {/*<FteGroupField label="FTE Sound" currentVal={"currentFteSound"} targetVal={"FteSound"}/>*/}
                {/*<FteGroupField label="FTE Stage" currentVal={"currentFteStage"} targetVal={"FteStage"}/>*/}
                {/*<FteGroupField label="FTE Video" currentVal={"currentFteVideo"} targetVal={"FteVideo"}/>*/}
                <MoneyField label="Budget" source="totalCost.budget" />
                <MoneyField label="Selbständig" source="totalCost.freelancer" />
                <MoneyField label="Angestellt" source="totalCost.employee" />
                <MoneyField label="Total" source="totalCost.total" />
            </Datagrid>
        </ReferenceManyField>
    )
}

export const ProjectDetail = ({onCancel,resource,...props}) => {
    return (
        <Show resource={'projects'} {...props} hasEdit={false}>
            <SimpleShowLayout>
                <InnerFormHeadline headline={"Projekt Detail"} />
                <TextField source={"name"} />
                <DateField source="dateTimeFrom" showTime={true}/>
                <DateField source="dateTimeUntil" showTime={true}/>
                <ChipField icon={<LocationOnOutlined/>} label="Standort" source={"location.name"} />
                {/*<ChipField label="Kategorie" source={"planCategory.name"} />*/}
                <ReferenceField label="Produktion" link={false} reference={"productions"} source={"production"} >
                    <ChipField icon={<ProductionIcon />} source={"name"} />
                </ReferenceField>
                <IconButton onClick={onCancel} title={"Schliessen"} aria-label="schliessen">
                    <CloseIcon />
                </IconButton>
            </SimpleShowLayout>
        </Show>
    )
}

const ProjectListFilter = props => (
    <Filter variant={"outlined"} {...props}>
        <SearchInput source="name" alwaysOn />
        <ReferenceArrayInput
            label="Standort"
            filter={props.filter}
            filterToQuery={searchText => ({ name: searchText })}
            source="location"
            reference="locations" alwaysOn >
            <AutocompleteArrayInput variant="outlined" optionText="name" allowEmpty={false} />
        </ReferenceArrayInput>
    </Filter>
);

export const FavoriteButton = ({selected, onChange, ...props}) => {
    return (
        <IconButton
            aria-label={'fav'}
            component="div"
            tabIndex={-1}
            aria-hidden="true"
            onClick={() => {
                if (typeof onChange === 'function') {
                    onChange(!selected);
                }
            }}
            {...props}
        >
            {selected ?
                <StarIcon color={'primary'}/>
                : <StarBorder color={'disabled'}/>
            }
        </IconButton>
    );
}

const ProjectFavoriteButton = (record) => {
    const [favSelected,setFavSelected] = useFavs(record.id);
    return (
        <FavoriteButton
            onChange={selected => setFavSelected(record.id, selected)}
            selected={favSelected()}
        />
    )
}

export const ProjectList = props => {
    
    useSessionViewSync();
    
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    addProps.filter = {
        "plan": localStorage.getItem('PlanID')
    }
    
    return (
        <List {...props} {...addProps} 
              exporter={ProjectGroupExporter}
              filters={<ProjectListFilter {...addProps} />}
              hasCreate={false} 
              hasEdit={false} 
              hasShow={false} >
            <Datagrid expand={<ProjectExpanded />} >
                <FunctionField label={"Favorit"} render={ProjectFavoriteButton} /> 
                <LinkField label="Projekt" to="/employee_allocations" linkCallback={record => makeProjectLink(record)} >
                    <ChipField source="name"/>
                </LinkField>
                <FieldGuesser source={"dateTimeFrom"}/>
                <FieldGuesser source={"dateTimeUntil"}/>
                <MoneyField label="Budget" source="totalCost.budget" />
                <MoneyField label="Selbständig" source="totalCost.freelancer" />
                <MoneyField label="Angestellt" source="totalCost.employee" />
                <MoneyField label="Total" source="totalCost.total" />
                <ReferenceField link={false} label="Standort" reference={"locations"} source={"location.@id"}>
                    <ChipField source="name"/>
                </ReferenceField>
                {/*{admin && <EditButton />}*/}
                {/*{admin && <DeleteWithConfirmButton />}*/}
            </Datagrid>
        </List>
    );
}