import {
    createMuiTheme
} from '@material-ui/core/styles';
import {
    DateRangeOutlined,
    CloudUploadOutlined,
    PeopleAltTwoTone,
    TheatersTwoTone,
    GroupWorkOutlined,
    LocationOnOutlined,
    SlideshowOutlined,
    PersonAddDisabledOutlined,
    PersonAddOutlined,
    AssignmentIndOutlined,
    EventNoteOutlined,
    MonetizationOnOutlined,
} from '@material-ui/icons';

export const Theme = createMuiTheme({
    palette: {
        // type: 'dark',
        secondary: {
            // main: '#14B38B',
            main: 'rgb(0, 127, 119)',
            // main: '#00BDB6',
        },
        primary: {
            main: '#0081B8',
        },
    },
    
    // Dense everything
    props: {
        MuiButton: {
            size: 'small',
        },
        MuiFilledInput: {
            margin: 'dense',
        },
        MuiFormControl: {
            margin: 'dense',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiListItem: {
            dense: true,
        },
        MuiOutlinedInput: {
            margin: 'dense',
        },
        MuiFab: {
            size: 'small',
        },
        MuiTable: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
        },
        MuiToolbar: {
            variant: 'dense',
        },
    },
    overrides: {
        RaAutocompleteArrayInput:{
            chipContainerOutlined: {
                margin: '1px'
            },
            chipContainerFilled: {
                margin: '1px'
            },
        },

        MuiIconButton: {
            sizeSmall: {
                // Adjust spacing to reach minimal touch target hitbox
                marginLeft: 4,
                marginRight: 4,
                padding: 12,
            },
        },
    },
});

export const CalendarIcon = DateRangeOutlined;
export const ImportIcon = CloudUploadOutlined;
export const EmployeeIcon = PeopleAltTwoTone;
export const EmployeeAllocationIcon = AssignmentIndOutlined;
export const PerformanceIcon = SlideshowOutlined;
export const GroupIcon = GroupWorkOutlined;
export const ProductionIcon = TheatersTwoTone;
export const LocationIcon = LocationOnOutlined;
export const AbsenceIcon = PersonAddDisabledOutlined;
export const PresenceIcon = PersonAddOutlined;
export const ProjectIcon = EventNoteOutlined;
export const BudgetIcon = MonetizationOnOutlined;