import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import {withStyles} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";
import Zoom from '@material-ui/core/Zoom';

const iOSBoxShadow =
    '0 2px 1px rgba(0,0,0,0.1),0 2px 4px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
    root: {
        color: '#2780E3',
        marginTop: 2,
        marginRight:10,
        // height: 2,
        // padding: '15px 0',
    },
    thumb: {
        height: 12,
        width: 12,
        // backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -5,
        marginLeft: -5,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 2px 1px rgba(0,0,0,0.1),0 2px 4px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        transform: 'scale(0) translateY(0px)',
        // transform: 'scale(0) translateY(100px)',
        // '&:focus, &:hover, &$active': {
        //     transform: 'scale(1) translateY(100px)',
        // },
        '& > span': {
            transform: 'rotate(-225deg)',
            '& > span': {
                transform: 'rotate(225deg)',
                // color:  Theme.palette.secondary,
            }
        },
        //     // borderRadius: '0 50% 50% 50%',
        //     '& span': {
        //         color: 'green'    
        //     }
        // },
        // '> span': {
        //     border: '3px solid orange'
        // },
        // '.span': {
        //     border: '3px solid green'
        // },
        marginTop: 70,
        //
        zIndex: 2,
        // background: '#2780E3',
        // color: Theme.palette.secondary,
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        opacity: 0.75,
        height: 4,
        width: 1,
        marginTop: -1,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

const useStyles = makeStyles(theme => ({
    root: {
        // margin: theme.spacing(1),
        width: 220
    },
   
}))
function valuetext(value) {
    return `${value} min`;
}
const BlueOnGreenTooltip = withStyles({
    tooltip: {
        // color: "white",
        fontSize: '0.9em',
        backgroundColor: "#2780E3"
    }
})(Tooltip);

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <BlueOnGreenTooltip 
            TransitionComponent={Zoom}
            arrow={false} 
            open={open} 
            enterTouchDelay={0} 
            placement="bottom" 
            title={value}>
             {children}
         </BlueOnGreenTooltip>
     );
 }

// ValueLabelComponent.propTypes = {
//     children: PropTypes.element.isRequired,
//     open: PropTypes.bool.isRequired,
//     value: PropTypes.number.isRequired,
// };
export const DiscreteSlider = ({onChange,defaultValue, values}) => {
    const classes = useStyles();

    const getScaledValue = (n) => {
        return Object.keys(values[(n - 1)])[0];
    }

    return (
        // <ThemeProvider theme={Theme}>
        <div className={classes.root}>
            <IOSSlider 
                color={"primary"}
                // className={classes.slider}
                ValueLabelComponent={ValueLabelComponent}
                onChangeCommitted={onChange}
                defaultValue={defaultValue ?? 1}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={values.length}
                scale={getScaledValue}
            />
        </div>
        // </ThemeProvider>
    );
}