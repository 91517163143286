import React from "react";
import germanMessages from './Translation/german';
import {createHashHistory as createHistory} from 'history';
import {Route, Redirect} from 'react-router-dom';
import {
    HydraAdmin,
    ResourceGuesser,
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {Resource} from "react-admin";
import FullCalendar from "@fullcalendar/react";
// Connection 
import {Entrypoint} from "./Entrypoint";
import AuthProvider from './DataProvider/AuthProvider'

// Theming and layout  
import SpektakuloLayout from './Layout/SpektakuloLayout';
import {
    Theme,
    AbsenceIcon,
    EmployeeIcon,
    EmployeeAllocationIcon,
    GroupIcon,
    ImportIcon,
    LocationIcon,
    PerformanceIcon,
    PresenceIcon,
    ProductionIcon,
    ProjectIcon, BudgetIcon
} from "./Layout/SpektakuloTheme";

// Session & Views
import SessionReducer from './Session/SessionReducer';
import {CalendarListAdapter} from "./Calendar/CalendarListAdapter";
import {AbsenceList, AbsenceEdit, AbsenceCreate} from "./Views/AbsenceViews";
import {ImportShow, ImportCreate, ImportList} from "./Views/ImportViews";
import {EmployeeShow, EmployeeList, EmployeeEdit} from "./Views/EmployeeViews";
import {AdminResourceGuesser, AuthorizedListGuesser} from "./DataProvider/Authorization";
import {AllocationCreate} from "./Views/EmployeeAllocationViews";
import {ProjectList} from "./Views/ProjectViews";
import {GroupCreate, GroupList, StandardGroupEdit} from "./Views/GroupViews";
import {GroupBudgetCreate, GroupBudgetEdit, GroupBudgetList} from "./Views/GroupBudgetViews";
import SwitchPlan from "./Layout/SwitchPlan";
import Dashboard from "./Layout/Dashboard";
import {PositionCreate} from "./Views/PostionViews";
import {PerformanceEdit, PerformanceList} from "./Views/PerformanceViews";
import {PlanList} from "./Views/PlanViews";
import {EventList} from "./Views/EventViews";
import {PresenceList} from "./Views/PresenceViews";
import {ProductionList} from "./Views/ProductionViews";
import {LocationList} from "./Views/LocationViews";
import {EmployeeTypeList} from "./Views/EmployeeTypeViews";
import {AbsenceTypeList} from "./Views/AbsenceTypeViews";
import {LocationTypeList} from "./Views/LocationTypeViews";
import {PositionTypeList} from "./Views/PositionTypeViews";
import {PositionList} from "./Views/PositionViews";
import {PlanCategoryList} from "./Views/PlanCategoryViews";
import {PlanPhaseList} from "./Views/PlanPhaseViews";
import useInitialState from "./Hook/useInitialState";

// Convert a `File` object returned by the upload input into a base 64 string.
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
const fetchHeaders = {Authorization: `Bearer ${window.localStorage.getItem("token")}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});

const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, {headers: new Headers(fetchHeaders)})
    .then(
        ({api}) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                return window.localStorage.getItem("token")
                                    ? <Redirect to="/"/>
                                    : <Redirect to="/login"/>;
                                // window.location.reload() :
                                // window.localStorage.getItem("token") ? window.location.reload() :
                                // window.localStorage.removeItem('token');
                                // window.localStorage.removeItem('permissions');
                                // window.localStorage.removeItem('roles');

                            }}/>
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );

const dataProvider = baseHydraDataProvider(
    Entrypoint,
    fetchHydra,
    apiDocumentationParser,
    true // useEmbedded parameter
);

const UploadCapableDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        if (resource === 'performances') {

        }
        if (params.data) {
            if (params.data.hasOwnProperty('_transformed')) {
                // use transformed version to actually post to server,
                // but keep the original one in the app
                var newParams = {
                    id: params.id,
                    previousData: params.previousData,
                    data: params.data._transformed
                };
                var promise = dataProvider.update(resource, newParams)
                delete params.data._transformed;

                return promise;
            }
        }
        return dataProvider.update(resource, params)
    },
    create: (resource, params) => {
        if (resource !== 'imports' || !params.data.file) {
            return dataProvider.create(resource, params);
        }
        var files = [params.data.file];
        return Promise.all(files.map(convertFileToBase64))
            .then(base64Files =>
                base64Files.map(base64 => ({
                    base64: base64,
                    plan: params.data.plan ? `${params.data.plan}` : null,
                    // plan: `${params.data.plan}`,
                    jsonContent: `${params.data.file.title}`,
                }))
            )
            .then(transformedFiles =>
                dataProvider.create(resource, {
                    ...params,
                    data: transformedFiles[0],
                })
            );
    },
};

const History = createHistory();
const i18nProvider = polyglotI18nProvider(() => germanMessages, 'de');

export default () => {

    const initialState = useInitialState();
    return (
        <HydraAdmin
            disableTelemetry={true}
            dashboard={Dashboard}
            customReducers={{session: SessionReducer}}
            title="SPEKTAKULO"
            layout={SpektakuloLayout}
            theme={Theme}
            history={History}
            entrypoint={Entrypoint}
            dataProvider={UploadCapableDataProvider}
            authProvider={AuthProvider}
            initialState={initialState}
            useEmbedded={true}
            customRoutes={[
                <Route path="/switchPlan/:id" component={SwitchPlan}/>
            ]}
            locale={"de"}
            i18nProvider={i18nProvider}
        >
            {permissions => {
                var props = {"permissions": permissions};
                return [
                    <ResourceGuesser {...props}
                                     name="employee_allocations"
                                     create={AllocationCreate}
                                     list={CalendarListAdapter}
                                     icon={EmployeeAllocationIcon}
                                     options={{label: 'Einsatzplanung'}}
                    />,
                    <ResourceGuesser {...props}
                                     name="projects"
                                     icon={ProjectIcon}
                                     list={ProjectList}
                                     options={{label: 'Projekte', menu: 'B'}}
                    />,

                    <AdminResourceGuesser {...props}
                                          name="plan_categories"
                                          list={PlanCategoryList}
                                          options={{label: 'Plankategorien'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="plan_phases"
                                          list={PlanPhaseList}
                                          options={{label: 'Planphasen'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="plans"
                                          list={PlanList}
                                          options={{label: 'Planungen'}}
                    />,

                    <AdminResourceGuesser {...props}
                                          name="productions"
                                          icon={ProductionIcon}
                                          list={ProductionList}
                                          options={{label: 'Produktionen'}}
                    />,
                    <ResourceGuesser {...props}
                                     name="groups"
                                     icon={GroupIcon}
                                     list={GroupList}
                                     options={{label: 'Gruppen'}}
                                     edit={StandardGroupEdit}
                                     create={GroupCreate}
                    />,
                    <ResourceGuesser {...props}
                                     name="group_budgets"
                                     icon={BudgetIcon}
                                     options={{label: 'Budgets'}}
                                     create={GroupBudgetCreate}
                                     edit={GroupBudgetEdit}
                                     list={GroupBudgetList}
                    />,
                    <ResourceGuesser {...props}
                                          name="absences"
                                          perPage={130}
                                          icon={AbsenceIcon}
                                          options={{label: 'Abwesenheiten'}}
                                          edit={AbsenceEdit}
                                          create={AbsenceCreate}
                                          list={AbsenceList}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="events"
                                          icon={ProductionIcon}
                                          list={EventList}
                                          options={{label: 'Ereignisse'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="event_types"
                                          list={AuthorizedListGuesser}
                                          options={{label: 'Ereignistypen'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="performances"
                                          icon={PerformanceIcon}
                                          list={PerformanceList}
                                          options={{label: 'Vorstellungen'}}
                                          edit={PerformanceEdit}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="employees"
                                          icon={EmployeeIcon}
                                          list={EmployeeList}
                                          edit={EmployeeEdit}
                                          show={EmployeeShow}
                                          options={{label: 'Mitarbeiter'}}
                    />,
                    
                    <AdminResourceGuesser {...props}
                                          name="presences"
                                          icon={PresenceIcon}
                                          list={PresenceList}
                                          options={{label: 'Anwesenheiten'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="locations"
                                          icon={LocationIcon}
                                          list={LocationList}
                                          options={{label: 'Standorte'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="employee_types"
                                          list={EmployeeTypeList}
                                          options={{label: 'Mitarbeitertypen'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="absence_types"
                                          list={AbsenceTypeList}
                                          options={{label: 'Abwesenheitstypen'}}
                    />,
                    <AdminResourceGuesser {...props}

                                          name="location_types"
                                          list={LocationTypeList}
                                          options={{label: 'Standorttypen'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="position_types"
                                          list={PositionTypeList}
                                          options={{label: 'Funktionstypen'}}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="positions"
                                          list={PositionList}
                                          options={{label: 'Funktionen'}}
                                          create={PositionCreate}
                    />,
                    <AdminResourceGuesser {...props}
                                          name="imports"
                                          icon={ImportIcon}
                                          options={{label: 'Importieren'}}
                                          create={ImportCreate}
                                          show={ImportShow}
                                          list={ImportList}
                    />,
                    <Resource name="users"
                              list={null}
                              edit={null}
                              create={null}
                              show={null} 
                              {...props}
                    />
                ]
            }
            }
        </HydraAdmin>
    )
}