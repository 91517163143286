import React, {useEffect} from 'react';
import {
    AppBar,
    UserMenu,
    MenuItemLink,
    translate,
    useDataProvider,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {Divider} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import LabelIcon from "@material-ui/icons/Label";
import HelpIcon from '@material-ui/icons/Help';
import logo from "./spektakulo-10.png";
import get from 'lodash/get';
import {useSessionViewSync} from "../Hook/useSessionViewSync";
import {Baseurl} from "../Entrypoint";

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const CustomUserMenu = translate(({translate, ...props}) => {

    const Dispatch = useDispatch();
    const dataProvider = useDataProvider();

    const Session = useSelector(state => state.session);
    const SessionPlanID = useSelector(state => state.session.PlanID);
    const SessionPlans = useSelector(state => get(state, 'session.plans'));
    const SessionWaitingForPlans = useSelector(state => get(state, 'session.waitingForPlans'));

    // Load plans into Session
    useEffect(() => {
        if (!SessionWaitingForPlans && !SessionPlans) {
            Dispatch({type: 'SESSION_ADD', waitingForPlans: true})
            dataProvider.getList('plans', {
                pagination: {page: 1, perPage: 155},
                sort: {field: "id", order: "desc"},
                filter: {} // todo filter
            })
                .then(({data}) => {

                    // Add plans to session
                    Dispatch({type: 'SESSION_ADD', plans: data})
                    Dispatch({type: 'SESSION_REMOVE', waitingForPlans: true})
                    
                    // Check if selected plan exists
                    // Or select first existing plan
                    // Save selected ID in LocalStorage
                    var PlanID = SessionPlanID ?? localStorage.getItem('PlanID');

                    if (data.length > 0) {
                        var planExists = data.filter((plan => {
                            return PlanID && parseInt(plan.originId) === parseInt(PlanID);
                        })).length > 0;
                        if (!planExists) {
                            PlanID = parseInt(data[0].originId);
                        }
                        localStorage.setItem('PlanID', PlanID);
                        Dispatch({type: 'SESSION_ADD', PlanID: PlanID})
                    }
                })
                .catch(error => {
                    // setError(error);
                })
        }
    }, [dataProvider,
        SessionPlanID,
        SessionWaitingForPlans,
        SessionPlans,
        Dispatch
    ])

    return (
        <UserMenu {...props}>
            {Session && Session.plans ? Session.plans.map(plan => {
                return (
                    <MenuItemLink
                        to={'/switchPlan/' + plan.originId}
                        key={'switchPlan-' + plan.originId}
                        primaryText={plan.name}
                        leftIcon={<LabelIcon/>}
                    />
                )
            }) : null}
            <Divider/>
        </UserMenu>
    )
});

const SessionSync = p => {
    useSessionViewSync();
    return null;
}

const CustomAppBar = ({classes, ...props}) => {
    return (
        <AppBar style={{backgroundImage: 'radial-gradient(circle farthest-side, rgba(255,255,255,0.3), rgb(0, 127, 119,0.1))'}} {...props} userMenu={<CustomUserMenu/>}>
            {/*<Typography*/}
            {/*    variant="title"*/}
            {/*    color="inherit"*/}
            {/*    className={classes.title}*/}
            {/*    id="react-admin-title"*/}
            {/*/>*/}
            <img src={logo} alt={"Spektakulo"}/>
            <span className={classes.spacer}/>
            <IconButton href={Baseurl + '/media/docs/Spektakulo-Einsatzplanung.pdf'}
                        style={{opacity: '0.97',color:"white"}}>
                <HelpIcon />
            </IconButton>
            <SessionSync/>
        </AppBar>
    );
}

export default withStyles(styles)(CustomAppBar);