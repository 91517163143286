import React from "react";
import {
    ListGuesser,
} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {
    ChipField,
    DateField, ReferenceField, Show, SimpleShowLayout, TextField,
} from 'react-admin'
import {InnerFormHeadline} from "../Form/FormFields";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {ProjectIcon} from '../Layout/SpektakuloTheme'

export const EventList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <DateField showTime={true} source={"dateTimeFrom"}/>
            <DateField showTime={true} source={"dateTimeUntil"}/>

            <ReferenceField sortBy={"project.name"} link={false} reference={"projects"} source={"project"}>
                <ChipField source={"name"}/>
            </ReferenceField>

            <ReferenceField sortBy={"type.name"} link={false} reference={"event_types"} source={"type"}>
                <ChipField source={"name"}/>
            </ReferenceField>

        </ListGuesser>
    )
}

export const EventDetail = ({onCancel, resource, ...props}) => {
    return (
        <Show resource={'events'} {...props} hasEdit={false}>
            <SimpleShowLayout>
                <InnerFormHeadline headline={"Ereignis Detail"}/>
                <TextField source={"name"}/>
                <DateField source="dateTimeFrom" showTime={true}/>
                <DateField source="dateTimeUntil" showTime={true}/>
                <ReferenceField link={false} reference={"projects"} source={"project"}>
                    <ChipField icon={<ProjectIcon />} source={"name"}/>
                </ReferenceField>
                <IconButton onClick={onCancel} title={"Schliessen"} aria-label="schliessen">
                    <CloseIcon/>
                </IconButton>
            </SimpleShowLayout>
        </Show>
    )
}
