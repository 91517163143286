import React from 'react';
import {Layout} from 'react-admin';
import Menu from "./Menu";
import Appbar from "./Appbar";

const SpektakuloLayout = (props) =>{
    return (
        <Layout {...props} 
                appBar={Appbar} 
                menu={Menu} 
        />
    )
};

export default SpektakuloLayout;