import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {ChipField, ReferenceField, TextField} from "react-admin";
import {isAdmin} from "../DataProvider/Authorization";
import {MoneyField} from "../Form/FormFields";

export const PositionList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser filters={false} {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <TextField source={"name"}/>
            <MoneyField source={"ansatz"} />
            
            <ReferenceField sortBy={"positionType.name"} link={false} reference={"position_types"} source={"positionType"}>
                <ChipField source={"name"} />
            </ReferenceField>
        </ListGuesser>
    );
}