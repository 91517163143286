import React from "react";
import {
    Resource,
} from 'react-admin';
import {
    ListGuesser,
    EditGuesser,
    CreateGuesser,
    ShowGuesser,
} from "@api-platform/admin";
import {Baseurl} from "../Entrypoint";

// Auth token provider endpoint
export const Authpoint = Baseurl + "/authentication_token";

// Privileges
export const hasRole = (props, role) => (typeof props.permissions !== "undefined") && (-1 < props.permissions.split(',').indexOf(role));
export const isAdmin = (props) => hasRole(props, 'ROLE_ADMIN');

// Auth restricted components

// AdminResourceGuesser maps a resource, but does not show it in UI.
// Need this for views of other resources which reference this resource.
export const AdminResourceGuesser = ({list, edit, create, show, ...props}) => {
    var admin = isAdmin(props);
    return (
        <Resource
            list={admin ? (list || AuthorizedListGuesser) : null}
            edit={admin ? (edit || EditGuesser) : null}
            create={admin ? (create || CreateGuesser) : null}
            show={admin ? (show || ShowGuesser) : null}
            {...props}
        />
    )
}

export const AuthorizedListGuesser = (props) => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser {...props}
                     {...addProps}
                     bulkActionButtons={false}
                     exporter={false} 
                     hasShow={false} 
                     hasCreate={false} 
                     hasEdit={false}
        />
    )
}