import React from "react";
import {
    DateField,
    RichTextField,
    FileField,
    FileInput, Create, SimpleForm, List, Datagrid
} from "react-admin";
import {
    FieldGuesser, 
    ShowGuesser,
    InputGuesser
} from "@api-platform/admin";
import {GetPlanID} from "../Session/getPlan";

export const ImportList = props => {
    const planID = GetPlanID();
    const planFilter = planID ? {'plan': planID} : null;
    return (
    <List sort={{field: 'dateTimeImported', order: 'desc'}} filter={planFilter} {...props} hasEdit={false}>
        <Datagrid expand={<ImportShow />} {...props}>
        <DateField source="dateTimeImported" showTime/>
        <FieldGuesser source="plan" link={false} addLabel={true}/>
        <FieldGuesser source="rootElement" addLabel={true}/>
        <FieldGuesser source="success" addLabel={true}/>
        </Datagrid>
    </List>
    );
};

export const ImportShow = props => (
    <ShowGuesser {...props}  hasEdit={false}>
        <DateField source="dateTimeImported" showTime/>
        <FieldGuesser source="success" addLabel={true}/>
        <RichTextField source="importLog"/>
    </ShowGuesser>
);

export const ImportCreate = props => (
    <Create {...props} redirect={"create"} hasEdit={false}>
        <SimpleForm variant={"outlined"} redirect={"create"}>
            <InputGuesser source="plan" allowEmpty={false} />
            <FileInput source="file" label="JSON Importfiles" accept="application/json">
                <FileField source="src" title="title"/>
            </FileInput>    
        </SimpleForm>
    </Create>
);