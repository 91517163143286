import React from "react";
import {
    ListGuesser,
    FieldGuesser,
} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {DateField} from "react-admin";

export const PlanList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <FieldGuesser source={"name"} />
            <DateField showTime={true} source={"dateTimeFrom"}/>
            <DateField showTime={true} source={"dateTimeUntil"}/>
            <FieldGuesser source={"progress"} />
        </ListGuesser>
    );
}