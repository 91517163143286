import {useSelector} from 'react-redux';

export const GetPlanID = () => {
    return useSelector(state => state.session.PlanID);
}

export const GetPlan = () => {
    const PlanID = useSelector(state => state.session.PlanID);
    const plans = useSelector(state => state.session.plans);
    if(PlanID && plans){
            let filtered = plans.filter(plan => {
                return parseInt(plan.originId) === parseInt(PlanID);
            });
            if (filtered.length) {
                return filtered[0];
            }    
    }
    return null;
}

export default GetPlan;