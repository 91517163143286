import React from "react";
import {
        ListGuesser
} from "@api-platform/admin";
import {isAdmin} from "../DataProvider/Authorization";
import {ChipField, ReferenceArrayField, ReferenceField, SingleFieldList, TextField} from "react-admin";

export const LocationList = props => {
        var admin = isAdmin(props);
        var addProps = admin ? {} : {bulkActionButtons: false}
        return (
            <ListGuesser filters={false} {...props} {...addProps}
                         bulkActionButtons={false}
                         exporter={false}
                         hasShow={false}
                         hasCreate={false}
                         hasEdit={false}
            >
                    <TextField source={"name"} />
                    
                    <ReferenceField sortBy={"type.name"} link={false} reference={"location_types"} source={"type"}>
                            <ChipField source={"name"} />
                    </ReferenceField>
                    
                    <ReferenceArrayField sortBy={"plan.name"} link={false} reference={"plans"} source={"plan"}>
                            <SingleFieldList linkType={false}>
                                    <ChipField  source={"name"} />
                            </SingleFieldList>
                    </ReferenceArrayField>

                    <ReferenceArrayField sortBy={"project.name"} link={false} reference={"projects"} source={"project"}>
                            <SingleFieldList linkType={false}>
                                    <ChipField  source={"name"} />
                            </SingleFieldList>
                    </ReferenceArrayField>

            </ListGuesser>
        );
}