import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {ChipField, ReferenceArrayField, SingleFieldList, TextField} from "react-admin";
import {isAdmin} from "../DataProvider/Authorization";
import {MoneyField} from "../Form/FormFields";

export const PositionTypeList = props => {
    var admin = isAdmin(props);
    var addProps = admin ? {} : {bulkActionButtons: false}
    return (
        <ListGuesser filters={false} {...props} {...addProps}
                     bulkActionButtons={false}
                     exporter={false}
                     hasShow={false}
                     hasCreate={false}
                     hasEdit={false}
        >
            <TextField source={"name"}/>
            <MoneyField source={"hourlyRate"} />
            
            <ReferenceArrayField sortBy={"position.name"} link={false} reference={"positions"} source={"positions"}>
                <SingleFieldList linkType={false}>
                    <ChipField source={"name"} />
                </SingleFieldList>
            </ReferenceArrayField>
        </ListGuesser>
    );
}